import React from 'react';
import { Table, Image, Icon } from 'semantic-ui-react';
import { Row, Col } from 'reactstrap';
import { AWS_CONFIG } from '../../constant';
import { images } from '../../theme/images';
import { AuthModal, EmailModel } from '../../components/';
import { isEmpty } from 'lodash';

const SpecificationsTable = ({
  hideUser,
  seller = {},
  auction = {},
  userName,
  loginUser,
  signUpUser,
  user,
  sendEmail,
}) => {
  const fields = [
    {
      name: 'Location',
      key: 'location',
    },
    {
      name: 'Published Date',
      key: 'auction_start_time',
    },
    {
      name: 'Year Of Made',
      key: 'year_of_made',
    },
    {
      name: 'Make',
      key: 'make',
    },
    {
      name: 'Model',
      key: 'model',
    },
    {
      name: 'Mileage',
      key: 'mileage',
    },
    {
      name: 'Body Style',
      key: 'body_style',
    },
    {
      name: 'Engine Capacity',
      key: 'engine_capacity',
    },
    {
      name: 'Drivetrain',
      key: 'drivetrain',
    },
    {
      name: 'Transmission',
      key: 'transmission',
    },
    {
      name: 'Exterior Color',
      key: 'exterior_color',
    },
    {
      name: 'Interior Color',
      key: 'interior_color',
    },
    {
      name: 'Title Status',
      key: 'title_status',
    },
    {
      name: 'Seller Type',
      key: 'seller_type',
    },
    {
      name: 'Vehicle History Report',
      key: 'vehicle_history_report_link',
    },
    {
      name: 'VIN',
      key: 'vin',
    },
  ];

  const leftSet = [];
  const rightSet = [];
  let counter = 1;
  fields.map((field, i) => {
    if (auction && auction[field.key] && !isEmpty(auction[field.key])) {
      field.value = auction[field.key];
      if (counter % 2 === 1) {
        rightSet.push(field);
      } else {
        leftSet.push(field);
      }
      counter++;
    }
    return true;
  });

  return (
    <Row>
      <Col xs={6}>
        <Table definition>
          <Table.Body className="specifications-table">
            {!hideUser && (
              <Table.Row>
                <Table.Cell width={6}>Seller</Table.Cell>
                <Table.Cell>
                  <Image
                    src={
                      seller?.avatar
                        ? `${AWS_CONFIG.defaultUrl}${seller?.avatar}`
                        : images.avatar
                    }
                    avatar
                  />
                  <a href={`/user/${seller?._id}/${seller?.user_name}`}>
                    <strong>
                      {seller?.user_name}{' '}
                      <span>
                        <Icon
                          className="certified-user mr-0"
                          title="Verified user"
                          name="certificate"
                        />
                        <Icon
                          className="certified-user-check mr-0"
                          title="Verified user"
                          name="check"
                        />
                      </span>
                    </strong>
                  </a>
                  &nbsp;&nbsp;&nbsp;
                  {!auction.hide_user_email ? (
                    userName ? (
                      <EmailModel
                        buttonText="Contact"
                        sendEmail={sendEmail}
                        to={seller?.email}
                      />
                    ) : (
                      <AuthModal
                        loginUser={loginUser}
                        signUpUser={signUpUser}
                        buttonText="Contact"
                        id={user._id}
                        link
                      />
                    )
                  ) : null}
                </Table.Cell>
              </Table.Row>
            )}
            {leftSet.length > 0
              ? leftSet.map((set, i) => {
                  return (
                    <Table.Row key={i}>
                      <Table.Cell width={6}>{set?.name}</Table.Cell>
                      {set.key === 'vehicle_history_report_link' ? (
                        <Table.Cell>
                          <a
                            className="contact"
                            target="_blank"
                            rel="noreferrer"
                            href={`${auction?.vehicle_history_report_link}`}
                          >
                            View History Report
                          </a>
                        </Table.Cell>
                      ) : (
                        <Table.Cell>{set?.value}</Table.Cell>
                      )}
                    </Table.Row>
                  );
                })
              : null}
          </Table.Body>
        </Table>
      </Col>
      <Col xs={6}>
        <Table definition>
          <Table.Body className="specifications-table">
            {rightSet.length > 0
              ? rightSet.map((set, i) => {
                  return (
                    <Table.Row key={i}>
                      <Table.Cell width={6}>{set?.name}</Table.Cell>
                      {set.key === 'vehicle_history_report_link' ? (
                        <Table.Cell>
                          <a
                            className="contact"
                            target="_blank"
                            rel="noreferrer"
                            href={`${auction?.vehicle_history_report_link}`}
                          >
                            View History Report
                          </a>
                        </Table.Cell>
                      ) : (
                        <Table.Cell>{set?.value}</Table.Cell>
                      )}
                    </Table.Row>
                  );
                })
              : null}
          </Table.Body>
        </Table>
      </Col>
    </Row>
  );
};

export default SpecificationsTable;
