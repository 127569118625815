import React, { useEffect } from 'react';
import { Header } from 'semantic-ui-react';
import './contentPages.scss';
import { Helmet } from 'react-helmet';

const TermsOfUse = () => {
  useEffect(() => {
    document.title = 'Terms Of Use | Onemorecar.ca';
  });

  return (
    <div className="content-pages">

      <Helmet>
        <title>{'Terms Of Use - Onemorecar'}</title>
        <meta name="title" content={'Terms Of Use - onemorecar'}/>
        <meta name="description" content="Onemorecar.ca was founded to be a platform for Canadian car enthusiasts to come together to buy, sell and discuss classic, speciality and modern vehicles" />
        <meta name="image" property="image" content="https://onemorecar.ca/seo-black.png"/>

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@onemorecar" />
        <meta name="twitter:creator" content="@onemorecar" />
        <meta name="twitter:title" content={'Terms Of Use - Onemorecar'} />
        <meta name="twitter:description" content="Onemorecar.ca was founded to be a platform for Canadian car enthusiasts to come together to buy, sell and discuss classic, speciality and modern vehicles" />
        <meta name="twitter:image" content="https://onemorecar.ca/seo-black.png" />
        
        <meta property="og:image" content="https://onemorecar.ca/seo-black.png" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={'Terms Of Use - Onemorecar'} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:description" content="Onemorecar.ca was founded to be a platform for Canadian car enthusiasts to come together to buy, sell and discuss classic, speciality and modern vehicles" />
      </Helmet>

      <Header className="mt-3" as="h2">
        Terms Of Use
      </Header>
      <p>Last updated: 2021/01/03</p>

      <p>
      Welcome to onemorecar.ca. If you have any questions, comments, or concerns regarding these terms of use, please contact us at:{' '} <br/>
        <a href="mailto:legal@onemorecar.ca">legal@onemorecar.ca</a> or
        +1-647-581-6268.
      </p>
      <p className="justify-text">
        These terms and conditions are the contract between you and onemore.car
        Ltd. By visiting or using our website, or signing up for our services,
        you agree to be bound by them.
      </p>
      <p clclassNameass="justify-text">
        We are onemorecar.ca, an online car auction website designed to connect
        buyers with sellers.
      </p>
      <p className="justify-text">
        You are: Anyone who uses our website or the services that we provide.
      </p>
      <p className="justify-text">
        Please read this agreement carefully and save it. If you do not agree
        with it, you should leave the website immediately.
      </p>
      <p className="justify-text">
        <strong>Your Account</strong>
      </p>
      <p className="justify-text">
        In order to access and use some or a portion of the Services, you may be
        required to register with us and set up an account with your email
        address and a password. The email address you provide will be your email
        address, and you are solely responsible for maintaining the
        confidentiality of your password. You are solely responsible for all
        activities that occur under your Account. Therefore, you should protect
        your password and make your password difficult for others to guess. You
        will not transfer your Account to another party without our consent.
      </p>
      <p className="justify-text">
        <strong>Termination</strong>
      </p>
      <p className="justify-text">
        You agree that onemorecar.ca in its sole discretion, may terminate your
        password, account (or any part thereof) or use of the Service, and
        remove and discard any content within the Service, for any reason,
        including, without limitation, for lack of use, or if onemorecar.ca
        believes that you have violated or acted inconsistently with the letter
        or spirit of the terms of use. You acknowledge and agree that
        onemorecar.ca may immediately deactivate or delete your account and all
        related information and files in your account and/or bar any further
        access to such files or the Service. Further, you agree that
        onemorecar.ca shall not be liable to you or any third-party for any
        termination of your access to the Service.
      </p>
      <p className="justify-text">
        <strong>Using the Services to</strong>
        <span class="Apple-converted-space">&nbsp;</span>
      </p>
      <ol class="ol1">
        <li class="li2">
          violate any laws<span class="Apple-converted-space">&nbsp;</span>
        </li>
        <li class="li2">be false or misleading;</li>
        <li class="li2">infringe any third-party right;</li>
        <li class="li2">
          distribute or contain spam, chain letters, or pyramid schemes;
        </li>
        <li class="li2">
          distribute viruses or any other technologies that may harm
          onemorecar.ca or the interests or property of onemorecar.ca users;
        </li>
        <li class="li2">
          impose an unreasonable load on our infrastructure or interfere with
          the proper working of the site
        </li>
        <li class="li2">
          copy, modify, or distribute any other person&apos;s content;
        </li>
        <li class="li2">
          harvest or otherwise collect information about others, including email
          addresses, without their consent;
        </li>
        <li class="li2">
          bypass measures used to prevent or restrict access to onemorecar.ca
        </li>
      </ol>
      <p class="p3"></p>
      <p className="justify-text">
        <span class="s1">
          <strong>
            onemorecar.ca acts as an intermediary that connects sellers with
            buyers. onemorecar.ca is strictly a platform to connect buyers with
            sellers.
          </strong>
          &nbsp;
        </span>
        The actual contract for sale for each car listed on the site is directly
        between the seller and the highest bidder.
      </p>
      <p className="justify-text">
        <strong>Bidding conditions</strong>
        <span class="Apple-converted-space">&nbsp;</span>
      </p>
      <p cclassName="justify-text">
        When bidding on an item on our Services, you agree to the Rules and
        policies for buyers and that:
      </p>
      <ul class="ul1">
        <li class="li2">
          You are responsible for reading the full item listing before making a
          bid or committing to buy,
        </li>
        <li class="li2">
          You enter a legally binding contract to purchase an item if you have
          the winning bid<span class="Apple-converted-space">&nbsp;</span>
        </li>
        <li class="li2">
          We do not transfer legal ownership of items from the seller to the
          buyer
        </li>
      </ul>
      <p className="justify-text">
        Onemorecar.ca will do it&rsquo;s best to transmit the vehicles condition
        and suitability but it is ultimately the bidders responsibility to
        ensure the car is as described. We highly encourage the bidders to have
        the car inspected to ensure it&rsquo;s up to their standards. Each
        seller agrees to make a reasonable effort to sell the car if there is a
        winning bid. Buyers/bidders agree that if they are the winning bidder,
        their bid is binding and is responsible for fees such as taxes and
        licensing.<span class="Apple-converted-space">&nbsp;</span>
      </p>
      <p className="justify-text">
        <strong>Sellers</strong>
      </p>
      <p className="justify-text">
        While an auction is live, sellers are expected to keep the car available
        for sale for the duration on of the auction. While a car is listed, the
        car may not be listed on any other classifieds, auction or publication.
        <span class="Apple-converted-space">&nbsp;</span>
      </p>
      <p className="justify-text">
        All amounts listed on the website are in Canadian dollars.
      </p>
    </div>
  );
};

export default TermsOfUse;
