import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';
import { Dimmer, Segment } from 'semantic-ui-react';
import BeatLoader from 'react-spinners/BeatLoader';
import { LightgalleryProvider, LightgalleryItem } from 'react-lightgallery';
import { AWS_CONFIG } from '../constant';

import './ImageGallery.scss';

export const ImageGallery = ({ gallery_images = [], categoryList = [] }) => {
  const [imageLoader, setImageLoader] = useState({});

  const renderGallery = () => {
    return (
      <LightgalleryProvider categoryList={categoryList}>
        {
          <Row className="gallery m-0" id="gallery">
            <Col md="8" sm="4" xs="6" className="gallery-main-image px-0">
              <div className="content">
                <LightgalleryItem
                  group="All"
                  src={`${AWS_CONFIG.defaultUrl}${gallery_images[0]}`}
                  thumb={`${AWS_CONFIG.defaultUrl}${gallery_images[0]}`}
                >
                  <Dimmer.Dimmable
                    className="p-0"
                    as={Segment}
                    dimmed={!imageLoader[0]}
                  >
                    <Dimmer active={!imageLoader[0]}>
                      <BeatLoader size={6} loading color="#db2828" />
                    </Dimmer>
                    <img
                      onLoad={() => {
                        setImageLoader(preStatte => {
                          // eslint-disable-next-line
                          return { ...preStatte, [0]: true };
                        });
                      }}
                      src={
                        gallery_images[0]
                          ? `${AWS_CONFIG.defaultUrl}${gallery_images[0]}`
                          : null
                      }
                      alt=""
                    />
                  </Dimmer.Dimmable>
                </LightgalleryItem>
              </div>
            </Col>
            <Col md="4" sm="4" xs="6">
              <Row>
                {gallery_images.map((x, i) => {
                  if (0 < i && i < 8) {
                    return (
                      <Col
                        md="6"
                        sm="4"
                        xs="6"
                        key={i}
                        className="gallery-item px-0"
                      >
                        <div key={i} className="content">
                          <LightgalleryItem
                            group="All"
                            src={`${AWS_CONFIG.defaultUrl}${x}`}
                            thumb={`${AWS_CONFIG.defaultUrl}${x}`}
                          >
                            <Dimmer.Dimmable
                              className="p-0"
                              as={Segment}
                              dimmed={!imageLoader[i]}
                            >
                              <Dimmer active={!imageLoader[i]}>
                                <BeatLoader size={6} loading color="#db2828" />
                              </Dimmer>
                              <img
                                onLoad={() => {
                                  setImageLoader(preStatte => {
                                    return { ...preStatte, [i]: true };
                                  });
                                }}
                                src={`${AWS_CONFIG.defaultUrl}${x}`}
                                alt=""
                              />
                            </Dimmer.Dimmable>
                          </LightgalleryItem>
                        </div>
                      </Col>
                    );
                  } else if (i === 8) {
                    return (
                      <Col
                        md="6"
                        sm="4"
                        xs="6"
                        key={i}
                        className="px-0 gallery-last-image"
                      >
                        <div className="content">
                          <LightgalleryItem
                            group="All"
                            src={`${AWS_CONFIG.defaultUrl}${x}`}
                            thumb={`${AWS_CONFIG.defaultUrl}${x}`}
                          >
                            <Dimmer.Dimmable
                              className="p-0"
                              as={Segment}
                              dimmed={!imageLoader[i]}
                            >
                              <Dimmer active={!imageLoader[i]}>
                                <BeatLoader size={6} loading color="#db2828" />
                              </Dimmer>
                              <div className="image-overlay">
                                <p>
                                  <strong>
                                    All Photos ({gallery_images.length})
                                  </strong>
                                </p>
                              </div>
                              <img
                                onLoad={() => {
                                  setImageLoader(preStatte => {
                                    return { ...preStatte, [i]: true };
                                  });
                                }}
                                src={`${AWS_CONFIG.defaultUrl}${x}`}
                                alt=""
                              />
                            </Dimmer.Dimmable>
                          </LightgalleryItem>
                        </div>
                      </Col>
                    );
                  } else {
                    return (
                      <LightgalleryItem
                        key={i}
                        group="All"
                        src={`${AWS_CONFIG.defaultUrl}${x}`}
                        thumb={`${AWS_CONFIG.defaultUrl}${x}`}
                      />
                    );
                  }
                })}
              </Row>
            </Col>
          </Row>
        }
      </LightgalleryProvider>
    );
  };

  return (
    <div className={window.innerWidth < 992 ? 'gallery-container' : ''}>
      {gallery_images[0] && renderGallery()}
    </div>
  );
};
