let API_URL = 'http://localhost:3000/v1';

export const REACT_APP_ENV_VALUE =
  process.env.REACT_APP_ENV_VALUE || 'production';
let s3Info = {
  ACL: 'public-read',
  accessKeyId: 'AKIAYAG3OUE5JKXCV76F',
  secretAccessKey: '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',
  region: 'us-east-2',
  Bucket: 'one-more-car-auction-images',
  defaultUrl: 'https://one-more-car-auction-images.s3.us-east-2.amazonaws.com/',
};
if (REACT_APP_ENV_VALUE === 'stage') {
  API_URL = 'https://northerngarageweb.info/v1';
} else if (REACT_APP_ENV_VALUE === 'production') {
  API_URL = 'https://notherngarage.ca/v1';
  s3Info = {
    ACL: 'public-read',
    accessKeyId: 'AKIAYAG3OUE5JKXCV76F',
    secretAccessKey: '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',
    region: 'us-east-2',
    Bucket: 'one-more-car-auction-images',
    defaultUrl:
      'https://one-more-car-auction-images.s3.us-east-2.amazonaws.com/',
  };
}

export const AWS_CONFIG = s3Info;
export const APP_NAME = 'one-more-car';
export const BASE_URL = API_URL;
export const AUTH_URL = API_URL;

export const SORT_DIRECTION = {
  ASC: 'ASC',
  DESC: 'DESC',
};

export const SELL_CAR_CHARGE = {
  NO_RESERVE: 50,
  RESERVE: 75,
};

export const MIN_BID_INCREMENT = 250;
export const BID_BUYER_PERCENTAGE = 4;
export const BUYER_MIN = 225;
export const BUYER_MAX = 4500;
