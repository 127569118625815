import React, { useState, useRef, useEffect } from 'react';
import {
  Button,
  Comment,
  Form,
  Header,
  Label,
  Icon,
  Menu,
  Image,
  Reveal,
  Segment,
} from 'semantic-ui-react';
import { AWS_CONFIG } from '../../constant';
import { ConfirmationMessage } from '../../components';
import moment from 'moment';
import { images } from '../../theme/images';
import Dropzone from 'react-dropzone-uploader';
import { makeid } from '../../lib/util';
import AWS from 'aws-sdk';

const CommentSection = ({
  auction_id,
  postComments,
  deleteComment,
  setUpVote,
  setInappropriate,
  hideBidHistory,
  user = {},
  comments = [],
}) => {
  const [newComment, setComment] = useState('');
  const [isReply, setReply] = useState(false);
  const [parentCommentId, setParentCommentId] = useState('');
  const [replyUser, setReplyUser] = useState('');
  const [activeItem, setActiveItem] = useState('Newest');
  const [sortedComments, setSortedComments] = useState(comments);
  const [commentImage, setCommentImage] = useState('');
  const [loading, setLoading] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [activeDeleteComment, setDeleteComment] = useState(null);

  useEffect(() => {
    AWS.config.update({
      accessKeyId: AWS_CONFIG.accessKeyId,
      secretAccessKey: AWS_CONFIG.secretAccessKey,
      region: AWS_CONFIG.region,
    });
    setSortedComments(comments);
    setActiveItem('Newest');
  }, [comments]);

  const inputRef = useRef(null);

  const sendComment = async () => {
    let req = {
      comment: newComment,
      auction_id: auction_id,
      reply: isReply,
      comment_image: commentImage,
    };
    if (isReply) {
      req = {
        ...req,
        parent_comment_id: parentCommentId,
      };
    }
    if (newComment) {
      await postComments(req, auction_id);
      setComment('');
      setReply(false);
      setParentCommentId('');
      setReplyUser('');
    }
  };

  const onChangeMenu = name => {
    setActiveItem(name);
    if (name === 'Newest') {
      setSortedComments(comments);
    } else {
      setSortedComments(comments.filter(comment => comment.type === 'bid'));
    }
  };

  const uploadFile = (file, remove) => {
    const bucket = new AWS.S3({
      params: { Bucket: AWS_CONFIG.Bucket },
    });
    const params = {
      ACL: AWS_CONFIG.ACL,
      Key: `comment_images/${makeid(50)}${file.name}`,
      ContentType: file.type,
      Body: file,
    };
    bucket.upload(params).send((err, data) => {
      if (err) {
        // an error occurred, handle the error
        console.log(err, err.stack);
        remove();
        setLoading(false);
        return;
      }
      if (loading) {
        setLoading(false);
        setCommentImage(data.key);
      }
    });
  };

  const handleChangeStatus = ({ file, remove }, status) => {
    setLoading(true);
    if (status === 'removed') {
      setCommentImage('');
      setLoading(false);
    } else if (status === 'done') {
      uploadFile(file, remove);
    }
  };

  return (
    <div>
      <Comment.Group>
        <Header as="h2" dividing>
          Comments & Bids
        </Header>

        {user?.role ? (
          <>
            {isReply ? (
              <Label color="red" as="a">
                Reply to {replyUser}
                <Icon
                  onClick={() => {
                    setReply(false);
                    setParentCommentId('');
                    setReplyUser('');
                  }}
                  name="delete"
                />
              </Label>
            ) : null}
            <Form reply className="reply-box-margin">
              <Dropzone
                classNames={{ dropzone: 'dzu-dropzone comment-image' }}
                accept="image/*"
                onChangeStatus={handleChangeStatus}
                maxFiles={1}
                multiple={false}
                canCancel={false}
                inputContent="Upload Image"
              />
              <textarea
                ref={inputRef}
                value={newComment}
                onChange={e => {
                  setComment(e.target.value);
                }}
              />
              <Button
                className="mt-3"
                onClick={() => {
                  sendComment();
                }}
                content={isReply ? 'Reply' : 'Add Comment'}
                labelPosition="left"
                icon="comment outline"
                disabled={loading}
                loading={loading}
                primary
              />
            </Form>
          </>
        ) : null}

        {!hideBidHistory && (
          <Menu text className="font-xl">
            <Menu.Item header>Sort By</Menu.Item>
            <Menu.Item
              name="Newest"
              active={activeItem === 'Newest'}
              color={activeItem === 'Newest' ? 'red' : undefined}
              onClick={(e, { name }) => onChangeMenu(name)}
            />
            <Menu.Item
              name="Bid History"
              active={activeItem === 'Bid History'}
              color={activeItem === 'Bid History' ? 'red' : undefined}
              onClick={(e, { name }) => onChangeMenu(name)}
            />
          </Menu>
        )}
        <div className="comment-section">
          {sortedComments.map((comment, i) => {
            return (
              <Comment key={i}>
                <Comment.Avatar
                  className="m-0"
                  src={
                    comment.user.avatar
                      ? `${AWS_CONFIG.defaultUrl}${comment.user.avatar}`
                      : images.avatar
                  }
                />
                <Comment.Content>
                  <Comment.Author
                    as="a"
                    href={`/user/${comment.user._id}/${comment.user.user_name}`}
                  >
                    {comment.user.user_name}
                    {comment.user.verified && (
                      <span>
                        <Icon
                          className="certified-user mr-0"
                          title="Verified user"
                          name="certificate"
                        />
                        <Icon
                          className="certified-user-check mr-0"
                          title="Verified user"
                          name="check"
                        />
                      </span>
                    )}
                  </Comment.Author>
                  <Comment.Metadata>
                    <div>
                      {moment
                        .duration(
                          moment(new Date()).diff(moment(comment.createdAt))
                        )
                        .humanize()}{' '}
                      ago
                    </div>
                  </Comment.Metadata>
                  {comment.comment_image && (
                    <Image
                      src={`${AWS_CONFIG.defaultUrl}${comment.comment_image}`}
                      size="small"
                    />
                  )}
                  <Comment.Text>
                    {comment.type === 'admin_message' ? (
                      <Label className="py-2" color={'teal'}>
                        {comment.comment}
                      </Label>
                    ) : comment.type === 'text' ? (
                      <span>
                        {comment.auction_owner === true ? (
                          <Label className="py-2" color={'yellow'}>
                            {comment.comment}
                          </Label>
                        ) : (
                          <span>
                            {comment.user.verified ? (
                              <span>{comment.comment}</span>
                            ) : (
                              <div>
                                <span className="over-reveal-layer">
                                  {comment.comment}
                                </span>
                                <span className="over-reveal-layer-title">
                                  {' '}
                                  (*User not verified. Hover to view.)
                                </span>
                              </div>
                            )}
                          </span>
                        )}
                      </span>
                    ) : (
                      <Label className="py-2" color="black">
                        {comment.comment}
                      </Label>
                    )}
                  </Comment.Text>

                  <Comment.Actions>
                    <Comment.Action
                      onClick={() => {
                        if (user?.role) {
                          setUpVote(comment._id, auction_id);
                        }
                      }}
                    >
                      <Label
                        color={
                          comment.up_votes_users.includes(user._id)
                            ? 'blue'
                            : undefined
                        }
                        basic
                      >
                        <Icon name="thumbs up outline" /> {comment.up_votes}
                      </Label>
                    </Comment.Action>
                    {user?.role && comment.type !== 'bid' ? (
                      <Comment.Action
                        onClick={() => {
                          setReply(true);
                          setParentCommentId(comment._id);
                          setReplyUser(comment?.user?.user_name);
                          inputRef.current.focus();
                        }}
                      >
                        <span className="pl-2 reply-button">
                          Reply <Icon name="reply" />
                        </span>
                      </Comment.Action>
                    ) : null}
                    {comment.type !== 'bid' ? (
                      <Comment.Action
                        onClick={() => {
                          if (user?.role) {
                            setInappropriate(
                              comment._id,
                              !comment?.inappropriate,
                              auction_id
                            );
                          }
                        }}
                      >
                        {comment?.inappropriate ? (
                          <span className="red-text">
                            Flagged <Icon color="red" name="flag outline" />
                          </span>
                        ) : (
                          <span>
                            Flag as inappropriate <Icon name="flag outline" />
                          </span>
                        )}
                      </Comment.Action>
                    ) : null}
                    {user?.role === 'admin' ? (
                      <Comment.Action
                        onClick={() => {
                          setDeleteComment(comment);
                          setOpenDeleteModal(true);
                        }}
                      >
                        <span className="red-text">
                          Delete <Icon color="red" name="eraser" />
                        </span>
                      </Comment.Action>
                    ) : null}
                  </Comment.Actions>
                </Comment.Content>
                <Comment.Group>
                  {comment.replies.map((reply, index) => {
                    return (
                      <Comment className="reply-comment" key={index}>
                        <Comment.Avatar
                          className="m-0"
                          src={
                            reply.user.avatar
                              ? `${AWS_CONFIG.defaultUrl}${reply.user.avatar}`
                              : images.avatar
                          }
                        />
                        <Comment.Content>
                          <Comment.Author
                            as="a"
                            href={`/user/${reply.user._id}/${reply.user.user_name}`}
                          >
                            {reply.user.user_name}
                            {reply.user.verified && (
                              <span>
                                <Icon
                                  className="certified-user mr-0"
                                  title="Verified user"
                                  name="certificate"
                                />
                                <Icon
                                  className="certified-user-check mr-0"
                                  title="Verified user"
                                  name="check"
                                />
                              </span>
                            )}
                          </Comment.Author>
                          <Comment.Metadata>
                            <div>
                              {moment
                                .duration(
                                  moment(new Date()).diff(
                                    moment(reply.createdAt)
                                  )
                                )
                                .humanize()}{' '}
                              ago
                            </div>
                          </Comment.Metadata>
                          <Comment.Text>
                            {reply.comment_image && (
                              <Image
                                src={`${AWS_CONFIG.defaultUrl}${reply.comment_image}`}
                                size="small"
                              />
                            )}
                            {reply.type === 'admin_message' ? (
                              <Label className="py-2" color={'teal'}>
                                {reply.comment}
                              </Label>
                            ) : reply.type === 'text' ? (
                              <span>
                                {reply.auction_owner === true ? (
                                  <Label className="py-2" color={'yellow'}>
                                    {reply.comment}
                                  </Label>
                                ) : (
                                  <span>
                                    {reply.user.verified ? (
                                      <span>{reply.comment}</span>
                                    ) : (
                                      <div>
                                        <span className="over-reveal-layer">
                                          {reply.comment}
                                        </span>
                                        <span className="over-reveal-layer-title">
                                          {' '}
                                          (*User not verified. Hover to view.)
                                        </span>
                                      </div>
                                    )}
                                  </span>
                                )}
                              </span>
                            ) : (
                              <Label className="py-2" color="black">
                                {reply.comment}
                              </Label>
                            )}
                          </Comment.Text>
                          <Comment.Actions>
                            <Comment.Action
                              onClick={() => {
                                if (user?.role) {
                                  setUpVote(reply._id, auction_id);
                                }
                              }}
                            >
                              <Label
                                color={
                                  reply.up_votes_users.includes(user._id)
                                    ? 'blue'
                                    : undefined
                                }
                                basic
                              >
                                <Icon name="thumbs up outline" />{' '}
                                {reply.up_votes}
                              </Label>
                            </Comment.Action>
                            <Comment.Action
                              onClick={() => {
                                if (user?.role) {
                                  setInappropriate(
                                    reply._id,
                                    !reply?.inappropriate,
                                    auction_id
                                  );
                                }
                              }}
                            >
                              {reply?.inappropriate ? (
                                <span className="red-text">
                                  Flagged{' '}
                                  <Icon color="red" name="flag outline" />
                                </span>
                              ) : (
                                <span>
                                  Flag as inappropriate{' '}
                                  <Icon name="flag outline" />
                                </span>
                              )}
                            </Comment.Action>
                            {user?.role === 'admin' ? (
                              <Comment.Action
                                onClick={() => {
                                  setDeleteComment(reply);
                                  setOpenDeleteModal(true);
                                }}
                              >
                                <span className="red-text">
                                  Delete <Icon color="red" name="eraser" />
                                </span>
                              </Comment.Action>
                            ) : null}
                          </Comment.Actions>
                        </Comment.Content>
                      </Comment>
                    );
                  })}
                </Comment.Group>
              </Comment>
            );
          })}
        </div>
      </Comment.Group>
      <ConfirmationMessage
        open={openDeleteModal}
        toggle={() => {
          setOpenDeleteModal(false);
          setDeleteComment(null);
        }}
        text={`Are you want to delete this ${
          activeDeleteComment?.type === 'text'
            ? 'comment'
            : activeDeleteComment?.type
        }?`}
        onDeletePress={async () => {
          if (activeDeleteComment) {
            deleteComment(activeDeleteComment._id, auction_id);
          }
          setOpenDeleteModal(false);
          setDeleteComment(null);
        }}
      />
    </div>
  );
};

export default CommentSection;
