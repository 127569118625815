import React, { useState, useEffect } from 'react';
import { Label, Button, Icon, Modal } from 'semantic-ui-react';
import { Row, Col } from 'reactstrap';
import moment from 'moment';
import BidStepOne from './BidStepOne';
import BidStepTwo from './BidStepTwo';
import MakeOfferStepOne from './MakeOfferStepOne';
import MakeOfferStepTwo from './MakeOfferStepTwo';
import { PaymentDetail, AuthModal } from '../../components/';

const BidBar = ({
  loginUser,
  signUpUser,
  postBid,
  auction_end_time,
  end_time,
  postBidLoading,
  user,
  auction = {},
  cardDetail = {},
  comment_count = 0,
  addCardDetail,
  errorMessage = null,
  postBidSuccess = null,
  postOffer,
  postOfferSuccess,
  postOfferLoading,
}) => {
  const [duration, setDuration] = useState('');
  const [open, setOpen] = useState(false);
  const [makeOfferModelOpen, setMakeOfferModelOpen] = useState(false);
  const [bidAmount, setBidAmount] = useState('');
  const [step, setStep] = useState(0);
  const [makeOffer, setMakeOffer] = useState(false);

  const getTimeRemaining = endtime => {
    const total = Date.parse(endtime) - Date.parse(new Date());
    let seconds = 0;
    let minutes = 0;
    let hours = 0;
    let days = 0;
    if (total > 0) {
      seconds = Math.floor((total / 1000) % 60);
      minutes = Math.floor((total / 1000 / 60) % 60);
      hours = Math.floor((total / (1000 * 60 * 60)) % 24);
      days = Math.floor(total / (1000 * 60 * 60 * 24));
    } else {
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
    return {
      total,
      days,
      hours,
      minutes,
      seconds,
    };
  };

  useEffect(() => {
    if (auction?.make_offer) {
      setMakeOffer(true);
    }
    if (cardDetail?.last4 && step === 0) {
      setStep(1);
    }
    if (end_time && moment(end_time) > moment(new Date())) {
      const { days } = getTimeRemaining(end_time);
      if (!days) {
        setInterval(() => {
          setTimer(end_time);
        }, 1000);
      } else {
        const timeLeft = moment
          .duration(moment(end_time).diff(moment(new Date())))
          .humanize();
        setDuration(timeLeft);
      }
    }
    // eslint-disable-next-line
  }, [auction_end_time, end_time, cardDetail]);

  const setTimer = time => {
    const { hours, minutes, seconds } = getTimeRemaining(time);
    return setDuration(
      `${hours < 10 ? '0' + hours : hours}:${
        minutes < 10 ? '0' + minutes : minutes
      }:${seconds < 10 ? '0' + seconds : seconds}`
    );
  };

  const inlineStyle = {
    modal: {
      height: 'auto',
      top: 'auto',
      left: 'auto',
      bottom: 'auto',
      right: 'auto',
    },
  };
  const closeModal = () => {
    setOpen(false);
    setMakeOfferModelOpen(false);
  };

  const renderLabel = (
    status,
    current_bid_amount,
    current_auction_end_time,
    mark_as_sold
  ) => {
    if (status === 'finished') {
      if (current_bid_amount) {
        return (
          <>
            <strong>Sold for CAD ${current_bid_amount || 0}</strong>
          </>
        );
      } else if (mark_as_sold) {
        return (
          <>
            <strong>Sold for max bid</strong>
          </>
        );
      } else {
        return (
          <>
            <strong>No bids placed</strong>
          </>
        );
      }
    } else if (status === 'reserve_not_met') {
      return (
        <>
          <strong>
            Bid to CAD ${current_bid_amount || 0} (Reserve not met)
          </strong>
        </>
      );
    } else if (status === 'rejected') {
      return (
        <>
          <strong>Auction Cancelled</strong>
        </>
      );
    } else {
      if (
        !current_auction_end_time ||
        (current_auction_end_time &&
          moment(current_auction_end_time) > moment(new Date()))
      ) {
        return null;
      } else {
        setTimeout(() => {
          window.location.reload();
        }, 10000);
        return (
          <>
            <strong>Auction results processing...</strong>
          </>
        );
      }
    }
  };

  const renderMakeOfferModel = makeOfferStep => {
    switch (makeOfferStep) {
      case 0:
        return (
          <PaymentDetail
            addCardDetail={addCardDetail}
            onlyContent
            closePropsModal={closeModal}
            setStep={setStep}
          />
        );
      case 1:
        return (
          <MakeOfferStepOne
            setStep={setStep}
            closeModal={closeModal}
            setBidAmount={setBidAmount}
            duration={duration}
            auction={auction}
          />
        );
      case 2:
        return (
          <MakeOfferStepTwo
            setStep={setStep}
            closeModal={closeModal}
            bidAmount={bidAmount}
            duration={duration}
            auction={auction}
            postOffer={postOffer}
            user={user}
            errorMessage={errorMessage}
            postOfferSuccess={postOfferSuccess}
            postOfferLoading={postOfferLoading}
          />
        );

      default:
        break;
    }
  };

  const renderBidModel = bidStep => {
    switch (bidStep) {
      case 0:
        return (
          <PaymentDetail
            addCardDetail={addCardDetail}
            onlyContent
            closePropsModal={closeModal}
            setStep={setStep}
          />
        );
      case 1:
        return (
          <BidStepOne
            setStep={setStep}
            closeModal={closeModal}
            setBidAmount={setBidAmount}
            duration={duration}
            auction={auction}
          />
        );
      case 2:
        return (
          <BidStepTwo
            setStep={setStep}
            closeModal={closeModal}
            bidAmount={bidAmount}
            duration={duration}
            auction={auction}
            postBid={postBid}
            user={user}
            errorMessage={errorMessage}
            postBidSuccess={postBidSuccess}
            postBidLoading={postBidLoading}
          />
        );

      default:
        break;
    }
  };

  return (
    <Row className="pt-3">
      <Col className="pt-2" md={9}>
        <Label className="p-4 auction-view-lable" size="large">
          {duration ? (
            <>
              <Icon className="mr-0 p-1" name="clock outline" /> Time Left{' '}
              <strong>{duration === 'a day' ? '1 day' : duration}</strong>
            </>
          ) : (
            renderLabel(
              auction.status,
              auction.current_bid_amount,
              end_time,
              auction.mark_as_sold
            )
          )}
          {auction.adz_type !== 'found_in_market' && (
            <>
              <Icon className="ml-3 mr-0 p-1" name="angle double up" /> Current
              Bid <strong>CAD ${auction.current_bid_amount || 0}</strong>
              <Icon className="ml-3 mr-0 p-1" name="gavel" /> Bids{' '}
              <strong>{auction.total_bids || 0}</strong>
            </>
          )}
          <Icon className="ml-3 mr-0 p-1" name="comments outline" /> Comments{' '}
          <strong>{comment_count}</strong>
        </Label>
      </Col>
      <Col className="pt-2" md={3}>
        {user._id && makeOffer && (
          <Modal
            size="mini"
            onClose={() => {
              setMakeOfferModelOpen(false);
            }}
            onOpen={() => {
              setMakeOfferModelOpen(true);
            }}
            open={makeOfferModelOpen}
            trigger={
              makeOffer && (
                <Button className="m-0 w-100 bid-button" color="red">
                  MAKE OFFER
                </Button>
              )
            }
            style={inlineStyle.modal}
            closeOnDimmerClick={false}
            className="sign-up-modal"
          >
            {renderMakeOfferModel(step)}
          </Modal>
        )}
        {user._id ? (
          <Modal
            size="mini"
            onClose={() => {
              setOpen(false);
            }}
            onOpen={() => {
              setOpen(true);
            }}
            open={open}
            trigger={
              duration && (
                <Button className="m-0 w-100 bid-button" color="red">
                  PLACE BID
                </Button>
              )
            }
            style={inlineStyle.modal}
            closeOnDimmerClick={false}
            className="sign-up-modal"
          >
            {renderBidModel(step)}
          </Modal>
        ) : (
          <AuthModal
            loginUser={loginUser}
            signUpUser={signUpUser}
            buttonText={makeOffer ? 'MAKE OFFER' : 'PLACE BID'}
            id={user._id}
          />
        )}
      </Col>
    </Row>
  );
};

export default BidBar;
