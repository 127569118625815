import { CARS_LOADING, SET_CAR_LIST, SET_CAR } from './actions.js';

const initialState = {
  adminCarLoading: false,
  adminCars: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CARS_LOADING:
      return { ...state, adminCarLoading: action.payload };
    case SET_CAR_LIST:
      return {
        ...state,
        adminCars: action.payload || {},
      };
    case SET_CAR:
      return {
        ...state,
        adminCar: action.payload || {},
      };
    default:
      return state;
  }
};

export * from './cars';
export default reducer;
