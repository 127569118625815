import React, { useEffect } from 'react';
import { Header } from 'semantic-ui-react';
import './contentPages.scss';
import { Helmet } from 'react-helmet';

const AboutUs = () => {
  useEffect(() => {
    document.title = 'About Us | Onemorecar.ca';
  });

  return (
    <div className="content-pages">

      <Helmet>
        <title>{'About Us - Onemorecar'}</title>
        <meta name="title" content={'About Us - onemorecar'}/>
        <meta name="description" content="Onemorecar.ca was founded to be a platform for Canadian car enthusiasts to come together to buy, sell and discuss classic, speciality and modern vehicles" />
        <meta name="image" property="image" content="https://onemorecar.ca/seo-black.png"/>

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@onemorecar" />
        <meta name="twitter:creator" content="@onemorecar" />
        <meta name="twitter:title" content={'About Us - Onemorecar'} />
        <meta name="twitter:description" content="Onemorecar.ca was founded to be a platform for Canadian car enthusiasts to come together to buy, sell and discuss classic, speciality and modern vehicles" />
        <meta name="twitter:image" content="https://onemorecar.ca/seo-black.png" />
        
        <meta property="og:image" content="https://onemorecar.ca/seo-black.png" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={'About Us - Onemorecar'} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:description" content="Onemorecar.ca was founded to be a platform for Canadian car enthusiasts to come together to buy, sell and discuss classic, speciality and modern vehicles" />
      </Helmet>

      <Header className="mt-3" as="h2">
        About Us
      </Header>
      <p className="justify-text">
      Onemorecar was founded to be a platform for Canadian car enthusiasts to come together to buy, sell and discuss classic, speciality and enthusiast vehicles. We welcome cars, trucks, and bikes that hold a special place in car enthusiasts’ hearts. 
      </p>
      <p className="justify-text">
      What we offer:
      </p>
      <ul className="list-view">
        <li>No listing fees for sellers</li>
        <li>Sellers can bid and purchase vehicles in Canadian dollars</li>
        <li>Complimentary car history report for every listing provided by onemorecar</li>
        <li>We only list cars in Canada. Buyers can easily arrange pre-purchase inspections, exchange funds, and drive the car home without additional inconveniences at the border</li>
      </ul>
      <p className="justify-text">
      How does onemorecar.ca work? <a href="/how-it-works">Click here to find out</a>.
      </p>
      
      <p>&nbsp;</p>
    </div>
  );
};

export default AboutUs;
