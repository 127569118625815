import logo from '../assets/images/logo.png';
import admin_logo from '../assets/images/admin-logo.png';
import avatar from '../assets/images/avatar.png';
import dummy from '../assets/images/dummy.png';
import car1 from '../assets/images/car1.jpg';
import car2 from '../assets/images/car2.jpg';
import car3 from '../assets/images/car3.jpg';
import medal from '../assets/images/medal.png';

export const images = {
  logo: logo,
  admin_logo: admin_logo,
  avatar: avatar,
  dummy: dummy,
  car1: car1,
  car2: car2,
  car3: car3,
  medal: medal,
};
