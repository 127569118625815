import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import Parser from 'html-react-parser';
import { ImageGallery } from '../../components';
import {
  Header,
  Icon,
  Divider,
  Grid,
  Embed,
  Label,
  Segment,
  Button,
} from 'semantic-ui-react';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getAuction,
  getAuctionBySlug,
  getUserWishList,
  postWishList,
  deleteUserWishList,
  getComments,
  getCommentsBySlug,
  postComments,
  deleteComment,
  setUpVote,
  setInappropriate,
  postBid,
  addCardDetail,
  getCard,
  loginUser,
  signUpUser,
  postOffer,
  sendEmail,
} from '../../store/actions';
import BeatLoader from 'react-spinners/BeatLoader';
import moment from 'moment';
import SpecificationsTable from './SpecificationsTable';
import draftToHtml from 'draftjs-to-html';
import BidBar from './BidBar';
import CommentSection from './CommentSection';
import { Helmet } from 'react-helmet';
import { AWS_CONFIG } from '../../constant';
import './AuctionView.scss';

const AuctionView = props => {
  const wishList = props?.auctions?.wishList?.wish_list_auction_ids || [];
  const comment_count = props?.auctions?.auctionDetail?.comment_count || 0;

  const end_time =
    props?.auctions?.auctionDetail?.auction?.auction_end_time || 0;
  const [auction, setAuction] = useState({});
  const [seller, setSeller] = useState({});
  let [galleryImages, setGalleryImages] = useState([]);
  const [duration, setDuration] = useState(false);
  const [cardDetail, setCardDetail] = useState({});
  const [categoryList, setCategoryList] = useState([
    {
      key: 'gallery_images',
      value: 'EXTERIOR',
      index: 0,
    },
    {
      key: 'interior_gallery_images',
      value: 'INTERIOR',
      index: 0,
    },
    {
      key: 'engine_gallery_images',
      value: 'ENGINE',
      index: 0,
    },
    {
      key: 'miscellaneous_gallery_images',
      value: 'MISCELLANEOUS',
      index: 0,
    },
    {
      key: 'service_reports',
      value: 'SERVICE REPORTS',
      index: 0,
    },
  ]);
  const [followCount, setFollowCount] = useState(0);

  useEffect(() => {
    const pathname = window.location.pathname;
    const slug = pathname.split('/')[2];

    const feachData = async () => {
      const [res] = await Promise.all([
        props.getAuctionBySlug(slug),
        props.getCommentsBySlug(slug),
      ]);
      if (props.user.role) {
        await props.getUserWishList();
        const cardDetail = await props.getCard();
        setCardDetail(cardDetail);
      }
      if (res?.auction) {
        const { feature_image, gallery_images = [] } = res.auction;
        galleryImages = [feature_image, ...gallery_images];
        for (const [index, category] of categoryList.entries()) {
          if (index > 0) {
            const imageList = res.auction[category.key] || [];
            if (!_.isEmpty(imageList)) {
              category.index = galleryImages.length + 1;
              galleryImages = [...galleryImages, ...imageList];
            }
          }
        }
        setCategoryList(categoryList);
      }
      setGalleryImages(galleryImages);
      setAuction(res.auction);
      setSeller(res.seller);
      if (
        res?.auction?.auction_end_time &&
        moment(res?.auction?.auction_end_time) > moment(new Date())
      ) {
        setDuration(true);
      }
      const followCount = props?.auctions?.auctionDetail?.follow_count || 0;
      setFollowCount(followCount);
    };

    feachData();
    // eslint-disable-next-line
  }, [
    window.location.pathname,
    props?.auctions?.auctionDetail?.auction?.auction_end_time,
  ]);

  let followText = 'Follow';
  let followColor;
  if (wishList.includes(auction?._id)) {
    followText = 'Following';
    followColor = 'blue';
  }

  if (props.auctions.getAuctionDetailLoading) {
    return (
      <Grid className="my-3" centered>
        <BeatLoader size={10} loading color="#db2828" />
      </Grid>
    );
  }
  return (
    <div className="pt-3">
      {auction?.title && (
        <Helmet>
          <title>{auction?.title + ' - Onemorecar'}</title>
          <meta name="title" content={auction?.title + ' - onemorecar'} />
          <meta name="description" content={auction?.sub_title} />
          <meta
            name="image"
            property="image"
            content={AWS_CONFIG.defaultUrl + auction?.feature_image}
          />

          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@onemorecar" />
          <meta name="twitter:creator" content="@onemorecar" />
          <meta name="twitter:title" content={auction?.title} />
          <meta name="twitter:description" content={auction?.sub_title} />
          <meta
            name="twitter:image"
            content={AWS_CONFIG.defaultUrl + auction?.feature_image}
          />

          <meta
            property="og:image"
            content={AWS_CONFIG.defaultUrl + auction?.feature_image}
          />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={auction?.title} />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:description" content={auction?.sub_title} />
        </Helmet>
      )}
      <Row className="my-4">
        <Col md="12">
          <Segment raised secondary stacked>
            <p className="header-banner center-text">
              New to onemorecar? <a href="/how-it-works">Learn how it works.</a>
            </p>
          </Segment>
        </Col>
      </Row>
      <Header as="h1">
        <div className="d-flex">
          <p className="m-0">{auction.title} </p>
          {props?.user?.user_name && (
            <div className="follow-button-view">
              <Button
                content={followText}
                icon="hand point right outline"
                label={{
                  as: 'p',
                  basic: true,
                  content: followCount,
                  size: 'mini',
                  color: followColor,
                }}
                labelPosition="right"
                size="mini"
                color={followColor}
                onClick={() => {
                  if (wishList.includes(auction?._id)) {
                    setFollowCount(followCount - 1);
                    props.deleteUserWishList(auction?._id);
                  } else {
                    setFollowCount(followCount + 1);
                    props.postWishList(auction?._id);
                  }
                }}
              />
            </div>
          )}
        </div>
        <Header.Subheader>
          {!auction.reserve && auction.adz_type !== 'found_in_market' && (
            <Label basic color="green" className="ml-0 mr-2 mt-2">
              NO RESERVE
            </Label>
          )}
          {auction.reserve && auction.adz_type !== 'found_in_market' && (
            <Label basic color="red" className="ml-0 mr-2 mt-2">
              RESERVE
            </Label>
          )}
          &nbsp;{auction.sub_title}
        </Header.Subheader>
      </Header>
      <div className="pt-1">
        <ImageGallery
          gallery_images={galleryImages}
          categoryList={categoryList}
        />
      </div>
      {auction.adz_type !== 'found_in_market' && (
        <BidBar
          comment_count={comment_count}
          auction={props?.auctions?.auctionDetail?.auction || {}}
          auction_end_time={auction?.auction_end_time}
          end_time={end_time}
          postBid={props.postBid}
          postOffer={props.postOffer}
          errorMessage={props.auctions.postBidError}
          postBidSuccess={props.auctions.postBidSuccess}
          postBidLoading={props.auctions.postBidLoading}
          postOfferSuccess={props.auctions.postOfferSuccess}
          postOfferLoading={props.auctions.postOfferLoading}
          user={props.user}
          addCardDetail={props.addCardDetail}
          cardDetail={cardDetail}
          loginUser={props.loginUser}
          signUpUser={props.signUpUser}
        />
      )}
      {auction.adz_type !== 'found_in_market' && (
        <Divider horizontal>
          <Header disabled as="h3">
            {duration ? 'Ending ' : 'Ended '}{' '}
            {moment(auction?.auction_end_time).format('MMMM D [at] h:mm a')}
          </Header>
        </Divider>
      )}
      {auction?.classic_link ? (
        <Segment placeholder>
          <Header color="red" as="h3">
            <Icon name="chart area" />
            Bid Guide (Prices shown in $ USD)
          </Header>
          <div className="content">{Parser(auction?.classic_link)}</div>
        </Segment>
      ) : null}

      <Header color="red" as="h3">
        <Icon name="bar chart" />
        Specifications
      </Header>

      <SpecificationsTable
        seller={seller}
        auction={auction}
        hideUser={auction.adz_type === 'found_in_market'}
        userName={props?.user?.user_name}
        loginUser={props.loginUser}
        signUpUser={props.signUpUser}
        user={props.user}
        sendEmail={props.sendEmail}
      />

      <Divider />

      {auction?.sub_sections
        ? auction?.sub_sections.map((sub_section, i) => {
            return (
              <div key={i}>
                <div
                  className="sub-section py-1"
                  dangerouslySetInnerHTML={{
                    __html: draftToHtml(sub_section),
                  }}
                />
                <Divider />
              </div>
            );
          })
        : null}

      {auction?.videos?.length ? (
        <Header color="red" as="h3">
          <Icon name="file video outline" />
          Videos
        </Header>
      ) : null}

      {auction?.videos ? (
        <Row className="mb-3">
          {auction?.videos.map((video, i) => {
            let colSize = 12;
            if (auction?.videos.length === 2) {
              colSize = 6;
            } else if (auction?.videos.length > 2) {
              colSize = 4;
            }
            return (
              <Col className="pt-3" key={i} sm={colSize}>
                <Embed
                  className="video-container"
                  id={video.split('https://youtu.be/')[1]}
                  placeholder={`http://img.youtube.com/vi/${
                    video.split('https://youtu.be/')[1]
                  }/maxresdefault.jpg`}
                  source="youtube"
                />
              </Col>
            );
          })}
        </Row>
      ) : null}

      <CommentSection
        comments={props?.auctions?.comments || []}
        user={props.user}
        postComments={props.postComments}
        deleteComment={props.deleteComment}
        setUpVote={props.setUpVote}
        setInappropriate={props.setInappropriate}
        auction_id={auction?._id}
        hideBidHistory={auction.adz_type === 'found_in_market'}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.auth.userData,
  auctions: state.auctions,
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getAuction,
      getAuctionBySlug,
      getUserWishList,
      postWishList,
      deleteUserWishList,
      getComments,
      getCommentsBySlug,
      postComments,
      deleteComment,
      setUpVote,
      setInappropriate,
      postBid,
      addCardDetail,
      getCard,
      loginUser,
      signUpUser,
      postOffer,
      sendEmail,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AuctionView);
