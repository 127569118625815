import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export const ConfirmationMessage = props => {
  let { open, toggle, onDeletePress, text } = props;
  return (
    <Modal isOpen={open} toggle={() => toggle()}>
      <ModalHeader toggle={() => toggle()}>DELETE</ModalHeader>
      <ModalBody>{text || 'Are you want to delete this?'}</ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={() => onDeletePress()}>
          Yes
        </Button>{' '}
        <Button color="secondary" onClick={() => toggle()}>
          No
        </Button>
      </ModalFooter>
    </Modal>
  );
};
