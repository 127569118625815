import React, { useEffect, useState } from 'react';
import {
  Segment,
  Menu,
  Grid,
  Header,
  Divider,
  Image,
  Table,
  Button,
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  updateUser,
  resetPassword,
  getCard,
  addCardDetail,
  getUserDetail,
  getUserWishListAuctions,
  getUserOffers,
  acceptOffer,
  getUserSendOffers,
  rejectOffer,
} from '../../store/actions';
import AWS from 'aws-sdk';
import { makeid } from '../../lib/util';
import { AWS_CONFIG } from '../../constant';
import Account from './Account';
import Settings from './Settings';
import BeatLoader from 'react-spinners/BeatLoader';
import { CardGroup, ConfirmationMessage } from '../../components';
import { images } from '../../theme/images';
import './Profile.scss';

const Profile = props => {
  const [profileData, setProfileData] = useState({
    avatar: '',
  });
  const [uploadPrgress, setUploadPrgress] = useState({});
  const [activeItem, setActiveItem] = useState('');
  const [cardDetail, setCardDetail] = useState({});
  const [cardLoading, setCardLoading] = useState(true);
  const [ownAuctions, setOwnAuctions] = useState([]);
  const [wishList, setWishList] = useState([]);
  const [pastWishList, setPastWishList] = useState([]);
  const [myOffers, setMyOffers] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [offerDetails, setOfferDetails] = useState(null);

  const handleData = (key, value) => {
    setProfileData(prevState => {
      return { ...prevState, [key]: value };
    });
  };
  useEffect(() => {
    AWS.config.update({
      accessKeyId: AWS_CONFIG.accessKeyId,
      secretAccessKey: AWS_CONFIG.secretAccessKey,
      region: AWS_CONFIG.region,
    });
    const feachData = async () => {
      if (window.location.pathname === '/account/settings') {
        const cardDetail = await props.getCard();
        setCardLoading(false);
        setCardDetail(cardDetail);
      }
      if (window.location.pathname === '/account/listings') {
        const ownAuctions = await props.getUserDetail(
          props?.auth?.userData?._id
        );
        setOwnAuctions(ownAuctions?.auctions || []);
        setCardLoading(false);
      }
      if (window.location.pathname === '/watch-list') {
        const [wishList, pastWishList] = await Promise.all([
          props.getUserWishListAuctions({ auction_type: 'live' }),
          props.getUserWishListAuctions({ auction_type: 'past' }),
        ]);
        setWishList(wishList?.auctions || []);
        setPastWishList(pastWishList?.auctions || []);
        setCardLoading(false);
      }
      if (window.location.pathname === '/received-offer') {
        const offers = await props.getUserOffers();
        setMyOffers(offers);
        setCardLoading(false);
      }
      if (window.location.pathname === '/sent-offer') {
        const offers = await props.getUserSendOffers();
        setMyOffers(offers);
        setCardLoading(false);
      }
    };
    feachData();
    setProfileData(props?.auth?.userData);
    if (window.location.pathname === '/account') {
      document.title = 'Profile - Onemorecar';
      setActiveItem('Profile');
    } else if (window.location.pathname === '/account/settings') {
      document.title = 'Settings - Onemorecar';
      setActiveItem('Settings');
    } else if (window.location.pathname === '/account/listings') {
      document.title = 'My Listings - Onemorecar';
      setActiveItem('My Listings');
    } else if (window.location.pathname === '/watch-list') {
      document.title = 'Watch List - Onemorecar';
      setActiveItem('Watch List');
    } else if (window.location.pathname === '/received-offer') {
      document.title = 'Received Offers - Onemorecar';
      setActiveItem('Received Offers');
    } else if (window.location.pathname === '/sent-offer') {
      document.title = 'Sent Offers - Onemorecar';
      setActiveItem('Sent Offers');
    }
    // eslint-disable-next-line
  }, [window.location.pathname, props?.auth?.userData]);

  const uploadFile = (file, fileType) => {
    const bucket = new AWS.S3({
      params: { Bucket: AWS_CONFIG.Bucket },
    });
    const params = {
      ACL: AWS_CONFIG.ACL,
      Key: `${fileType}/${makeid(50)}${file.name}`,
      ContentType: file.type,
      Body: file,
    };
    bucket
      .upload(params)
      .on('httpUploadProgress', evt => {
        const uploaded = Math.round((evt.loaded / evt.total) * 100);
        setUploadPrgress({
          [fileType]: uploaded,
        });
      })
      .send((err, data) => {
        setUploadPrgress({});
        if (err) {
          // an error occurred, handle the error
          console.log(err, err.stack);
          return;
        }
        handleData('avatar', data.key);
      });
  };

  const putSchema = ['user_name', 'contact_no'];

  const updateUserData = async (data, setLoding) => {
    const putData = {};
    putSchema.map(key => {
      return (putData[key] = data[key]);
    });
    putData.avatar = profileData.avatar || '';
    await props.updateUser(putData, setLoding);
  };

  const handleItemClick = ({ name }) => setActiveItem(name);

  if (props?.auth?.userListLoading) {
    return (
      <Grid className="my-3" centered>
        <BeatLoader size={10} loading color="#db2828" />
      </Grid>
    );
  }

  const renderOffers = row => {
    if (row.offerStatus === 'active') {
      return (
        <div className="d-flex justify-content-center">
          <Button
            inverted
            size="mini"
            color="green"
            onClick={() => {
              setOfferDetails({
                auction_id: row.auction_id,
                offer_id: row._id,
              });
              setOpenModal(true);
            }}
          >
            Accept
          </Button>
          <Button
            inverted
            size="mini"
            color="red"
            onClick={() => {
              setOfferDetails({
                auction_id: row.auction_id,
                offer_id: row._id,
                reject: true,
              });
              setOpenModal(true);
            }}
          >
            Reject
          </Button>
        </div>
      );
    } else if (row.offerStatus === 'inactive') {
      return (
        <div className="d-flex justify-content-center">
          <Button inverted size="mini" color="red" disabled>
            Rejected
          </Button>
        </div>
      );
    }
    return (
      <div className="d-flex justify-content-center">
        <Button inverted size="mini" color="green" disabled>
          Accepted
        </Button>
      </div>
    );
  };

  const renderOfferStatus = row => {
    if (row.offerStatus === 'active') {
      return (
        <div className="d-flex justify-content-center">
          <Button inverted size="mini" color="orange" disabled>
            Pending
          </Button>
        </div>
      );
    } else if (row.offerStatus === 'inactive') {
      return (
        <div className="d-flex justify-content-center">
          <Button inverted size="mini" color="red" disabled>
            Rejected
          </Button>
        </div>
      );
    }
    return (
      <div className="d-flex justify-content-center">
        <Button inverted size="mini" color="green" disabled>
          Accepted
        </Button>
      </div>
    );
  };

  return (
    <div className="profile-container mt-3">
      <Menu color="red" className="profile-menu" attached="top" tabular>
        <Menu.Item
          name="Profile"
          active={activeItem === 'Profile'}
          onClick={(e, data) => {
            handleItemClick(data);
            props.history.push('/account');
          }}
        />
        <Menu.Item
          name="My Listings"
          active={activeItem === 'My Listings'}
          onClick={(e, data) => {
            handleItemClick(data);
            props.history.push('/account/listings');
          }}
        />
        <Menu.Item
          name="Settings"
          active={activeItem === 'Settings'}
          onClick={(e, data) => {
            handleItemClick(data);
            props.history.push('/account/settings');
          }}
        />
        <Menu.Item
          name="Watch List"
          active={activeItem === 'Watch List'}
          onClick={(e, data) => {
            handleItemClick(data);
            props.history.push('/watch-list');
          }}
        />
        <Menu.Item
          name="Received Offers"
          active={activeItem === 'Received Offers'}
          onClick={(e, data) => {
            handleItemClick(data);
            props.history.push('/received-offer');
          }}
        />
        <Menu.Item
          name="Sent Offers"
          active={activeItem === 'Sent Offers'}
          onClick={(e, data) => {
            handleItemClick(data);
            props.history.push('/sent-offer');
          }}
        />
      </Menu>

      <Segment className="profile-segment" color="red" attached="bottom">
        {activeItem === 'Profile' && (
          <Account
            profileData={profileData}
            uploadPrgress={uploadPrgress}
            updateUserData={updateUserData}
            uploadFile={uploadFile}
            userListLoading={props?.auth?.userListLoading}
          />
        )}
        {activeItem === 'Settings' && (
          <Settings
            resetPassword={props?.resetPassword}
            cardDetail={cardDetail}
            userListLoading={props?.auth?.userListLoading}
            addCardDetail={props?.addCardDetail}
            cardLoading={cardLoading}
          />
        )}

        {activeItem === 'My Listings' && (
          <div className="card-container mt-3">
            <Header>My Listings</Header>
            <Divider />
            {cardLoading ? (
              <div className="d-flex justify-content-center">
                <BeatLoader size={6} loading color="#db2828" />
              </div>
            ) : (
              <CardGroup rows={ownAuctions || []} />
            )}
          </div>
        )}

        {activeItem === 'Watch List' && (
          <div className="card-container mt-3">
            <Header>Watch List</Header>
            <Divider />
            <Header>Now Live/ Sold</Header>
            {cardLoading ? (
              <div className="d-flex justify-content-center">
                <BeatLoader size={6} loading color="#db2828" />
              </div>
            ) : (
              <CardGroup rows={wishList || []} />
            )}
            <Header>Past Auctions</Header>
            {cardLoading ? (
              <div className="d-flex justify-content-center">
                <BeatLoader size={6} loading color="#db2828" />
              </div>
            ) : (
              <CardGroup rows={pastWishList || []} />
            )}
          </div>
        )}

        {activeItem === 'Received Offers' && (
          <div className="card-container mt-3">
            <Header>Received Offers</Header>
            <Divider />
            {myOffers.length ? (
              <Table basic="very" celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell></Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">
                      Buyer
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">
                      Offer Amount
                    </Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                {myOffers.map((offer, i) => {
                  return (
                    <Table.Body key={i}>
                      <Table.Row>
                        <Table.Cell>
                          <Header as="h4" image>
                            <Image
                              src={`${AWS_CONFIG.defaultUrl}${offer.feature_image}`}
                              rounded
                              size="massive"
                            />
                            <Header.Content>
                              {offer.title}
                              <Header.Subheader>{offer.slug}</Header.Subheader>
                            </Header.Content>
                          </Header>
                        </Table.Cell>
                        <Table.Cell>
                          <Header as="h4" image>
                            <Image
                              src={
                                offer?.buyer?.avatar
                                  ? `${AWS_CONFIG.defaultUrl}${offer.buyer.avatar}`
                                  : images.avatar
                              }
                              rounded
                              size="small"
                            />
                            <Header.Content>
                              <a
                                href={`/user/${offer?.buyer?._id}/${offer?.buyer?.user_name}`}
                              >
                                <strong>{offer?.buyer?.user_name}</strong>
                              </a>
                            </Header.Content>
                          </Header>
                        </Table.Cell>
                        <Table.Cell
                          textAlign="center"
                          className="font-weight-bold"
                        >
                          CAD ${offer.amount}
                        </Table.Cell>
                        <Table.Cell>{renderOffers(offer)}</Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  );
                })}
              </Table>
            ) : null}
            <ConfirmationMessage
              open={openModal}
              toggle={() => {
                setOpenModal(false);
                setOfferDetails(null);
              }}
              text={`Are you want to ${
                offerDetails?.reject ? 'reject' : 'accept'
              } this offer?`}
              onDeletePress={async () => {
                if (offerDetails?.reject) {
                  await props.rejectOffer({
                    auction_id: offerDetails.auction_id,
                    offer_id: offerDetails.offer_id,
                  });
                } else {
                  await props.acceptOffer(offerDetails);
                }
                setOpenModal(false);
                setOfferDetails(null);
                window.location.reload();
              }}
            />
          </div>
        )}

        {activeItem === 'Sent Offers' && (
          <div className="card-container mt-3">
            <Header>Sent Offers</Header>
            <Divider />
            {myOffers.length ? (
              <Table basic="very" celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell></Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">
                      Offer Amount
                    </Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                {myOffers.map((offer, i) => {
                  return (
                    <Table.Body key={i}>
                      <Table.Row>
                        <Table.Cell>
                          <Header as="h4" image>
                            <Image
                              src={`${AWS_CONFIG.defaultUrl}${offer.feature_image}`}
                              rounded
                              size="massive"
                            />
                            <Header.Content>
                              {offer.title}
                              <Header.Subheader>{offer.slug}</Header.Subheader>
                            </Header.Content>
                          </Header>
                        </Table.Cell>
                        <Table.Cell
                          textAlign="center"
                          className="font-weight-bold"
                        >
                          CAD ${offer.amount}
                        </Table.Cell>
                        <Table.Cell>{renderOfferStatus(offer)}</Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  );
                })}
              </Table>
            ) : null}
          </div>
        )}
      </Segment>
    </div>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      updateUser,
      resetPassword,
      getCard,
      addCardDetail,
      getUserDetail,
      getUserWishListAuctions,
      getUserOffers,
      acceptOffer,
      getUserSendOffers,
      rejectOffer,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
