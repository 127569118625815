import React, { useEffect, useState } from 'react';
import { Tab, Header } from 'semantic-ui-react';
import EndingSoon from '../home/EndingSoon';
import MakeOffer from '../home/MakeOffer';
import NoReserve from '../home/NoReserve';
import PastAuctions from '../home/PastAuctions';
import GreaterTorontoArea from '../home/GreaterTorontoArea';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getAuctions,
  getUserWishList,
  postWishList,
  deleteUserWishList,
} from '../../store/actions';
import qs from 'query-string';

const Search = props => {
  const [search, setSearch] = useState('');
  const [hide, setHide] = useState(true);
  useEffect(() => {
    const query = qs.parse(window.location.search);
    setSearch(query.q);
    const fetchData = async () => {
      setHide(true);
      if (props.user.role) {
        await props.getUserWishList();
      }
      setHide(false);
    };
    fetchData();
    // eslint-disable-next-line
  }, [props.user, window.location.search]);
  return (
    <div>
      <Header className="pt-3" as="h2">
        Results for &nbsp; "{search || ''}"
      </Header>
      {!hide ? (
        <Tab
          className="tab-main-container"
          menu={{ color: 'red', secondary: true }}
          panes={[
            {
              menuItem: 'Ending Soon',
              render: () => (
                <Tab.Pane className="tab-container" attached={false}>
                  <EndingSoon
                    loading={props.auctions.getAuctionsLoading}
                    getAuctions={props.getAuctions}
                    postWishList={props.postWishList}
                    deleteUserWishList={props.deleteUserWishList}
                    user={props.user}
                    wishList={
                      props.auctions?.wishList?.wish_list_auction_ids || []
                    }
                    search={search || null}
                  />
                </Tab.Pane>
              ),
            },
            {
              menuItem: 'Newly Listed',
              render: () => {
                return (
                  <Tab.Pane className="tab-container" attached={false}>
                    <MakeOffer
                      loading={props.auctions.getAuctionsLoading}
                      getAuctions={props.getAuctions}
                      postWishList={props.postWishList}
                      deleteUserWishList={props.deleteUserWishList}
                      user={props.user}
                      wishList={
                        props.auctions?.wishList?.wish_list_auction_ids || []
                      }
                      search={search || null}
                    />
                  </Tab.Pane>
                );
              },
            },
            {
              menuItem: 'No Reserve',
              render: () => (
                <Tab.Pane className="tab-container" attached={false}>
                  <NoReserve
                    loading={props.auctions.getAuctionsLoading}
                    getAuctions={props.getAuctions}
                    postWishList={props.postWishList}
                    deleteUserWishList={props.deleteUserWishList}
                    user={props.user}
                    wishList={
                      props.auctions?.wishList?.wish_list_auction_ids || []
                    }
                    search={search || null}
                  />
                </Tab.Pane>
              ),
            },
            {
              menuItem: 'Greater Toronto Area',
              render: () => (
                <Tab.Pane className="tab-container" attached={false}>
                  <GreaterTorontoArea
                    loading={props.auctions.getAuctionsLoading}
                    getAuctions={props.getAuctions}
                    postWishList={props.postWishList}
                    deleteUserWishList={props.deleteUserWishList}
                    user={props.user}
                    wishList={
                      props.auctions?.wishList?.wish_list_auction_ids || []
                    }
                  />
                </Tab.Pane>
              ),
            },
          ]}
        />
      ) : null}
      {!hide ? (
        <PastAuctions
          header="Past Auctions"
          loading={props.auctions.getPassAuctionsLoading}
          getAuctions={props.getAuctions}
          postWishList={props.postWishList}
          deleteUserWishList={props.deleteUserWishList}
          auctionType="past"
          user={props.user}
          wishList={props.auctions?.wishList?.wish_list_auction_ids || []}
          search={search || null}
        />
      ) : null}
    </div>
  );
};

const mapStateToProps = state => ({
  auctions: state.auctions,
  user: state.auth.userData,
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getAuctions,
      getUserWishList,
      postWishList,
      deleteUserWishList,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);
