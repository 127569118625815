import React, { useState } from 'react';
import {
  Card,
  Image,
  Icon,
  Label,
  Dimmer,
  Segment,
  Button,
} from 'semantic-ui-react';
import { Row, Col } from 'reactstrap';
import moment from 'moment';
import { images } from '../theme/images';
import { isMobile } from 'react-device-detect';
import BeatLoader from 'react-spinners/BeatLoader';
import LazyLoad from 'react-lazyload';
import { AWS_CONFIG } from '../constant';
import _ from 'lodash';
import './CardGroup.scss';

const renderLabal = (
  hideLable,
  status,
  duration,
  current_bid_amount,
  mark_as_sold
) => {
  if (hideLable) {
    return null;
  } else if (status === 'active' && (duration || current_bid_amount)) {
    return (
      <Label
        className={isMobile ? 'card-time-mobile d-flex' : 'card-time d-flex'}
        color="red"
        size="large"
      >
        <p>
          {current_bid_amount ? (
            <>
              <Icon className="mr-0" name="gavel" /> CAD $
            </>
          ) : (
            ''
          )}
          {current_bid_amount}
          &nbsp;&nbsp;&nbsp;
          {duration ? <Icon className="mr-0" name="stopwatch" /> : null}
          &nbsp;
          {duration === 'a day' ? '1 day' : duration}
        </p>
      </Label>
    );
  } else if (status === 'finished') {
    if (current_bid_amount) {
      return (
        <Label
          className={isMobile ? 'card-time-mobile d-flex' : 'card-time d-flex'}
          color="red"
          size="large"
        >
          <h5>Sold for CAD ${current_bid_amount || 0}</h5>
        </Label>
      );
    } else if (mark_as_sold) {
      return (
        <Label
          className={isMobile ? 'card-time-mobile d-flex' : 'card-time d-flex'}
          color="red"
          size="large"
        >
          <h5>Sold for max bid</h5>
        </Label>
      );
    } else {
      return (
        <Label
          className={isMobile ? 'card-time-mobile d-flex' : 'card-time d-flex'}
          color="red"
          size="large"
        >
          <h5>No bids placed</h5>
        </Label>
      );
    }
  } else if (status === 'reserve_not_met') {
    return (
      <Label
        className={isMobile ? 'card-time-mobile d-flex' : 'card-time d-flex'}
        color="red"
        size="large"
      >
        <h5>Bid to CAD ${current_bid_amount || 0}</h5>
      </Label>
    );
  } else if (status === 'rejected') {
    return (
      <Label
        className={isMobile ? 'card-time-mobile d-flex' : 'card-time d-flex'}
        color="red"
        size="large"
      >
        <h5>Auction Cancelled</h5>
      </Label>
    );
  } else {
    return null;
  }
};

const renderUserLabal = user_bid_amount => {
  return (
    <Label
      className={isMobile ? 'user-bid-mobile d-flex' : 'user-bid d-flex'}
      color="blue"
      size="large"
    >
      <p>Bid to CAD ${user_bid_amount}</p>
    </Label>
  );
};

export const CardGroup = ({
  user,
  postWishList,
  deleteUserWishList,
  hideLable = false,
  hideFeature = false,
  rows = [],
  wishList = [],
}) => {
  const [isLoading, setLoading] = useState(true);
  const [followCounts, setFollowCounts] = useState({});
  return (
    <Row>
      {rows.map((row, i) => {
        const status = row.status;
        let duration = '';
        if (
          row.auction_end_time &&
          moment(row.auction_end_time) > moment(new Date())
        ) {
          duration = moment
            .duration(moment(row.auction_end_time).diff(moment(new Date())))
            .humanize();
        }
        let followText = 'Follow';
        let followColor;
        if (wishList.includes(row._id)) {
          followText = 'Following';
          followColor = 'blue';
        }
        const existsCount = _.isUndefined(followCounts[row._id]) ? row.follow_count : followCounts[row._id]
        return (
          <Col className="card-content" key={i} md="3" sm="6" xs="12">
            <LazyLoad throttle={200} height={300}>
              <Card color="red" raised>
                <a href={row._id ? `/auctions/${row.slug}` : '#'}>
                  {!hideFeature && row.featured ? (
                    // eslint-disable-next-line
                    <img
                      className={
                        isMobile ? 'featured-image-mobile' : 'featured-image'
                      }
                      alt="featured-image"
                      src={images.medal}
                    />
                  ) : null}
                  <Dimmer.Dimmable
                    className={
                      isMobile
                        ? 'card-content-image-mobile p-0 m-0'
                        : 'card-content-image p-0 m-0'
                    }
                    as={Segment}
                    dimmed={isLoading}
                  >
                    <Dimmer active={isLoading}>
                      <BeatLoader size={6} loading color="#db2828" />
                    </Dimmer>
                    <Image
                      src={`${AWS_CONFIG.defaultUrl}${row.feature_image}`}
                      alt={row.slug || 'main-image'}
                      wrapped
                      ui={false}
                      onLoad={() => {
                        setLoading(false);
                      }}
                    />
                  </Dimmer.Dimmable>
                  {row.user_bid_amount && renderUserLabal(row.user_bid_amount)}
                  {renderLabal(
                    hideLable,
                    status,
                    duration,
                    row.current_bid_amount,
                    row.mark_as_sold
                  )}
                </a>
                <Card.Content>
                  <Card.Header className="card-header-text" as="h4">
                    <a href={row._id ? `auctions/${row.slug}` : '#'}>
                      {row.title}
                    </a>
                  </Card.Header>
                  <Card.Meta className="card-sub-title">
                    {!row.reserve && row.adz_type !== 'found_in_market' ? (
                      <Label basic color="green" className="mr-1">
                        NO RESERVE
                      </Label>
                    ) : null}
                    {row.reserve && row.adz_type !== 'found_in_market' ? (
                      <Label basic color="red" className="mr-1">
                        RESERVE
                      </Label>
                    ) : null}
                    &nbsp;{row.sub_title}
                  </Card.Meta>
                </Card.Content>
                <Card.Content extra>
                  <p className="location-text">
                    <Icon name="map marker alternate" />
                    {row.location}
                  </p>
                  {user?.user_name && row.adz_type !== 'found_in_market' && (
                    <div className="follow-button-container">
                      <Button
                        content={followText}
                        icon="hand point right outline"
                        label={{
                          as: 'p',
                          basic: true,
                          content: existsCount,
                          size: 'mini',
                          color: followColor,
                        }}
                        labelPosition="right"
                        size="mini"
                        floated="right"
                        color={followColor}
                        className="follow-button"
                        onClick={() => {
                          if (wishList.includes(row._id)) {
                            deleteUserWishList(row._id);
                            setFollowCounts({
                              ...followCounts,
                              [row._id]: existsCount - 1 
                            })
                          } else {
                            postWishList(row._id);
                            setFollowCounts({
                              ...followCounts,
                              [row._id]: existsCount + 1 
                            })
                          }
                        }}
                      />
                    </div>
                  )}
                </Card.Content>
              </Card>
            </LazyLoad>
          </Col>
        );
      })}
    </Row>
  );
};
