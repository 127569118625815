import { NotificationContainer } from 'react-notifications';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import React from 'react';
import history from './lib/history';
import withTracker from './lib/withPageTracker';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import PublicLayout from './containers/layout/PublicLayout';
import Home from './containers/home/Home';
import SellCar from './containers/sellCar/SellCar';
import AuctionView from './containers/auctionView/AuctionView';
import FoundInMarket from './containers/foundInMarket/FoundInMarket';
import Search from './containers/search/Search';
import Profile from './containers/profile/Profile';
import User from './containers/user/User';
import AboutUs from './containers/contentPages/AboutUs';
import PrivacyPolicy from './containers/contentPages/PrivacyPolicy';
import TermsOfUse from './containers/contentPages/TermsOfUse';
import HowItWorks from './containers/contentPages/HowItWorks';
import ForgotPassword from './containers/user/ForgotPassword';

import Page404 from './containers/page404/Page404';

import DefaultLayout from './containers/layout/DefaultLayout';

const PublicRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      <PublicLayout
        {...props}
        routes={rest}
        title={rest.title}
        sub={rest.sub}
        transparentBackground={rest.transparentBackground}
        hoverColor={rest.hoverColor}
      >
        <Component {...props} />
        <NotificationContainer />
      </PublicLayout>
    )}
  />
);

const AppRoutes = mainProps => {
  const role = mainProps?.auth?.userData?.role;
  const isAdmin = role === 'admin';
  return (
    <Router history={history}>
      <Switch>
        <PublicRoute exact path="/" component={withTracker(Home)} />
        <PublicRoute exact path="/sell-car" component={withTracker(SellCar)} />
        <PublicRoute exact path="/about-us" component={withTracker(AboutUs)} />
        <PublicRoute
          exact
          path="/how-it-works"
          component={withTracker(HowItWorks)}
        />
        <PublicRoute
          exact
          path="/privacy-policy"
          component={withTracker(PrivacyPolicy)}
        />
        <PublicRoute
          exact
          path="/terms-of-use"
          component={withTracker(TermsOfUse)}
        />
        <PublicRoute
          exact
          path="/auctions/:slug"
          component={withTracker(AuctionView)}
        />
        <PublicRoute
          exact
          path="/marketplacewatch"
          component={withTracker(FoundInMarket)}
        />
        <PublicRoute exact path="/search" component={withTracker(Search)} />

        <PublicRoute
          exact
          path="/user/:userId/:name"
          component={withTracker(User)}
        />

        <PublicRoute
          exact
          path="/forgot-password/:passwordResetToken"
          component={withTracker(ForgotPassword)}
        />

        {role && (
          <PublicRoute exact path="/account" component={withTracker(Profile)} />
        )}

        {role && (
          <PublicRoute
            exact
            path="/account/settings"
            component={withTracker(Profile)}
          />
        )}

        {role && (
          <PublicRoute
            exact
            path="/account/listings"
            component={withTracker(Profile)}
          />
        )}

        {role && (
          <PublicRoute
            exact
            path="/watch-list"
            component={withTracker(Profile)}
          />
        )}

        {role && (
          <PublicRoute
            exact
            path="/received-offer"
            component={withTracker(Profile)}
          />
        )}

        {role && (
          <PublicRoute
            exact
            path="/sent-offer"
            component={withTracker(Profile)}
          />
        )}

        <Route
          path="/404"
          name="page404"
          render={props => <Page404 {...props} />}
        />
        {isAdmin && (
          <Route
            path="/"
            name="Dashboard"
            render={props => <DefaultLayout {...props} />}
          />
        )}
        <Redirect from="/" to="/404" />
      </Switch>
    </Router>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AppRoutes);
