import {
  USER_LOGIN_LOADING,
  SET_USER_DATA,
  GET_USER_LIST_LOADING,
  SET_USER_LIST,
} from './actions.js';

const initialState = {
  userLoading: false,
  idAccount: '',
  userData: {},
  userListLoading: false,
  userList: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGIN_LOADING:
      return { ...state, userLoading: action.payload };
    case SET_USER_DATA:
      return {
        ...state,
        userData: action.payload,
      };
    case GET_USER_LIST_LOADING:
      return { ...state, userListLoading: action.payload };
    case SET_USER_LIST:
      return {
        ...state,
        userList: action.payload,
      };

    default:
      return state;
  }
};

export * from './auth';
export default reducer;
