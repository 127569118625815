import React, { useState } from 'react';
import { Button, Header, Divider, Modal, Message } from 'semantic-ui-react';
import { images } from '../../theme/images';
import {
  AvForm,
  AvGroup,
  AvInput,
  AvFeedback,
} from 'availity-reactstrap-validation';
import { Label, Row, Col } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import { PaymentDetail } from '../../components';
import BeatLoader from 'react-spinners/BeatLoader';

const Settings = ({
  resetPassword,
  cardDetail,
  addCardDetail,
  cardLoading,
}) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoding] = useState(false);

  const closeModal = () => {
    setOpen(false);
  };

  const inlineStyle = {
    modal: {
      height: 'auto',
      top: 'auto',
      left: 'auto',
      bottom: 'auto',
      right: 'auto',
    },
  };

  return (
    <div className="profile-container mt-3">
      <Header>Account</Header>
      <Row>
        <Col md="8" xs="6">
          <strong>Password</strong>
          <p>************</p>
        </Col>
        <Col md="4" xs="6">
          <Modal
            size="tiny"
            onClose={() => {
              setOpen(false);
            }}
            onOpen={() => {
              setOpen(true);
            }}
            open={open}
            trigger={<Button className="w-100">Change Password</Button>}
            style={inlineStyle.modal}
            closeOnDimmerClick={false}
            className="sign-up-modal"
          >
            <Modal.Header className="sign-up-modal-header">
              <button
                onClick={() => {
                  closeModal();
                }}
                type="button"
                className="close"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <div className="d-flex justify-content-center">
                {
                  // eslint-disable-next-line
                  <img
                    alt="auth-image"
                    className="auth-image"
                    width={isMobile ? '135px' : '275px'}
                    height={isMobile ? '25px' : '50px'}
                    src={images.admin_logo}
                  />
                }
              </div>
              <Header className="mt-2">Change Password</Header>
            </Modal.Header>
            <Modal.Content>
              <Modal.Description>
                {error && (
                  <Message negative>
                    <Message.Header>{error}</Message.Header>
                  </Message>
                )}
                <AvForm
                  onValidSubmit={(event, values) => {
                    setError('');
                    resetPassword(values, closeModal, setLoding, setError);
                  }}
                >
                  <AvGroup>
                    <Label for="current_password">Current password</Label>
                    <AvInput
                      name="current_password"
                      id="current_password"
                      type="password"
                      required
                    />
                    <AvFeedback>
                      Current password shouldn't be empty.
                    </AvFeedback>
                  </AvGroup>
                  <AvGroup>
                    <Label for="new_password">New password</Label>
                    <AvInput
                      name="new_password"
                      id="new_password"
                      type="password"
                      required
                    />
                    <AvFeedback>New password shouldn't be empty.</AvFeedback>
                  </AvGroup>
                  <AvGroup>
                    <Label for="confirm_password">Re-enter new password</Label>
                    <AvInput
                      name="confirm_password"
                      id="confirm_password"
                      type="password"
                      required
                    />
                    <AvFeedback>
                      Re-enter password shouldn't be empty.
                    </AvFeedback>
                  </AvGroup>
                  <Button
                    disabled={loading}
                    loading={loading}
                    className="w-100"
                    inverted
                    color="red"
                    size="large"
                  >
                    Change Password
                  </Button>
                </AvForm>
              </Modal.Description>
            </Modal.Content>
          </Modal>
        </Col>
      </Row>
      <Divider />
      {cardLoading ? (
        <div className="d-flex justify-content-center">
          <BeatLoader size={6} loading color="#db2828" />
        </div>
      ) : (
        <Row>
          <Col md="8" xs="6">
            <strong>Payment method</strong>
            {cardDetail?.last4 && (
              <p className="m-0">**** **** **** {cardDetail?.last4}</p>
            )}
            {cardDetail?.brand && (
              <p className="m-0">{cardDetail?.brand.toUpperCase()}</p>
            )}
            {cardDetail?.exp_month && (
              <p className="m-0">
                {cardDetail?.exp_month} / {cardDetail?.exp_year}
              </p>
            )}
          </Col>
          <Col md="4" xs="6">
            <PaymentDetail
              cardDetail={cardDetail}
              addCardDetail={addCardDetail}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default Settings;
