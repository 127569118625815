import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import {
  AvForm,
  AvGroup,
  AvInput,
  AvFeedback,
} from 'availity-reactstrap-validation';
import { Label, Container, Row, Col } from 'reactstrap';
import {
  Button,
  Header,
  Icon,
  Card,
  Image,
  Message,
  Item,
} from 'semantic-ui-react';
import { AuthModal } from '../../components/AuthModalCustom';
import { ButtonGroup } from '../../components';
import Dropzone from 'react-dropzone-uploader';
import { NotificationManager } from 'react-notifications';
import AWS from 'aws-sdk';
import { AWS_CONFIG } from '../../constant';
import { makeid } from '../../lib/util';
import './SellCar.scss';
import {
  loginUser,
  signUpUser,
  logOut,
  fogotPassword,
} from '../../store/actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const SellCarForm = props => {
  useEffect(() => {
    AWS.config.update({
      accessKeyId: AWS_CONFIG.accessKeyId,
      secretAccessKey: AWS_CONFIG.secretAccessKey,
      region: AWS_CONFIG.region,
    });
    if (!_.isEmpty(props.userData)) {
      fetchData();
    }
    // eslint-disable-next-line
  }, []);
  const [card, setCard] = useState(null);
  const [succesMsg, setsuccesMsg] = useState(null);
  const fetchData = async () => {
    const data = await props.getCard();
    setCard(data);
  };

  const [carData, setCarData] = useState({
    photos: [],
  });

  const [galleyImagesPercent, setGalleyImagesPercent] = useState({});

  const handleCarData = (key, value) => {
    setCarData(prevState => {
      if (value === 'true') value = true;
      if (value === 'false') value = false;
      return { ...prevState, [key]: value };
    });
  };

  const postCar = async () => {
    if (!carData.seller_name) {
      return NotificationManager.error('Seller Name is required');
    } else if (!carData.phone_number) {
      return NotificationManager.error('Phone Number is required');
    } else if (!carData.year) {
      return NotificationManager.error('Manufactured year is required');
    } else if (!carData.make) {
      return NotificationManager.error('Make is required');
    } else if (!carData.billing_type) {
      return NotificationManager.error('Please select one type of auction');
    } else if (!carData.package_type) {
      return NotificationManager.error('Please select a listing package');
    } else if (carData.billing_type === 'reserve' && !carData.reserve_price) {
      return NotificationManager.error(
        'Reserve Price is required if you selected Reserve'
      );
    }
    const response = await props.postCar(carData);
    if (response.message && response.message === 'error') {
      const errorMessage = response?.errorMessage || 'Something went wrong';
      return NotificationManager.error(errorMessage);
    } else {
      setsuccesMsg(response);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  };

  const removeImage = index => {
    const photos = carData.photos;
    photos.splice(index, 1);
    setCarData(prevState => {
      return { ...prevState, photos };
    });
  };

  const handleChangeStatus = ({ meta, file }, status) => {
    if (status === 'done') {
      uploadFile(file, 'photos', meta.id);
    }
  };

  const uploadFile = (file, fileType, id) => {
    const bucket = new AWS.S3({
      params: { Bucket: AWS_CONFIG.Bucket },
    });
    const params = {
      ACL: AWS_CONFIG.ACL,
      Key: `${fileType}/${makeid(50)}${file.name}`,
      ContentType: file.type,
      Body: file,
    };
    bucket
      .upload(params)
      .on('httpUploadProgress', evt => {
        const uploaded = Math.round((evt.loaded / evt.total) * 100);
        setGalleyImagesPercent(prevState => {
          return { ...prevState, [id]: uploaded };
        });
      })
      .send((err, data) => {
        if (err) {
          // an error occurred, handle the error
          console.log(err, err.stack);
          return;
        }

        const photos = carData.photos;
        photos.push(data.key);
        handleCarData(fileType, photos);
      });
  };

  const renderForm = () => {
    return (
      <div>
        <div className="inner-container mt-2 pt-4 px-5 pb-4 form-scroll">
          <Row className="my-4">
            <Header color="red" as="h3">
              <Icon name="file alternate" />
              Your Info
            </Header>
          </Row>
          <Row className="my-4">
            <Col md="12">
              <Label for="seller_type">Dealer or Private Party ?</Label>
            </Col>
            <Col md="12">
              <ButtonGroup
                id="seller_type"
                name="seller_type"
                options={[
                  { label: 'Dealer', value: 'dealer' },
                  { label: 'Private Party', value: 'private_party' },
                ]}
                onChange={seller_type => {
                  handleCarData('seller_type', seller_type);
                }}
              />
            </Col>
          </Row>
          <Row className="my-4">
            <Col md="6">
              <AvGroup>
                <Label for="seller_name">Your Name</Label>
                <AvInput
                  value={carData?.seller_name || ''}
                  name="seller_name"
                  id="seller_name"
                  onChange={e => handleCarData('seller_name', e.target.value)}
                  required
                />
                <AvFeedback>Your name shouldn't be empty.</AvFeedback>
              </AvGroup>
            </Col>
            <Col md="6">
              <AvGroup>
                <Label for="phone_number">Contact Number</Label>
                <AvInput
                  value={carData?.phone_number || ''}
                  name="phone_number"
                  id="phone_number"
                  onChange={e => handleCarData('phone_number', e.target.value)}
                  required
                />
                <AvFeedback>Contact number shouldn't be empty.</AvFeedback>
              </AvGroup>
            </Col>
          </Row>
        </div>
        <div className="inner-container mt-4 pt-4 px-5 pb-4 form-scroll">
          <Row className="my-4">
            <Header color="red" as="h3">
              <Icon name="car" />
              Car Info
            </Header>
          </Row>

          <Row className="my-4">
            <Col md="6">
              <AvGroup>
                <Label for="year">Year</Label>
                <AvInput
                  value={carData?.year || ''}
                  name="year"
                  id="year"
                  onChange={e => handleCarData('year', e.target.value)}
                />
              </AvGroup>
            </Col>
            <Col md="6">
              <AvGroup>
                <Label for="make">Make</Label>
                <AvInput
                  value={carData?.make || ''}
                  name="make"
                  id="make"
                  onChange={e => handleCarData('make', e.target.value)}
                  required
                />
                <AvFeedback>Make shouldn't be empty.</AvFeedback>
              </AvGroup>
            </Col>
          </Row>
          <Row className="my-4">
            <Col md="6">
              <AvGroup>
                <Label for="model">Model</Label>
                <AvInput
                  value={carData?.model || ''}
                  name="model"
                  id="model"
                  onChange={e => handleCarData('model', e.target.value)}
                  required
                />
                <AvFeedback>Model shouldn't be empty.</AvFeedback>
              </AvGroup>
            </Col>
            <Col md="6">
              <AvGroup>
                <Label for="vin">VIN</Label>
                <AvInput
                  value={carData?.vin || ''}
                  name="vin"
                  id="vin"
                  onChange={e => handleCarData('vin', e.target.value)}
                />
              </AvGroup>
            </Col>
          </Row>
          <Row className="my-4">
            <Col md="6">
              <AvGroup>
                <Label for="mileage">Mileage (in KMS)</Label>
                <AvInput
                  value={carData?.mileage || ''}
                  name="mileage"
                  id="mileage"
                  onChange={e => handleCarData('mileage', e.target.value)}
                />
              </AvGroup>
            </Col>
            <Col md="6">
              <AvGroup>
                <Label for="location">Location</Label>
                <AvInput
                  value={carData?.location || ''}
                  name="location"
                  id="location"
                  onChange={e => handleCarData('location', e.target.value)}
                />
              </AvGroup>
            </Col>
          </Row>
          <Row className="my-4">
            <Col md="12">
              <AvGroup>
                <Label for="features">Options/Features</Label>
                <AvInput
                  type="textarea"
                  className="w-100"
                  rows="8"
                  name="features"
                  id="features"
                  value={carData?.features || ''}
                  onChange={e => handleCarData('features', e.target.value)}
                />
              </AvGroup>
            </Col>
          </Row>
          <Row className="my-4">
            <Col md="12">
              <Label for="is_vehicle_titled">
                Is the vehicle titled in your name?
              </Label>
            </Col>
            <Col md="12">
              <ButtonGroup
                id="is_vehicle_titled"
                name="is_vehicle_titled"
                options={[
                  { label: 'Yes', value: 'true', id: 'title_yes' },
                  { label: 'No', value: 'false', id: 'title_no' },
                ]}
                onChange={is_vehicle_titled => {
                  handleCarData('is_vehicle_titled', is_vehicle_titled);
                }}
              />
            </Col>
          </Row>
          <Row className="my-4">
            <Col md="12">
              <Label for="is_there_lienholder">
                Is there a lienholder on the title?
              </Label>
            </Col>
            <Col md="12">
              <ButtonGroup
                id="is_there_lienholder"
                name="is_there_lienholder"
                options={[
                  { label: 'Yes', value: 'true' },
                  { label: 'No', value: 'false' },
                ]}
                onChange={is_there_lienholder => {
                  handleCarData('is_there_lienholder', is_there_lienholder);
                }}
              />
            </Col>
          </Row>
          <Row className="my-4">
            <Col md="12">
              <Label for="title_status">What is the title’s status?</Label>
            </Col>
            <Col md="12">
              <ButtonGroup
                id="title_status"
                name="title_status"
                options={[
                  { label: 'Clean', value: 'clean' },
                  { label: 'Rebuilt', value: 'rebuilt' },
                ]}
                onChange={selectedValue => {
                  handleCarData('title_status', selectedValue);
                }}
              />
            </Col>
          </Row>
        </div>
        <div className="inner-container my-4 pt-4 px-5 pb-4 form-scroll">
          <Row className="my-4">
            <Header color="red" as="h3">
              <Icon name="money bill alternate outline" />
              Type of Auction
            </Header>
          </Row>
          <Row className="my-4">
            <Col md="12">
              <Label for="billing_type">
                Do you want to set a minimum price required for your vehicle to
                sell?
              </Label>
            </Col>
            <Col md="12">
              <ButtonGroup
                id="billing_type"
                name="billing_type"
                options={[
                  {
                    label: 'Reserve',
                    value: 'reserve',
                    id: 'reserve_yes',
                  },
                  {
                    label: 'No Reserve',
                    value: 'no_reserve',
                    id: 'reserve_no',
                  },
                ]}
                onChange={selectedValue => {
                  handleCarData('billing_type', selectedValue);
                }}
              />
            </Col>
          </Row>
          {carData.billing_type === 'reserve' ? (
            <Row className="my-4">
              <Col md="6">
                <AvGroup>
                  <Label for="reserve_price">Reserve Price</Label>
                  <AvInput
                    value=""
                    name="reserve_price"
                    id="reserve_price"
                    onChange={e =>
                      handleCarData('reserve_price', e.target.value)
                    }
                  />
                </AvGroup>
              </Col>
            </Row>
          ) : null}
        </div>

        <div className="inner-container my-4 pt-4 px-5 pb-4 form-scroll">
          <Row className="my-4">
            <Header color="red" as="h3">
              <Icon name="cube" />
              Select Your Package
            </Header>
          </Row>
          <Row className="my-4">
            <Col md="12">
              <Label for="package_type">Please select a listing package</Label>
            </Col>
            <Col md="12">
              <ButtonGroup
                id="package_type"
                name="package_type"
                options={[
                  {
                    label: 'Fully Loaded',
                    value: 'Fully Loaded',
                    id: 'fully_loaded',
                  },
                  {
                    label: 'Mid Range',
                    value: 'Mid Range',
                    id: 'mid_range',
                  },
                  {
                    label: 'Base',
                    value: 'Base',
                    id: 'base',
                  },
                ]}
                onChange={selectedValue => {
                  handleCarData('package_type', selectedValue);
                }}
              />
            </Col>
          </Row>
        </div>

        <div className="inner-container my-2 pt-4 px-5 pb-4 form-scroll">
          <Row className="my-4">
            <Header color="red" as="h3">
              <Icon name="photo" />
              Photos
            </Header>
          </Row>
          <Row className="my-4">
            <Col md="12">
              <Label for="photos">Please upload photos of your vehicle.</Label>
            </Col>
            <Col md="12">
              <Dropzone
                classNames={{ dropzone: 'dzu-dropzone previewContainer' }}
                onChangeStatus={handleChangeStatus}
                isUpload={true}
                accept="image/*"
                PreviewComponent={data => {
                  const {
                    imageClassName,
                    className,
                    imageStyle,
                    style,
                    meta: { id, name = '', previewUrl },
                  } = data;
                  if (galleyImagesPercent[id] !== 100) {
                    return (
                      <div className={className} style={style}>
                        {previewUrl && (
                          <img
                            className={imageClassName}
                            style={imageStyle}
                            src={previewUrl}
                            alt={name}
                            title={name}
                          />
                        )}
                        <div className="dzu-previewStatusContainer">
                          <progress max={100} value={galleyImagesPercent[id]} />
                        </div>
                      </div>
                    );
                  } else {
                    return null;
                  }
                }}
              />
              <Row className="gallery-cards">
                {carData.photos.map((photo, i) => {
                  return (
                    <Col className="mb-2" key={i} md="4" xs="4">
                      <Card color="blue" raised>
                        <Image
                          className="gallery-card-image"
                          src={`${AWS_CONFIG.defaultUrl}${photo}`}
                          wrapped
                          ui={false}
                        />
                        <Card.Content
                          className="p-0 py-2 d-flex justify-content-center"
                          extra
                        >
                          <Button
                            basic
                            color="red"
                            onClick={() => {
                              removeImage(i);
                            }}
                            size="small"
                          >
                            Remove
                          </Button>
                        </Card.Content>
                      </Card>
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </Row>
        </div>
        {succesMsg ? (
          <div className="d-flex justify-content-center p-3">
            <Message success size="big">
              {succesMsg}
            </Message>
          </div>
        ) : (
          <div></div>
        )}
        <Button
          size="large"
          className="mt-3"
          onClick={() => postCar()}
          inverted
          color="green"
        >
          Submit
        </Button>
      </div>
    );
  };

  return (
    <div>
      <AvForm>
        <Container className="sellcar-form">
          <Row>
            <div className="sell-car-heading">
              <Col md="12">
                <Header as="h1">
                  <Icon name="handshake outline" />
                  <Header.Content>
                    List Your Car
                    <Header.Subheader></Header.Subheader>
                  </Header.Content>
                </Header>
              </Col>
            </div>
          </Row>
          <Row>
            <Col md="12" className="mt-5 mb-3">
              <p>
                Check out the{' '}
                <a className="contact" href="/how-it-works">
                  How It Works
                </a>{' '}
                section to learn more about different types of auctions.
              </p>
              <Header as="h1">Why List with Us?</Header>
            </Col>
          </Row>
          <Row>
            <Col md="6" className="my-2">
              <Item.Group>
                <Item>
                  <Icon name="map outline" size="big" floated="right" />
                  <Item.Content>
                    <Item.Description
                      verticalAlign="middle"
                      className="sell-car-content-box"
                    >
                      <p className="justify-text">
                        We only list cars in Canada. Buyers can easily arrange
                        pre-purchase inspections, exchange funds, and drive the
                        car home without additional inconveniences at the
                        border.
                      </p>
                    </Item.Description>
                  </Item.Content>
                </Item>
              </Item.Group>
            </Col>
            <Col md="6" className="my-2">
              <Item.Group>
                <Item>
                  <Icon name="lightbulb outline" size="big" floated="right" />
                  <Item.Content>
                    <Item.Description
                      verticalAlign="middle"
                      className="sell-car-content-box"
                    >
                      <p className="justify-text">
                        Our platform is secure, effective, and simple to use.
                      </p>
                    </Item.Description>
                  </Item.Content>
                </Item>
              </Item.Group>
            </Col>
            <Col md="6" className="my-2">
              <Item.Group>
                <Item>
                  <Icon
                    name="money bill alternate outline"
                    size="big"
                    floated="right"
                  />
                  <Item.Content>
                    <Item.Description
                      verticalAlign="middle"
                      className="sell-car-content-box"
                    >
                      <p className="justify-text">
                        It’s{' '}
                        <span className="highlighted-text">free to list</span>{' '}
                        your car. Buyers pay a 4% commission of the final sale
                        price.
                      </p>
                    </Item.Description>
                  </Item.Content>
                </Item>
              </Item.Group>
            </Col>
            <Col md="6" className="my-2">
              <Item.Group>
                <Item>
                  <Icon name="hourglass half" size="big" floated="right" />
                  <Item.Content>
                    <Item.Description
                      verticalAlign="middle"
                      className="sell-car-content-box"
                    >
                      <p className="justify-text">
                        We provide a FREE vehicle history report with every
                        listing!
                      </p>
                    </Item.Description>
                  </Item.Content>
                </Item>
              </Item.Group>
            </Col>
          </Row>
          <Row>
            <Col md="12" className="mt-5 mb-3">
              <Header as="h1">Our Listing Packages</Header>
              <img
                alt="Sell your car"
                className="sell-your-car-img"
                src="/sell-your-car.jpg"
              />
            </Col>
          </Row>
          <Row>
            <Col md="12" className="mt-5 mb-3">
              <Header as="h1">Tell us about your car</Header>
              <p>
                Complete the form below to tell us about your car for us to
                determine if it’s suitable to be featured on onemorecar.ca. If
                your car is accepted, we’ll be in touch to collect more details
                and photos.
              </p>
            </Col>
          </Row>

          {_.isEmpty(props.userData) ? (
            <div className="d-flex justify-content-center p-3">
              <Message error size="large">
                Prior to submitting your car to be featured on onemorecar,
                please make an account or <AuthModal {...props} email="" />.
              </Message>
            </div>
          ) : (
            renderForm()
          )}
        </Container>
      </AvForm>
    </div>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      loginUser,
      signUpUser,
      logOut,
      fogotPassword,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SellCarForm);
