export default {
  items: [
    {
      name: 'Dashboard',
      url: '/admin-dashboard',
      icon: 'icon-speedometer',
    },
    {
      name: 'Auctions',
      url: '/admin-auctions',
      icon: 'icon-compass',
    },
    {
      name: 'Cars',
      url: '/admin-cars',
      icon: 'icon-support',
    },
    {
      name: 'Users',
      url: '/admin-all-users',
      icon: 'icon-user',
    }
  ],
};
