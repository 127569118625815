import React, { useEffect, useState } from 'react';
import { CardGroup } from '../../components';
import { Button, Grid, Header } from 'semantic-ui-react';
import BeatLoader from 'react-spinners/BeatLoader';

const PastAuctions = props => {
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState([]);

  useEffect(() => {
    const req = {
      auction_type: 'all',
      limit: 8,
      offset: offset,
    };
    if (props.search) {
      req.search = props.search;
    }
    const fetchData = async () => {
      const resData = await props.getAuctions(req);
      const auctions = resData?.auctions || [];
      setData([...data, ...auctions]);
      setTotal(resData?.filters?.total || 0);
    };
    fetchData();
    // eslint-disable-next-line
  }, [offset]);

  return (
    <div className="card-container">
      {props.header ? <Header as="h2">{props.header}</Header> : null}
      <CardGroup {...props} rows={data || []} />
      {!data.length && !props.loading ? (
        <Grid className="no-data my-3" centered>
          No Results Found
        </Grid>
      ) : null}
      {props.loading ? (
        <Grid className="my-3" centered>
          <BeatLoader size={10} loading color="#db2828" />
        </Grid>
      ) : null}
      {data.length < total ? (
        <Grid className="mt-0" centered>
          <Button
            onClick={() => {
              setOffset(offset + 8);
            }}
            disabled={props.loading}
            loading={props.loading}
            basic
            color="red"
          >
            Load More
          </Button>
        </Grid>
      ) : null}
    </div>
  );
};

export default PastAuctions;
