import React, { useState } from 'react';
import { Button, Icon, Divider } from 'semantic-ui-react';
import _ from 'lodash';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Row,
} from 'reactstrap';
import { AvForm, AvInput } from 'availity-reactstrap-validation';
import { AWS_CONFIG } from '../constant';
import './ViewCarModal.scss';

export const ViewCarModal = props => {
  const [carData, setCarData] = useState({});
  const [open, setOpen] = useState(false);

  const toggle = async (getData = false) => {
    if (getData) {
      const data = await props.getAdminCar(props.id);
      setCarData(data);
    } else {
      setCarData({});
    }
    setOpen(!open);
  };

  const approveCar = async id => {
    await props.approveCar(id);
    toggle(false);
  };

  const rejectCar = async id => {
    await props.rejectCar(id);
    toggle(false);
  };

  const openImage = url => {
    window.open(url, '_blank');
  };

  return (
    <div>
      <Button
        inverted
        size="mini"
        color="blue"
        animated
        onClick={() => toggle(true)}
      >
        <Button.Content hidden>
          <Icon name="eye" />
        </Button.Content>
        <Button.Content visible>View</Button.Content>
      </Button>
      <Modal isOpen={open} size="lg">
        <ModalHeader toggle={() => toggle(false)} charCode="X">
          Car Details
        </ModalHeader>
        <ModalBody>
          <div>
            <AvForm>
              <Row className="my-3">
                <Col md="6">
                  <label>Seller Name</label>
                  <AvInput
                    disabled
                    value={carData.seller_name}
                    name="seller_name"
                    id="seller_name"
                  />
                </Col>
                <Col md="6">
                  <label>Contact No</label>
                  <AvInput
                    disabled
                    value={carData.phone_number}
                    name="phone_number"
                    id="phone_number"
                  />
                </Col>
              </Row>

              <Row className="my-3">
                <Col md="6">
                  <label>User Name</label>
                  <AvInput
                    disabled
                    value={carData.user_name}
                    name="user_name"
                    id="user_name"
                  />
                </Col>
                <Col md="6">
                  <label>User Email</label>
                  <AvInput
                    disabled
                    value={carData.user_email}
                    name="user_email"
                    id="user_email"
                  />
                </Col>
              </Row>

              <Row className="my-3">
                <Col md="6">
                  <label>Seller Type</label>
                  <AvInput
                    disabled
                    value={_.startCase(carData.seller_type)}
                    name="seller_type"
                    id="seller_type"
                  />
                </Col>
                <Col md="6">
                  <label>Listing Package</label>
                  <AvInput
                    disabled
                    value={carData.package_type}
                    name="package_type"
                    id="package_type"
                  />
                </Col>
              </Row>

              <Row className="my-3">
                <Col md="6">
                  <label>Billing Type</label>
                  <AvInput
                    disabled
                    value={_.startCase(carData.billing_type)}
                    name="billing_type"
                    id="billing_type"
                  />
                </Col>
                {carData.billing_type === 'reserve' ? (
                  <Col md="6">
                    <label>Reserve Price</label>
                    <AvInput
                      disabled
                      value={carData.reserve_price}
                      name="reserve_price"
                      id="reserve_price"
                    />
                  </Col>
                ) : (
                  <Col md="6"></Col>
                )}
              </Row>
              <Row className="my-3">
                <Col md="6">
                  <label>Make</label>
                  <AvInput
                    disabled
                    value={carData.make}
                    name="make"
                    id="make"
                  />
                </Col>
                <Col md="6">
                  <label>Model</label>
                  <AvInput
                    disabled
                    value={carData.model}
                    name="model"
                    id="model"
                  />
                </Col>
              </Row>
              <Row className="my-3">
                <Col md="6">
                  <label>Year</label>
                  <AvInput
                    disabled
                    value={carData.year}
                    name="year"
                    id="year"
                  />
                </Col>
                <Col md="6">
                  <label>Mileage</label>
                  <AvInput
                    disabled
                    value={carData.mileage}
                    name="mileage"
                    id="mileage"
                  />
                </Col>
              </Row>
              <Row className="my-3">
                <Col md="6">
                  <label>VIN</label>
                  <AvInput disabled value={carData.vin} name="vin" id="vin" />
                </Col>
                <Col md="6">
                  <label>Location</label>
                  <AvInput
                    disabled
                    value={carData.location}
                    name="location"
                    id="location"
                  />
                </Col>
              </Row>
              <Row className="my-3">
                <Col md="6">
                  <Row>
                    <Col md="6">
                      <label>Is vehicle titled ? </label>
                      <AvInput
                        disabled
                        value={carData.is_vehicle_titled ? 'Yes' : 'No'}
                        name="is_vehicle_titled"
                        id="is_vehicle_titled"
                      />
                    </Col>

                    <Col md="6">
                      <label>Title Status</label>
                      <AvInput
                        disabled
                        value={_.startCase(carData.title_status)}
                        name="title_status"
                        id="title_status"
                      />
                    </Col>
                  </Row>
                </Col>
                <Col md="6">
                  <label>Is there a lienholder ? </label>
                  <AvInput
                    disabled
                    value={carData.is_there_lienholder ? 'Yes' : 'No'}
                    name="is_there_lienholder"
                    id="is_there_lienholder"
                  />
                </Col>
              </Row>
              <Row className="my-3">
                <Col md="8">
                  <label>Features</label>
                  <AvInput
                    disabled
                    value={carData.features}
                    name="features"
                    id="features"
                    type="textarea"
                  />
                </Col>
              </Row>
              {carData.photos && carData.photos.length ? (
                <Row className="my-3">
                  <Col md="8">
                    <label>Photos</label>
                  </Col>

                  {carData.photos.map(photo => {
                    return (
                      <div className="col-xs-12 col-sm-12 col-md-6 image-div">
                        <img
                          className="photo"
                          src={`${AWS_CONFIG.defaultUrl}${photo}`}
                          alt="car"
                        />
                        <Button
                          download
                          className="mt-3 image-download"
                          color="blue"
                          onClick={() =>
                            openImage(`${AWS_CONFIG.defaultUrl}${photo}`)
                          }
                        >
                          Download Image
                        </Button>
                      </div>
                    );
                  })}
                </Row>
              ) : (
                <div></div>
              )}
            </AvForm>
            <Divider horizontal />
          </div>

          <div>
            <Row>
              <Col md="6" className="d-flex justify-content-center">
                <Button
                  size="large"
                  className="mt-3"
                  inverted
                  color="green"
                  onClick={() => approveCar(carData._id)}
                  disabled={carData.status === 'approved' ? true : false}
                >
                  Approve
                </Button>
              </Col>
              <Col md="6" className="d-flex justify-content-center">
                {' '}
                <Button
                  size="large"
                  className="mt-3"
                  inverted
                  color="red"
                  onClick={() => rejectCar(carData._id)}
                  disabled={carData.status === 'rejected' ? true : false}
                >
                  Reject
                </Button>
              </Col>
            </Row>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => toggle(false)}>Close</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
