import { NotificationManager } from 'react-notifications';
import { AuthAxios } from '../../../index';
import { CARS_LOADING, SET_CAR_LIST } from './actions.js';

export const getAdminCars = req => {
  return async dispatch => {
    try {
      dispatch({ type: CARS_LOADING, payload: true });
      const response = await AuthAxios({
        method: 'get',
        url: '/admin/cars',
        params: req,
      });
      const data = response?.data?.data || {};
      dispatch({ type: SET_CAR_LIST, payload: data });
      dispatch({ type: CARS_LOADING, payload: false });
      return data;
    } catch (error) {
      dispatch({ type: CARS_LOADING, payload: false });
      const message = error?.response?.data?.errorMessage || '';
      NotificationManager.error(message || 'Oops, Something went wrong!');
    }
  };
};

export const getAdminCar = id => {
  return async dispatch => {
    try {
      const response = await AuthAxios({
        method: 'get',
        url: `/admin/car/${id}`,
      });
      const data = response?.data?.data || {};
      return data;
    } catch (error) {
      const message = error?.response?.data?.errorMessage || '';
      NotificationManager.error(message || 'Oops, Something went wrong!');
    }
  };
};

export const approveAdminCar = id => {
  return async dispatch => {
    try {
      const res = await AuthAxios({
        method: 'put',
        url: `/admin/car-approve/${id}`,
      });
      NotificationManager.success(res?.data?.data);
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      const message = error?.response?.data?.errorMessage || '';
      NotificationManager.error(message || 'Oops, Something went wrong!');
    }
  };
};

export const rejectAdminCar = id => {
  return async dispatch => {
    try {
      const res = await AuthAxios({
        method: 'delete',
        url: `/admin/car-reject/${id}`,
      });
      NotificationManager.success(res?.data?.data);
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      const message = error?.response?.data?.errorMessage || '';
      NotificationManager.error(message || 'Oops, Something went wrong!');
    }
  };
};
