import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loginUser, signUpUser, logOut } from '../../store/actions';
import HomeContainer from './HomeContainer';
import './Home.scss';

const Home = props => {
  return (
    <div className="app mb-3">
      <HomeContainer {...props} />
    </div>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      loginUser,
      signUpUser,
      logOut,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
