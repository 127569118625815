import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getAuctions,
  getUserWishList,
  postWishList,
  deleteUserWishList,
} from '../../store/actions';
import { CardGroup } from '../../components';
import { Button, Grid, Header } from 'semantic-ui-react';
import BeatLoader from 'react-spinners/BeatLoader';
import { Helmet } from 'react-helmet';

const FoundInMarket = props => {
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState([]);
  useEffect(() => {
    const req = {
      adz_type: 'found_in_market',
      limit: 8,
      offset: offset,
    };
    document.title = 'Cool Cars from the Classifieds | Onemorecar.ca';
    const feachData = async () => {
      const resData = await props.getAuctions(req);
      const auctions = resData?.auctions || [];
      setData([...data, ...auctions]);
      setTotal(resData?.filters?.total || 0);
      if (props.user.role) {
        await props.getUserWishList();
      }
    };
    feachData();
    // eslint-disable-next-line
  }, [offset]);
  return (
    <div className="card-container pt-3">
      <Helmet>
        <title>{'Marketplace Watch - Onemorecar'}</title>
        <meta name="title" content={'Marketplace Watch - onemorecar'} />
        <meta
          name="description"
          content="A collection of cool cars currently for sale on the local classifieds"
        />
        <meta
          name="image"
          property="image"
          content="https://onemorecar.ca/seo-black.png"
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@onemorecar" />
        <meta name="twitter:creator" content="@onemorecar" />
        <meta name="twitter:title" content={'Marketplace Watch - Onemorecar'} />
        <meta
          name="twitter:description"
          content="A collection of cool cars currently for sale on the local classifieds"
        />
        <meta
          name="twitter:image"
          content="https://onemorecar.ca/seo-black.png"
        />

        <meta
          property="og:image"
          content="https://onemorecar.ca/seo-black.png"
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={'Marketplace Watch - Onemorecar'} />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:description"
          content="A collection of cool cars currently for sale on the local classifieds"
        />
      </Helmet>

      <Header as="h2">Marketplace Watch</Header>
      <p class="justify-text">
        A collection of cool cars currently for sale on the local classifieds.
        Send us an email at{' '}
        <a class="contact" href="mailto:marketplacewatch@onemorecar.ca">
          marketplacewatch@onemorecar.ca
        </a>{' '}
        if you spot an interesting car on your favourite classifieds site and we
        just may feature it here!
      </p>
      <p class="justify-text">
        <b>Terms of use:</b> We have no affiliations with the vehicles listed in
        Marketplace Watch and do not warrant the condition of the vehicles or
        the credibility of the sellers. Please perform your own due diligence.
      </p>
      <CardGroup
        wishList={props.auctions?.wishList?.wish_list_auction_ids || []}
        hideLable
        hideFeature
        {...props}
        rows={data || []}
      />
      {!data.length && !props.auctions.getAuctionsLoading ? (
        <Grid className="no-data my-3" centered>
          No Results Found
        </Grid>
      ) : null}
      {props.auctions.getAuctionsLoading ? (
        <Grid className="my-3" centered>
          <BeatLoader size={10} loading color="#db2828" />
        </Grid>
      ) : null}
      {data.length < total ? (
        <Grid className="mt-0" centered>
          <Button
            onClick={() => {
              setOffset(offset + 8);
            }}
            disabled={props.auctions.getAuctionsLoading}
            loading={props.auctions.getAuctionsLoading}
            basic
            color="red"
          >
            Load More
          </Button>
        </Grid>
      ) : null}
    </div>
  );
};

const mapStateToProps = state => ({
  auctions: state.auctions,
  user: state.auth.userData,
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getAuctions,
      getUserWishList,
      postWishList,
      deleteUserWishList,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(FoundInMarket);
