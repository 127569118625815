import React from 'react';

const Dashboard = React.lazy(() => import('../dashboard/Dashboard'));
const Auctions = React.lazy(() => import('../auctions/Auctions'));
const Cars = React.lazy(() => import('../cars/Cars'));
const UserList = React.lazy(() => import('../userList/UserList'));

const routes = [
  {
    path: '/admin-dashboard',
    exact: true,
    name: 'Dashboard',
    component: Dashboard,
  },
  {
    path: '/admin-auctions',
    exact: true,
    name: 'Auctions',
    component: Auctions,
  },
  {
    path: '/admin-cars',
    exact: true,
    name: 'Cars',
    component: Cars,
  },
  {
    path: '/admin-all-users',
    exact: true,
    name: 'UsersList',
    component: UserList,
  }
];

export default routes;
