import React from 'react';
import { Image, Modal, Icon, Segment } from 'semantic-ui-react';
import { images } from '../theme/images';
import './ImageUpload.scss';

export const ImageView = props => {
  const { src, imageUploadLoading } = props;
  const inlineStyle = {
    modal: {
      height: 'auto',
      top: 'auto',
      left: 'auto',
      bottom: 'auto',
      right: 'auto',
    },
  };
  if (src === images.dummy) {
    return (
      <Segment loading={imageUploadLoading}>
        <Image src={src} />
      </Segment>
    );
  }
  return (
    <Modal
      trigger={
        <Segment className="image-container" loading={imageUploadLoading}>
          <Image className="upload-cover-image" src={src} />
        </Segment>
      }
      style={inlineStyle.modal}
      basic
      size="small"
    >
      <Modal.Content className="image-view">
        <Image className="modal-image-view" src={src} />
      </Modal.Content>
    </Modal>
  );
};

export const AvFieldUpload = props => {
  const getImage = event => {
    if (event.target.files && event.target.files.length) {
      const { uploadImage } = props;
      if (uploadImage) {
        uploadImage(event.target.files[0]);
      }
    } else {
      return false;
    }
  };

  const { label, name, accept, helpMessage, buttonText } = props;
  return (
    <div className="av-upload">
      <div className="form-group">
        <div className="upload-container">
          <div className="upload-area">
            <label>{label}</label>
            <input
              name={name}
              id={name}
              type="file"
              className="org-input-file is-untouched is-pristine av-valid form-control-file"
              accept={accept}
              multiple={false}
              onChange={e => getImage(e)}
            />
            <label htmlFor={name} className="custom-file-upload">
              <Icon name="plus circle" />
              {buttonText}
            </label>
            <small className="text-muted">{helpMessage}</small>
          </div>
          <div className="upload-image-area">
            <div className="area">
              <ImageView {...props} src={props.image || images.dummy} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
