import React, { useState } from 'react';
import { AWS_CONFIG } from '../../constant';
import { Button, Modal, Image, Header, Divider } from 'semantic-ui-react';
import { Row, Col } from 'reactstrap';
import {
  AvForm,
  AvGroup,
  AvInput,
  AvFeedback,
} from 'availity-reactstrap-validation';

const BidStepOne = props => {
  const [bidAmount, setBidAmount] = useState('');
  const [buttonDisabled, setButtonStatus] = useState(true);

  return (
    <div>
      <Modal.Header id="bid-modal-header">
        <div className="position-absolute close-icon">
          <button
            onClick={() => {
              props.closeModal();
              props.setStep(1);
            }}
            type="button"
            className="close"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="d-flex justify-content-center">
          <Image
            className="bid-image"
            src={`${AWS_CONFIG.defaultUrl}${props.auction.feature_image}`}
          />
        </div>
      </Modal.Header>
      <Modal.Content id="bid-modal-content">
        <Header className="my-2 text-center" as="h2">
          {props.auction.title}
        </Header>
        <Divider horizontal>
          <Header disabled className="text-center" as="h3">
            {props.duration} &nbsp;&nbsp;&nbsp; Current Bid $
            {props.auction.current_bid_amount || 0}
          </Header>
        </Divider>

        <AvForm>
          <Row>
            <Col xs="9">
              <AvGroup>
                <AvInput
                  type="number"
                  placeholder={`Bid CAD $${(props.auction.current_bid_amount ||
                    0) + props.auction.minimum_bid_amount} or more`}
                  name="bidAmount"
                  id="bidAmount"
                  value={bidAmount}
                  onChange={e => {
                    setBidAmount(e.target.value);
                    if (
                      e.target.value >=
                      (props.auction.current_bid_amount || 0) +
                        props.auction.minimum_bid_amount
                    ) {
                      setButtonStatus(false);
                    } else {
                      setButtonStatus(true);
                    }
                  }}
                  required
                />
                <AvFeedback>Bid amount shouldn't be empty.</AvFeedback>
              </AvGroup>
            </Col>
            <Col className="pl-0" xs="3">
              <Button
                onClick={() => {
                  props.setStep(2);
                  props.setBidAmount(bidAmount);
                }}
                disabled={buttonDisabled}
                color="red"
              >
                BID
              </Button>
            </Col>
          </Row>
        </AvForm>

        <Header className="mt-2" disabled as="h5">
          Minimum bid increment is ${props.auction.minimum_bid_amount}. All bids
          in CAD.
        </Header>
      </Modal.Content>
    </div>
  );
};

export default BidStepOne;
