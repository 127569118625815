import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getUserDetail, getUserBidHistory } from '../../store/actions';
import { Image, Header, Menu, Segment } from 'semantic-ui-react';
import { AWS_CONFIG } from '../../constant';
import { images } from '../../theme/images';
import { CardGroup } from '../../components/';
import moment from 'moment';
import BeatLoader from 'react-spinners/BeatLoader';

const User = props => {
  const [userDetail, setUserDetails] = useState({});
  const [bidHistory, setBidHistory] = useState({});
  const [activeItem, setActiveItem] = useState('Cars Auctioned');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const pathname = window.location.pathname;
    const id = pathname.split('/')[2];
    const feachData = async () => {
      setLoading(true);
      const [userDetail, bidHistory] = await Promise.all([
        props.getUserDetail(id),
        props.getUserBidHistory(id),
      ]);
      setLoading(false);
      setUserDetails(userDetail);
      setBidHistory(bidHistory);
    };
    feachData();
    // eslint-disable-next-line
  }, [window.location.pathname]);

  const handleItemClick = ({ name }) => setActiveItem(name);

  return (
    <div className="mt-3">
      <Image
        className="profile-image mb-0"
        src={
          userDetail?.avatar
            ? AWS_CONFIG.defaultUrl + userDetail?.avatar
            : images.avatar
        }
        size="small"
        centered
        circular
      />
      <Header className="m-0" textAlign="center" as="h1">
        {userDetail?.user_name}
      </Header>
      {userDetail?.createdAt && (
        <p className="text-center">
          Joined {moment(userDetail?.createdAt).format('MMMM YYYY')}
        </p>
      )}
      <Menu color="red" className="profile-menu" attached="top" tabular>
        <Menu.Item
          name="Cars Auctioned"
          active={activeItem === 'Cars Auctioned'}
          onClick={(e, data) => {
            handleItemClick(data);
          }}
        />
        <Menu.Item
          name="Bid History"
          active={activeItem === 'Bid History'}
          onClick={(e, data) => {
            handleItemClick(data);
          }}
        />
      </Menu>
      {loading ? (
        <div className="d-flex justify-content-center">
          <BeatLoader size={6} loading color="#db2828" />
        </div>
      ) : (
        <Segment className="profile-segment" color="red" attached="bottom">
          {activeItem === 'Cars Auctioned' && (
            <div>
              <Header>Cars Auctioned</Header>
              <div className="card-container mt-3">
                {userDetail?.auctions && (
                  <CardGroup rows={userDetail?.auctions || []} />
                )}
              </div>
            </div>
          )}
          {activeItem === 'Bid History' && (
            <div>
              <Header>Bid History</Header>
              <div className="card-container mt-3">
                {bidHistory?.auctions && (
                  <CardGroup rows={bidHistory?.auctions || []} />
                )}
              </div>
            </div>
          )}
        </Segment>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.auth.userData,
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getUserDetail,
      getUserBidHistory,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(User);
