import React, { useState } from 'react';
import {
  AvForm,
  AvGroup,
  AvInput,
  AvFeedback,
} from 'availity-reactstrap-validation';
import { Label } from 'reactstrap';
import { Button, Icon } from 'semantic-ui-react';

const handleSubmit = async (values, props) => {
  const { loginUser, closeModal } = props;
  await loginUser(values, closeModal);
};

export const SignInForm = props => {
  const [passwordShown, setPasswordShown] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  return (
    <AvForm onValidSubmit={(event, values) => handleSubmit(values, props)}>
      <AvGroup>
        <Label for="email">Email address / User name</Label>
        <AvInput name="email" id="email" required />
        <AvFeedback>Email address or User name shouldn't be empty.</AvFeedback>
      </AvGroup>
      <AvGroup>
        <Label for="password">Password</Label>
        <div className="pass-wrapper">
          <AvInput
            name="password"
            id="password"
            type={passwordShown ? 'text' : 'password'}
            required
          />
          <Icon onClick={togglePasswordVisibility} name="eye" />
          <AvFeedback>Password shouldn't be empty.</AvFeedback>
        </div>
      </AvGroup>
      <Button className="w-100" inverted color="red" size="large">
        Sign In
      </Button>
    </AvForm>
  );
};
