import { AuthAxios } from '../../../index';

import { CAR_LOADING } from './actions.js';

export const postCar = req => {
  return async dispatch => {
    try {
      dispatch({ type: CAR_LOADING, payload: true });
      const res = await AuthAxios({
        method: 'post',
        url: '/user/car',
        data: req,
      });

      const data = res?.data?.data;
      return data;
    } catch (error) {
      dispatch({ type: CAR_LOADING, payload: false });
      const errorObject = error?.response?.data;
      return errorObject;
    }
  };
};
