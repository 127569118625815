import React, { useState } from 'react';
import {
  AvForm,
  AvGroup,
  AvInput,
  AvFeedback,
} from 'availity-reactstrap-validation';
import { Label } from 'reactstrap';
import { Button, Icon } from 'semantic-ui-react';

const handleSubmit = async (values, props) => {
  const { signUpUser, closeModal } = props;
  await signUpUser(values, closeModal);
};

export const SignUpForm = props => {
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordShown2, setPasswordShown2] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const togglePasswordVisibility2 = () => {
    setPasswordShown2(passwordShown2 ? false : true);
  };
  return (
    <AvForm onValidSubmit={(event, values) => handleSubmit(values, props)}>
      <AvGroup>
        <Label for="user_name">Create a username</Label>
        <AvInput name="user_name" id="user_name" required />
        <AvFeedback>Please enter a username.</AvFeedback>
      </AvGroup>
      <AvGroup>
        <Label for="email">Email address</Label>
        <AvInput name="email" id="email" required />
        <AvFeedback>Please enter your email address.</AvFeedback>
      </AvGroup>
      <AvGroup>
        <Label for="password">Password</Label>
        <div className="pass-wrapper">
          <AvInput
            name="password"
            id="password"
            type={passwordShown ? 'text' : 'password'}
            required
          />
          <Icon onClick={togglePasswordVisibility} name="eye" />
          <AvFeedback>Please enter your password.</AvFeedback>
        </div>
      </AvGroup>
      <AvGroup>
        <Label for="confirm_password">Confirm password</Label>
        <div className="pass-wrapper">
          <AvInput
            name="confirm_password"
            id="confirm_password"
            type={passwordShown2 ? 'text' : 'password'}
            required
          />
          <Icon onClick={togglePasswordVisibility2} name="eye" />
          <AvFeedback>Please repeat your password.</AvFeedback>
        </div>
        
      </AvGroup>
      <Button className="w-100" inverted color="red" size="large">
        Sign up
      </Button>
    </AvForm>
  );
};
