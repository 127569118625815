import React, { useState } from 'react';
import { Button, Header, Modal, Message } from 'semantic-ui-react';
import { images } from '../theme/images';
import {
  AvForm,
  AvGroup,
  AvInput,
  AvFeedback,
} from 'availity-reactstrap-validation';
import { Label } from 'reactstrap';
import { PaymentInputsWrapper, usePaymentInputs } from 'react-payment-inputs';
import paymentImages from 'react-payment-inputs/images';
import { isMobile } from 'react-device-detect';
import { NotificationManager } from 'react-notifications';

export const PaymentDetail = ({
  cardDetail,
  loading,
  addCardDetail,
  onlyContent,
  closePropsModal,
  setStep,
}) => {
  const [openBID, setOpenBID] = useState(false);
  const [error, setError] = useState('');
  const [paymentDetails, setPaymentDetails] = useState({
    number: '',
    date: '',
    cvc: '',
  });
  const closeModal = () => {
    setOpenBID(false);
    closePropsModal && closePropsModal(false);
  };

  const inlineStyle = {
    modal: {
      height: 'auto',
      top: 'auto',
      left: 'auto',
      bottom: 'auto',
      right: 'auto',
    },
  };

  const renderContent = () => {
    return (
      <>
        <Modal.Header className="sign-up-modal-header">
          <button
            onClick={() => {
              closeModal();
            }}
            type="button"
            className="close"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <div className="d-flex justify-content-center">
            {
              // eslint-disable-next-line
              <img
                alt="auth-image"
                className="auth-image"
                width={isMobile ? '135px' : '275px'}
                height={isMobile ? '25px' : '50px'}
                src={images.admin_logo}
              />
            }
          </div>
          <Header className="mt-2">Register to BID</Header>
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <p>
            Please enter your credit card information on the form below. Onemorecar.ca utilizes an
advanced SSL certificate to keep your data private. Information you send or get through the
site is private.
            </p>
            <Header as="h5">Credit Card Information</Header>
            {error && (
              <Message negative>
                <Message.Header>{error}</Message.Header>
              </Message>
            )}
            <AvForm
              onValidSubmit={(event, values) => {
                const req = {
                  number: '',
                  exp_month: '',
                  exp_year: '',
                  cvc: '',
                  name: '',
                };
                if (!paymentDetails.number) {
                  NotificationManager.error('Card number is required.');
                  return setError('Card number is required.');
                } else if (
                  !paymentDetails.date.replace(/ /g, '').split('/')[0] ||
                  !paymentDetails.date.replace(/ /g, '').split('/')[1]
                ) {
                  NotificationManager.error('Expire date is required.');
                  return setError('Expire date is required.');
                } else if (!paymentDetails.cvc) {
                  NotificationManager.error('CVC is required.');
                  return setError('CVC is required.');
                }
                setError('');
                req.number = paymentDetails.number.replace(/ /g, '');
                req.exp_month = paymentDetails.date
                  .replace(/ /g, '')
                  .split('/')[0];
                req.exp_year =
                  '20' + paymentDetails.date.replace(/ /g, '').split('/')[1];
                req.cvc = paymentDetails.cvc;
                req.name = values.name;
                addCardDetail(req, () => {
                  !onlyContent && closeModal();
                  setStep && setStep(1);
                });
              }}
            >
              <AvGroup>
                <Label for="name">Name on card</Label>
                <AvInput name="name" id="name" required />
                <AvFeedback>Name on card shouldn't be empty.</AvFeedback>
              </AvGroup>
              <PaymentInputsWrapper className="w-100 mb-3" {...wrapperProps}>
                <svg {...getCardImageProps({ images: paymentImages })} />
                <input
                  {...getCardNumberProps({
                    onChange: e =>
                      setPaymentDetails({
                        ...paymentDetails,
                        number: e.target.value,
                      }),
                  })}
                />
                <input
                  {...getExpiryDateProps({
                    onChange: e =>
                      setPaymentDetails({
                        ...paymentDetails,
                        date: e.target.value,
                      }),
                  })}
                />
                <input
                  {...getCVCProps({
                    onChange: e =>
                      setPaymentDetails({
                        ...paymentDetails,
                        cvc: e.target.value,
                      }),
                  })}
                />
              </PaymentInputsWrapper>

              <Button
                disabled={loading}
                loading={loading}
                className="w-100"
                inverted
                color="green"
                size="large"
              >
                {cardDetail?.last4
                  ? 'Update payment details'
                  : 'Add payment details'}
              </Button>
            </AvForm>
          </Modal.Description>
        </Modal.Content>
      </>
    );
  };

  const {
    wrapperProps,
    getCardImageProps,
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps,
  } = usePaymentInputs();
  if (onlyContent) {
    return renderContent();
  }
  return (
    <Modal
      size="tiny"
      onClose={() => {
        setOpenBID(false);
      }}
      onOpen={() => {
        setOpenBID(true);
      }}
      open={openBID}
      trigger={
        <Button className="w-100" color="green">
          {cardDetail?.last4 ? 'Update payment details' : 'Add payment details'}
        </Button>
      }
      style={inlineStyle.modal}
      closeOnDimmerClick={false}
      className="sign-up-modal"
    >
      {renderContent()}
    </Modal>
  );
};
