import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import history from '../../../lib/history';
import { AuthAxios } from '../../../index';

import {
  USER_LOGIN_LOADING,
  SET_USER_DATA,
  GET_USER_LIST_LOADING,
  SET_USER_LIST,
} from './actions.js';

export const loginUser = (req, closeModal) => {
  return async dispatch => {
    try {
      dispatch({ type: USER_LOGIN_LOADING, payload: true });
      const response = await axios.post('/login', req);
      const token = response?.data?.data?.token || '';
      const data = response?.data?.data?.user || {};
      AuthAxios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      closeModal();
      dispatch({ type: SET_USER_DATA, payload: data });
      await Promise.all([
        localStorage.setItem('auth-token', token),
        localStorage.setItem('ls.user', JSON.stringify(data)),
      ]);
      dispatch({ type: USER_LOGIN_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: USER_LOGIN_LOADING, payload: false });
      const message = error?.data?.errorMessage || '';
      NotificationManager.error(message || 'Oops, Something went wrong!');
    }
  };
};

export const signUpUser = (req, closeModal) => {
  return async dispatch => {
    try {
      dispatch({ type: USER_LOGIN_LOADING, payload: true });
      await axios.post('/sign-up', req);
      closeModal();
      NotificationManager.success(
        'Congratulations! You have successfully signed-up'
      );
      dispatch({ type: USER_LOGIN_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: USER_LOGIN_LOADING, payload: false });
      const message = error?.data?.errorMessage || '';
      NotificationManager.error(message || 'Oops, Something went wrong!');
    }
  };
};

export const logOut = () => {
  return async dispatch => {
    try {
      history.push('/');
      localStorage.clear();
      dispatch({ type: 'RESET' });
    } catch (error) {
      dispatch({ type: USER_LOGIN_LOADING, payload: false });
    }
  };
};

export const getAllUsers = (req) => {
  return async dispatch => {
    try {
      dispatch({ type: GET_USER_LIST_LOADING, payload: true });
      const res = await AuthAxios({
        method: 'get',
        url: '/admin/user/list',
        params: req,
      });
      const data = res?.data?.data;
      dispatch({ type: SET_USER_LIST, payload: data || [] });
      dispatch({ type: GET_USER_LIST_LOADING, payload: false });
      return data;
    } catch (error) {
      dispatch({ type: GET_USER_LIST_LOADING, payload: false });
    }
  };
};

export const getUser = () => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: GET_USER_LIST_LOADING, payload: true });
      const res = await AuthAxios({
        method: 'GET',
        url: 'user/own-profile',
      });
      const data = res?.data?.data;
      const state = getState();
      const userData = { ...state?.auth?.userData, ...data };
      dispatch({ type: SET_USER_DATA, payload: userData });
      dispatch({ type: GET_USER_LIST_LOADING, payload: false });
      return data;
    } catch (error) {
      dispatch({ type: GET_USER_LIST_LOADING, payload: false });
    }
  };
};

export const updateUser = (req, setLoding) => {
  return async dispatch => {
    try {
      dispatch({ type: GET_USER_LIST_LOADING, payload: true });
      setLoding(true);
      const res = await AuthAxios({
        method: 'PUT',
        url: '/user/profile',
        data: req,
      });
      const data = res?.data?.data;
      await dispatch(getUser());
      setLoding(false);
      return data;
    } catch (error) {
      setLoding(false);
      dispatch({ type: GET_USER_LIST_LOADING, payload: false });
    }
  };
};

export const resetPassword = (req, closeModal, setLoding, setError) => {
  return async dispatch => {
    try {
      setLoding(true);
      const res = await AuthAxios({
        method: 'POST',
        url: '/user/reset-password',
        data: req,
      });
      closeModal();
      const data = res?.data?.data;
      NotificationManager.success(data);
      setLoding(false);
      return data;
    } catch (error) {
      const message = error?.response?.data?.errorMessage || '';
      setError(message);
      NotificationManager.error(message || 'Oops, Something went wrong!');
      setLoding(false);
    }
  };
};

export const getCard = () => {
  return async dispatch => {
    try {
      const res = await AuthAxios({
        method: 'GET',
        url: 'user/card',
      });
      const data = res?.data?.data;
      return data;
    } catch (error) {
      const message = error?.response?.data?.errorMessage || '';
      NotificationManager.error(message || 'Oops, Something went wrong!');
    }
  };
};

export const addCardDetail = (req, closeModal) => {
  return async dispatch => {
    try {
      const res = await AuthAxios({
        method: 'POST',
        url: 'user/card',
        data: req,
      });
      const data = res?.data?.data;
      NotificationManager.success(data);
      closeModal();
      return data;
    } catch (error) {
      const message = error?.response?.data?.errorMessage || '';
      NotificationManager.error(message || 'Oops, Something went wrong!');
    }
  };
};
