import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SellCarForm from './SellCarForm';
import { postCar, getCard } from '../../store/actions';
import { Helmet } from 'react-helmet';
import './SellCar.scss';

const SellCar = props => {
  const [carData, setCarData] = useState(null);
  useEffect(() => {
    document.title = 'List Your Car | Onemorecar.ca ';
  });
  return (
    <div className="app">
      <Helmet>
          <title>{'List Your Car - Onemorecar'}</title>
          <meta name="title" content={'List Your Car - onemorecar'}/>
          <meta name="description" content="Onemorecar.ca was founded to be a platform for Canadian car enthusiasts to come together to buy, sell and discuss classic, speciality and modern vehicles" />
          <meta name="image" property="image" content="https://onemorecar.ca/seo-black.png"/>

          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@onemorecar" />
          <meta name="twitter:creator" content="@onemorecar" />
          <meta name="twitter:title" content={'List Your Car - Onemorecar'} />
          <meta name="twitter:description" content="Onemorecar.ca was founded to be a platform for Canadian car enthusiasts to come together to buy, sell and discuss classic, speciality and modern vehicles" />
          <meta name="twitter:image" content="https://onemorecar.ca/seo-black.png" />
          
          <meta property="og:image" content="https://onemorecar.ca/seo-black.png" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={'List Your Car - Onemorecar'} />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:description" content="Onemorecar.ca was founded to be a platform for Canadian car enthusiasts to come together to buy, sell and discuss classic, speciality and modern vehicles" />
      </Helmet>
      <SellCarForm
        userData={props.auth.userData}
        postCar={props.postCar}
        getCard={props.getCard}
        carData={carData}
        setCarData={setCarData}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      postCar,
      getCard,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SellCar);
