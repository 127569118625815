import React, { useState } from 'react';
import { Image, Button, Progress } from 'semantic-ui-react';
import { images } from '../../theme/images';
import {
  AvForm,
  AvGroup,
  AvInput,
  AvFeedback,
} from 'availity-reactstrap-validation';
import { Label, Row, Col } from 'reactstrap';
import { AWS_CONFIG } from '../../constant';

const Account = ({
  profileData,
  uploadPrgress,
  updateUserData,
  uploadFile,
}) => {
  const [loading, setLoding] = useState(false);
  return (
    <div className="profile-detail">
      <Image
        className="profile-image mb-3"
        src={
          profileData?.avatar
            ? AWS_CONFIG.defaultUrl + profileData?.avatar
            : images.avatar
        }
        size="small"
        centered
      />
      <div className="d-flex justify-content-center mt-3">
        {uploadPrgress.avatars ? (
          <div>
            <Progress
              className="progress-bar"
              percent={uploadPrgress.avatars}
              indicating
            />
          </div>
        ) : (
          <div>
            <input
              type="file"
              className="inputfile"
              id="embedpollfileinput"
              onChange={event => {
                if (event.target.files && event.target.files.length) {
                  uploadFile(event.target.files[0], 'avatars');
                }
              }}
            />

            <label
              htmlFor="embedpollfileinput"
              className="ui blue right inverted button"
            >
              <i className="ui upload icon"></i>
              Upload Image
            </label>
          </div>
        )}
      </div>
      <AvForm
        onValidSubmit={(event, values) => updateUserData(values, setLoding)}
        className="mt-4"
      >
        <Row>
          <Col md="6">
            <AvGroup>
              <Label for="user_name">User Name</Label>
              <AvInput
                value={profileData?.user_name}
                name="user_name"
                id="user_name"
                readOnly="readOnly"
                required
              />
              <AvFeedback>User Name shouldn't be empty.</AvFeedback>
            </AvGroup>
          </Col>
          <Col md="6">
            <AvGroup>
              <Label for="contact_no">Contact Number</Label>
              <AvInput
                value={profileData?.contact_no || ''}
                name="contact_no"
                id="contact_no"
              />
              <AvFeedback>Contact Number shouldn't be empty.</AvFeedback>
            </AvGroup>
          </Col>
        </Row>
        <Button
          className="w-100"
          inverted
          color="green"
          size="large"
          disabled={loading}
          loading={loading}
        >
          Update
        </Button>
      </AvForm>
    </div>
  );
};

export default Account;
