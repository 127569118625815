import React, { useEffect } from 'react';
import { Header, Tab, Embed } from 'semantic-ui-react';
import { Row, Col } from 'reactstrap';
import './contentPages.scss';
import { Helmet } from 'react-helmet';

const AboutUs = () => {
  useEffect(() => {
    document.title = 'How It Works | Onemorecar.ca';
  });

  const panes = [
    {
      menuItem: 'Selling',
      render: () => (
        <Tab.Pane attached={false}>
          <div className="how-it-works-content-center">
            <img
              alt="How it Works - Selling"
              className="how-it-works-img"
              src="/selling.png"
            />
          </div>
          <p className="justify-text">
            It is free to list to list your car with onemorecar! Please complete
            the list your car form if you are interested in selling your car
            with us. Make sure to include a short description of the car, some
            photos, and the VIN. We are interested in cars that are unique and
            have a special interest or significance in hearts of auto
            enthusiasts. If your car meets the criteria to be listed on
            onemorecar, we’ll send you an email asking for additional photos,
            service history, flaws, special provenance, etc.
          </p>
          <p className="justify-text">
            Once we receive the information from you, we will compile the data
            and write a professional, detailed description of your vehicle at no
            charge. You will receive a draft of the listing to ensure all the
            information is correct before the auction is live.
          </p>
          <div className="how-it-works-content-center">
            <img
              alt="How it Works - Selling"
              className="how-it-works-img"
              src="/selling-2.png"
            />
          </div>
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Buying',
      render: () => (
        <Tab.Pane attached={false}>
          <div className="how-it-works-content-center">
            <img
              alt="How it Works - Buying"
              className="how-it-works-img"
              src="/buying.png"
            />
          </div>
          <p className="justify-text">
            There are a few steps you need to take prior to bidding on auctions
            on Onemorecar!
          </p>
          <ul className="list-view">
            <li>
              Create an account and add your credit card information to your
              account. Winning bidders pay a buyer’s premium of 4% up to a
              maximum of $3000.
            </li>
            <li>
              While we do our best to make sure the car you are bidding on is as
              described, it is still your responsibility to ensure the vehicles
              you are bidding on are up to the standards you expect.
            </li>
            <li>
              If there are any questions, you may contact the seller through
              email or ask the seller via the comment section.
            </li>
            <li>
              <u>Last-Minute Bid protection</u> - We have incorporated a feature
              which adds two extra minutes to the auction timer when a bid is
              placed. This feature prevents the use of automated bidding
              software and gives other potential bidders a chance to respond to
              your bid.
            </li>
            <li>
              We recommend a PPI or an in-person inspection prior to bidding on
              any vehicle!
            </li>
          </ul>
          <p className="justify-text">
            When you bid, we place a hold on your credit card for 4% of the
            current bid. If you win, your card will be charged, and you may
            discuss payment with the seller. If you were outbid, we will release
            the hold. We will also release the hold if you are outbid or if the
            auction ends without the car meeting the reserve price.
          </p>
          <p className="justify-text">
            Please note that bids are binding. When you bid on a vehicle, it’s a
            commitment to follow through with the purchase. Please do your
            research prior to bidding. We do not accommodate inspections after
            the auction is over – please arrange viewings and inspections while
            the car is live!
          </p>
          <p className="justify-text">
            If you do decide to not complete the purchase after winning, you
            will be permanently banned from onemorecar and we reserve the right
            to forfeit your buyers fee. <u>You will not be refunded.</u>
          </p>

          <div className="how-it-works-content-center">
            <img
              alt="How it Works - Bidding"
              className="how-it-works-img"
              src="/how-it-works-bidding.png"
            />
          </div>
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'FAQ',
      render: () => (
        <Tab.Pane attached={false}>
          <p className="justify-text">
            <strong>What are your fees?</strong>
          </p>
          <p className="justify-text">
            As a seller, it is completely free to list your car.
          </p>
          <p className="justify-text">
            Buyers pay a 4% premium on their winning bid price up to a maximum
            of $3000.
          </p>
          <p className="how-it-works-empty">&nbsp;</p>

          <p className="justify-text">
            <strong>When is my credit card charged?</strong>
          </p>
          <p className="justify-text">
            When you bid, we place a hold on your credit card for 4% of the
            current bid. If you win, your card will be charged, and you may
            discuss payment with the seller. If you were outbid, we will release
            the hold. We will also release the hold if you are outbid or if the
            auction ends without the car meeting the reserve price.
          </p>
          <p className="how-it-works-empty">&nbsp;</p>

          <p className="justify-text">
            <strong>How do you keep my information secure?</strong>
          </p>
          <p className="justify-text">
            Onemorecar.ca utilizes an advanced{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.kaspersky.com/resource-center/definitions/what-is-a-ssl-certificate"
            >
              SSL certificate
            </a>{' '}
            to keep your data private. We process all payments and holds through{' '}
            <a target="_blank" rel="noreferrer" href="https://stripe.com/en-ca">
              Stripe
            </a>{' '}
            , which is used by millions of businesses to process payments.
          </p>
          <p className="how-it-works-empty">&nbsp;</p>

          <p className="justify-text">
            <strong>How do Reserves Work?</strong>
          </p>
          <p className="justify-text">
            <u>No Reserve</u>
          </p>
          <p className="justify-text">
            No reserve auctions have no minimum sale price but often fetch the
            highest values by driving attention to the auction. The vehicle will
            sell for the highest amount posted by a bidder.
          </p>
          <p className="justify-text">
            <u>Reserve Auctions</u>
          </p>
          <p className="justify-text">
            Reserve auctions have a minimum price the seller is willing to
            accept for the vehicle. If bidding doesn’t reach the reserve price,
            the car does not sell. In this case, we will connect the highest
            bidder with the seller to see if they can strike a deal.
          </p>
          <p className="how-it-works-empty">&nbsp;</p>

          <p className="justify-text">
            <strong>What currency is in use on Onemorecar.ca?</strong>
          </p>
          <p className="justify-text">
            All bid amounts are shown in Canadian Dollars.
          </p>
          <p className="how-it-works-empty">&nbsp;</p>

          <p className="justify-text">
            <strong>What happens once I win an auction?</strong>
          </p>
          <p className="justify-text">
            If you are the highest bidder at the end of the auction, you will
            receive an email containing information of the seller so you can
            complete the sale of the vehicle in person. Onemorecar do not
            transfer legal ownership of items from the seller to the buyer.
          </p>
          <p className="how-it-works-empty">&nbsp;</p>

          <p className="justify-text">
            <strong>
              What happens if the reserve is not met on an auction?
            </strong>
          </p>
          <p className="justify-text">
            If the reserve is not met, we will connect the seller and the
            highest bidder to see if a deal can be made.
          </p>
          <p className="how-it-works-empty">&nbsp;</p>

          <p className="justify-text">
            <strong>How do I pay for the vehicle?</strong>
          </p>
          <p className="justify-text">
            Once connected with the seller, it’s up to both parties to determine
            a suitable method of payment.
          </p>
          <p className="how-it-works-empty">&nbsp;</p>

          <p className="justify-text">
            <strong>What is the minimum bid increment?</strong>
          </p>
          <p className="justify-text">
            The minimum increment is $250. There are no maximum bid increment.
          </p>
          <p className="how-it-works-empty">&nbsp;</p>

          <p className="justify-text">
            <strong>How long do auctions run?</strong>
          </p>
          <p className="justify-text">
            We offer a choice between a 7 or 10 day auction period.
          </p>
          <p className="how-it-works-empty">&nbsp;</p>
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Make Offer',
      render: () => (
        <Tab.Pane attached={false}>
          <div className="how-it-works-content-center">
            <img
              alt="Making an Offer"
              className="how-it-works-img"
              src="/making-an-offer.png"
            />
          </div>
          <Row className="mb-3">
            <Col className="pt-3" sm="12">
              <Embed
                className="video-container"
                id="swZQ5CuPyNg"
                placeholder="https://img.youtube.com/vi/swZQ5CuPyNg/hqdefault.jpg"
                source="youtube"
              />
            </Col>
          </Row>
          <div className="how-it-works-content-center">
            <img
              alt="Got an Offer"
              className="how-it-works-img"
              src="/rec-offer.png"
            />
          </div>
        </Tab.Pane>
      ),
    },
  ];

  return (
    <div className="content-pages">
      <Helmet>
        <title>{'How It Works - Onemorecar'}</title>
        <meta name="title" content={'How It Works - onemorecar'} />
        <meta
          name="description"
          content="Onemorecar.ca was founded to be a platform for Canadian car enthusiasts to come together to buy, sell and discuss classic, speciality and modern vehicles"
        />
        <meta
          name="image"
          property="image"
          content="https://onemorecar.ca/seo-black.png"
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@onemorecar" />
        <meta name="twitter:creator" content="@onemorecar" />
        <meta name="twitter:title" content={'How It Works - Onemorecar'} />
        <meta
          name="twitter:description"
          content="Onemorecar.ca was founded to be a platform for Canadian car enthusiasts to come together to buy, sell and discuss classic, speciality and modern vehicles"
        />
        <meta
          name="twitter:image"
          content="https://onemorecar.ca/seo-black.png"
        />

        <meta
          property="og:image"
          content="https://onemorecar.ca/seo-black.png"
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={'How It Works - Onemorecar'} />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:description"
          content="Onemorecar.ca was founded to be a platform for Canadian car enthusiasts to come together to buy, sell and discuss classic, speciality and modern vehicles"
        />
      </Helmet>

      <Header className="mt-3" as="h2">
        How It Works
      </Header>

      <Tab menu={{ pointing: true }} panes={panes} />
      <p>&nbsp;</p>
    </div>
  );
};

export default AboutUs;
