import { NotificationManager } from 'react-notifications';
import { AuthAxios } from '../../../index';
import history from '../../../lib/history';

import { GET_USER_LOADING } from './actions.js';

export const getUserDetail = id => {
  return async dispatch => {
    try {
      dispatch({ type: GET_USER_LOADING, payload: true });
      const res = await AuthAxios({
        method: 'GET',
        url: '/user/profile/' + id,
      });
      dispatch({ type: GET_USER_LOADING, payload: false });
      return res?.data?.data;
    } catch (error) {
      dispatch({ type: GET_USER_LOADING, payload: false });
      const message = error?.response?.data?.errorMessage || '';
      NotificationManager.error(message || 'Oops, Something went wrong!');
    }
  };
};

export const fogotPassword = (req, closeModal) => {
  return async dispatch => {
    try {
      dispatch({ type: GET_USER_LOADING, payload: true });
      const res = await AuthAxios({
        method: 'POST',
        url: '/forgot-password',
        data: req,
      });
      dispatch({ type: GET_USER_LOADING, payload: false });
      const data = res?.data?.data;
      NotificationManager.success(data);
      closeModal();
      return data;
    } catch (error) {
      dispatch({ type: GET_USER_LOADING, payload: false });
      const message = error?.response?.data?.errorMessage || '';
      NotificationManager.error(message || 'Oops, Something went wrong!');
    }
  };
};

export const verifyResetPasswordToken = req => {
  return async dispatch => {
    try {
      dispatch({ type: GET_USER_LOADING, payload: true });
      const res = await AuthAxios({
        method: 'POST',
        url: '/reset-password-verify',
        data: req,
      });
      dispatch({ type: GET_USER_LOADING, payload: false });
      const userId = res?.data?._id;
      return userId;
    } catch (error) {
      dispatch({ type: GET_USER_LOADING, payload: false });
      const message = error?.response?.data?.errorMessage || '';
      history.push('/');
      NotificationManager.error(message || 'Oops, Something went wrong!');
    }
  };
};

export const resetPasswordWithToken = req => {
  return async dispatch => {
    try {
      dispatch({ type: GET_USER_LOADING, payload: true });
      const res = await AuthAxios({
        method: 'POST',
        url: '/reset-password',
        data: req,
      });
      dispatch({ type: GET_USER_LOADING, payload: false });
      history.push('/');
      NotificationManager.success('Password reset successfully.');
    } catch (error) {
      dispatch({ type: GET_USER_LOADING, payload: false });
      const message = error?.response?.data?.errorMessage || '';
      NotificationManager.error(message || 'Oops, Something went wrong!');
    }
  };
};

export const getUserWishListAuctions = req => {
  return async dispatch => {
    try {
      dispatch({ type: GET_USER_LOADING, payload: true });
      const res = await AuthAxios({
        method: 'GET',
        url: '/user/wish-list',
        params: req,
      });
      const data = res?.data?.data;
      dispatch({ type: GET_USER_LOADING, payload: false });
      return data;
    } catch (error) {
      dispatch({ type: GET_USER_LOADING, payload: false });
      const message = error?.response?.data?.errorMessage || '';
      NotificationManager.error(message || 'Oops, Something went wrong!');
    }
  };
};

export const getUserBidHistory = id => {
  return async dispatch => {
    try {
      dispatch({ type: GET_USER_LOADING, payload: true });
      const res = await AuthAxios({
        method: 'GET',
        url: '/user/bid-history/' + id,
      });
      const data = res?.data?.data;
      dispatch({ type: GET_USER_LOADING, payload: false });
      return data;
    } catch (error) {
      dispatch({ type: GET_USER_LOADING, payload: false });
      const message = error?.response?.data?.errorMessage || '';
      NotificationManager.error(message || 'Oops, Something went wrong!');
    }
  };
};

export const getUserOffers = req => {
  return async dispatch => {
    try {
      dispatch({ type: GET_USER_LOADING, payload: true });
      const res = await AuthAxios({
        method: 'GET',
        url: '/user/offers',
        params: req,
      });
      const data = res?.data?.data;
      dispatch({ type: GET_USER_LOADING, payload: false });
      return data;
    } catch (error) {
      dispatch({ type: GET_USER_LOADING, payload: false });
      const message = error?.response?.data?.errorMessage || '';
      NotificationManager.error(message || 'Oops, Something went wrong!');
    }
  };
};

export const acceptOffer = req => {
  return async dispatch => {
    try {
      dispatch({ type: GET_USER_LOADING, payload: true });
      const res = await AuthAxios({
        method: 'POST',
        url: '/user/offer/accept',
        data: req,
      });
      const data = res?.data?.data;
      dispatch({ type: GET_USER_LOADING, payload: false });
      return data;
    } catch (error) {
      dispatch({ type: GET_USER_LOADING, payload: false });
      const message = error?.response?.data?.errorMessage || '';
      NotificationManager.error(message || 'Oops, Something went wrong!');
    }
  };
};

export const getUserSendOffers = req => {
  return async dispatch => {
    try {
      dispatch({ type: GET_USER_LOADING, payload: true });
      const res = await AuthAxios({
        method: 'GET',
        url: '/user/send-offers',
        params: req,
      });
      const data = res?.data?.data;
      dispatch({ type: GET_USER_LOADING, payload: false });
      return data;
    } catch (error) {
      dispatch({ type: GET_USER_LOADING, payload: false });
      const message = error?.response?.data?.errorMessage || '';
      NotificationManager.error(message || 'Oops, Something went wrong!');
    }
  };
};

export const rejectOffer = req => {
  return async dispatch => {
    try {
      dispatch({ type: GET_USER_LOADING, payload: true });
      const res = await AuthAxios({
        method: 'POST',
        url: '/user/offer/reject',
        data: req,
      });
      const data = res?.data?.data;
      dispatch({ type: GET_USER_LOADING, payload: false });
      return data;
    } catch (error) {
      dispatch({ type: GET_USER_LOADING, payload: false });
      const message = error?.response?.data?.errorMessage || '';
      NotificationManager.error(message || 'Oops, Something went wrong!');
    }
  };
};

export const sendEmail = (req, closeModal) => {
  return async dispatch => {
    try {
      dispatch({ type: GET_USER_LOADING, payload: true });
      const res = await AuthAxios({
        method: 'POST',
        url: '/user/send-email',
        data: req,
      });
      const data = res?.data?.data;
      dispatch({ type: GET_USER_LOADING, payload: false });
      closeModal();
      NotificationManager.success('Email successfully sent.');
      return data;
    } catch (error) {
      dispatch({ type: GET_USER_LOADING, payload: false });
      const message = error?.response?.data?.errorMessage || '';
      NotificationManager.error(message || 'Oops, Something went wrong!');
    }
  };
};
