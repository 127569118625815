import React from 'react';
import { Modal, Header, Divider, Button, Message } from 'semantic-ui-react';
import { Row, Col } from 'reactstrap';
import { BID_BUYER_PERCENTAGE } from '../../constant';

const MakeOfferStepTwo = props => {
  const buyerFee = (props.bidAmount * BID_BUYER_PERCENTAGE) / 100;
  return (
    <div>
      <Modal.Header id="bid-modal-header">
        <div className="position-absolute close-icon">
          <button
            onClick={() => {
              props.closeModal();
              props.setStep(1);
            }}
            type="button"
            className="close"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </Modal.Header>
      <Modal.Content id="bid-modal-content">
        <Header className="my-2 text-center" as="h2">
          {props.auction.title}
        </Header>
        <Divider horizontal>
          <Header disabled className="text-center" as="h3">
            {props.duration} &nbsp;&nbsp;&nbsp; Minimum offer amount is CAD $
            {props.auction.min_offer_amount || 0}
          </Header>
        </Divider>
        <Row>
          <Col xs="8">
            <p>Your Offer Amount</p>
          </Col>
          <Col xs="4">
            <p className="float-right">${props.bidAmount} CAD</p>
          </Col>
          <Col xs="8">
            <p as="h5">Onemorecar Buyer's Fee</p>
          </Col>
          <Col xs="4">
            <p className="float-right">${buyerFee} CAD</p>
          </Col>
        </Row>

        <Divider />

        <p>
          When you offer on a vehicle, we will place a {BID_BUYER_PERCENTAGE}%
          hold on your credit card for the onemorecar buyers fee.
        </p>
        {props.postOfferSuccess ? (
          <Message color="green">
            <Message.Header>{props.postOfferSuccess}</Message.Header>
          </Message>
        ) : null}
        {props.errorMessage ? (
          <Message negative>
            <Message.Header>{props.errorMessage}</Message.Header>
          </Message>
        ) : null}
        <Button
          onClick={() => {
            props.postOffer(
              {
                auction_id: props.auction._id,
                amount: props.bidAmount,
              },
              () => {
                props.closeModal();
                props.setStep(1);
              }
            );
          }}
          size="large"
          className="w-100"
          inverted
          color="green"
          disabled={props.postOfferLoading || props.postOfferSuccess}
          loading={props.postOfferLoading}
        >
          Offer CAD ${props.bidAmount}
        </Button>
        <Button
          size="large"
          className="mt-3 w-100"
          inverted
          color="red"
          onClick={() => {
            props.closeModal();
            props.setStep(1);
          }}
          disabled={props.postBidLoading || props.postBidSuccess}
          loading={props.postBidLoading}
        >
          Cancel
        </Button>
      </Modal.Content>
    </div>
  );
};

export default MakeOfferStepTwo;
