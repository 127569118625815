/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Tab, Segment } from 'semantic-ui-react';
import EndingSoon from './EndingSoon';
import MakeOffer from './MakeOffer';
import PastAuctions from './PastAuctions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col } from 'reactstrap';
import {
  getAuctions,
  getUserWishList,
  postWishList,
  deleteUserWishList,
} from '../../store/actions';

const HomeContainer = props => {
  const [tabIndex, setTabIndex] = useState(0);
  const [hide, setHide] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      await props.getUserWishList();
    };
    if (props.user.role) {
      fetchData();
    }

    const req = {
      filter_type: 'ending_soon',
      limit: 8,
      offset: 0,
    };
    const countData = async () => {
      const resData = await props.getAuctions(req);
      const auctions = resData?.auctions || [];
      if (auctions.length !== 0) {
        setTabIndex(0);
      } else {
        setTabIndex(2);
      }
      setHide(false);
    };
    countData();
  }, [props.user]);

  if (hide) {
    return <div />;
  }
  return (
    <div>
      <Row className="my-4">
        <Col md="12">
          <Segment raised secondary stacked>
            <p className="header-banner center-text">
              New to onemorecar? <a href="/how-it-works">Learn how it works.</a>
            </p>
          </Segment>
        </Col>
      </Row>
      <Tab
        className="tab-main-container home-tab-container"
        defaultActiveIndex={tabIndex}
        panes={[
          {
            menuItem: 'Now Live / Sold',
            render: () => (
              <Tab.Pane className="tab-container" attached={false}>
                <EndingSoon
                  loading={props.auctions.getAuctionsLoading}
                  getAuctions={props.getAuctions}
                  postWishList={props.postWishList}
                  deleteUserWishList={props.deleteUserWishList}
                  user={props.user}
                  wishList={
                    props.auctions?.wishList?.wish_list_auction_ids || []
                  }
                />
              </Tab.Pane>
            ),
          },
          {
            menuItem: 'Make Offer',
            render: () => {
              return (
                <Tab.Pane className="tab-container" attached={false}>
                  <MakeOffer
                    loading={props.auctions.getAuctionsLoading}
                    getAuctions={props.getAuctions}
                    postWishList={props.postWishList}
                    deleteUserWishList={props.deleteUserWishList}
                    user={props.user}
                    wishList={
                      props.auctions?.wishList?.wish_list_auction_ids || []
                    }
                  />
                </Tab.Pane>
              );
            },
          },
          {
            menuItem: 'Past Auctions',
            render: () => (
              <Tab.Pane className="tab-container" attached={false}>
                {/* <NoReserve
                  loading={props.auctions.getAuctionsLoading}
                  getAuctions={props.getAuctions}
                  postWishList={props.postWishList}
                  deleteUserWishList={props.deleteUserWishList}
                  user={props.user}
                  wishList={
                    props.auctions?.wishList?.wish_list_auction_ids || []
                  }
                /> */}
                {/* <EndingSoon
                  loading={props.auctions.getAuctionsLoading}
                  getAuctions={props.getAuctions}
                  postWishList={props.postWishList}
                  deleteUserWishList={props.deleteUserWishList}
                  user={props.user}
                  wishList={
                    props.auctions?.wishList?.wish_list_auction_ids || []
                  }
                /> */}
                <PastAuctions
                  loading={props.auctions.getPassAuctionsLoading}
                  getAuctions={props.getAuctions}
                  postWishList={props.postWishList}
                  deleteUserWishList={props.deleteUserWishList}
                  auctionType="past"
                  user={props.user}
                  wishList={
                    props.auctions?.wishList?.wish_list_auction_ids || []
                  }
                />
              </Tab.Pane>
            ),
          },
        ]}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  auctions: state.auctions,
  user: state.auth.userData,
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getAuctions,
      getUserWishList,
      postWishList,
      deleteUserWishList,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeContainer);
