import React, { useEffect, Suspense, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Container } from 'reactstrap';

import {
  AppAside,
  AppBreadcrumb,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav,
} from '@coreui/react';
import navigation from './navigation';
import routes from './routes';
import { NotificationContainer } from 'react-notifications';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { cloneDeep } from 'lodash';

const DefaultAside = React.lazy(() => import('./DefaultAside'));
const DefaultHeader = React.lazy(() => import('./DefaultHeader'));

const DefaultLayout = props => {
  useEffect(() => {
    const role = props?.userData?.role || null;
    if (role !== 'admin') {
      props.history.push('/');
      window.location.reload();
    }
  });
  const loading = () => (
    <div className="animated fadeIn pt-1 text-center justify-content-center">
      <p>Loading</p>
    </div>
  );
  const { history, location, match, staticContext } = props;
  const navigationList = cloneDeep(navigation);
  const [isOpenAside, onAsideClick] = useState(false);
  return (
    <div className="app main-font-color">
      <AppHeader className="default-header p-0 pr-1" fixed>
        <Suspense fallback={loading()}>
          <DefaultHeader
            {...props}
            onAsideClick={() => {
              onAsideClick(!isOpenAside);
            }}
          />
        </Suspense>
      </AppHeader>
      <div className="app-body default-header-body">
        <AppSidebar fixed display="lg">
          <AppSidebarHeader />
          <AppSidebarForm />
          <Suspense>
            <AppSidebarNav
              className="font-weight-bold font-lg"
              navConfig={navigationList}
              {...{ history, location, match, staticContext }}
            />
          </Suspense>
          <AppSidebarFooter />
          <AppSidebarMinimizer />
        </AppSidebar>
        <main className="main">
          <AppBreadcrumb appRoutes={routes} />
          <Container className="p-0" fluid>
            <Suspense fallback={loading()}>
              <Switch>
                {routes.map((route, idx) => {
                  return route.component ? (
                    <Route
                      key={idx}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      render={componentProps => (
                        <route.component {...componentProps} />
                      )}
                    />
                  ) : null;
                })}
                <Redirect from="/" to="/404" />
              </Switch>
            </Suspense>
          </Container>
          <NotificationContainer />
        </main>
        <AppAside className={isOpenAside ? 'home-aside' : ''} fixed>
          <Suspense fallback={loading()}>
            <DefaultAside {...props} />
          </Suspense>
        </AppAside>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    ...state.auth,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(DefaultLayout);
