import { combineReducers } from 'redux';
import auth from './modules/auth/';
import auctions from './modules/auctions/';
import user from './modules/user/';
import cars from './modules/cars/';

const reducers = combineReducers({
  auth,
  auctions,
  user,
  cars,
});

export default reducers;
