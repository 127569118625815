import React from 'react';
import { Modal, Button } from 'semantic-ui-react';
import { SignInForm } from './SignInForm';
import { SignUpForm } from './SignUpForm';
import { images } from '../theme/images';
import { isMobile } from 'react-device-detect';
import {
  AvForm,
  AvGroup,
  AvInput,
  AvFeedback,
} from 'availity-reactstrap-validation';
import { Label } from 'reactstrap';
import './AuthModal.scss';

export const AuthModal = props => {
  const [open, setOpen] = React.useState(false);
  const [accountExists, changeAccountState] = React.useState(true);
  const [isFogotPassword, setIsFogotPassword] = React.useState(false);
  const inlineStyle = {
    modal: {
      height: 'auto',
      top: 'auto',
      left: 'auto',
      bottom: 'auto',
      right: 'auto',
    },
  };
  const closeModal = () => {
    setOpen(false);
    changeAccountState(false);
    setIsFogotPassword(false);
  };

  return (
    <Modal
      size="tiny"
      onClose={() => {
        setOpen(false);
        changeAccountState(true);
      }}
      onOpen={() => {
        setOpen(true);
        changeAccountState(true);
      }}
      open={open}
      trigger={
        !props.email ? (
          props.link ? (
            <strong className="contact-text bold">{props.buttonText}</strong>
          ) : (
            <Button
              className={props.buttonText && 'm-0 w-100 bid-button'}
              basic={props.buttonText ? false : true}
              color="red"
              size="large"
            >
              {props.buttonText ? props.buttonText : 'Sign In'}
            </Button>
          )
        ) : null
      }
      style={inlineStyle.modal}
      closeOnDimmerClick={false}
      className="sign-up-modal"
    >
      <Modal.Header className="sign-up-modal-header">
        <button
          onClick={() => {
            closeModal();
          }}
          type="button"
          className="close"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <div className="d-flex justify-content-center">
          {
            // eslint-disable-next-line
            <img
              alt="auth-image"
              className="auth-image"
              width={isMobile ? '135px' : '275px'}
              height={isMobile ? '25px' : '50px'}
              src={images.admin_logo}
            />
          }
        </div>
        {accountExists ? 'Sign in' : 'Sign up'}
        {accountExists ? (
          <p className="header-description">
            Need to create an account?{' '}
            <span
              onClick={() => {
                changeAccountState(!accountExists);
                setIsFogotPassword(false);
              }}
              className="link"
            >
              &nbsp;Sign up here
            </span>
          </p>
        ) : (
          <p className="header-description">
            Already have an account?{' '}
            <span
              onClick={() => {
                changeAccountState(!accountExists);
                setIsFogotPassword(false);
              }}
              className="link"
            >
              &nbsp;Sign in here
            </span>
          </p>
        )}
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          {isFogotPassword ? (
            <AvForm
              onValidSubmit={(event, values) =>
                props.fogotPassword(values, closeModal)
              }
            >
              <AvGroup>
                <Label for="email">Email address</Label>
                <AvInput name="email" id="email" required />
                <AvFeedback>Email address shouldn't be empty.</AvFeedback>
              </AvGroup>
              <Button className="w-100" inverted color="red" size="large">
                Submit
              </Button>
            </AvForm>
          ) : accountExists ? (
            <SignInForm {...props} closeModal={closeModal} />
          ) : (
            <SignUpForm {...props} closeModal={closeModal} />
          )}
          <div className="sign-up-modal-header">
            <p className="header-description">
              <span
                onClick={() => {
                  setIsFogotPassword(true);
                }}
                className="link"
              >
                Forgot your password?
              </span>
            </p>
          </div>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};
