import React, { useEffect } from 'react';
import { Header } from 'semantic-ui-react';
import './contentPages.scss';
import { Helmet } from 'react-helmet';

const PrivacyPolicy = () => {
  useEffect(() => {
    document.title = 'Privacy Policy | Onemorecar.ca';
  });

  return (
    <div className="content-pages">

      <Helmet>
        <title>{'Privacy Policy - Onemorecar'}</title>
        <meta name="title" content={'Privacy Policy - onemorecar'}/>
        <meta name="description" content="Onemorecar.ca was founded to be a platform for Canadian car enthusiasts to come together to buy, sell and discuss classic, speciality and modern vehicles" />
        <meta name="image" property="image" content="https://onemorecar.ca/seo-black.png"/>

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@onemorecar" />
        <meta name="twitter:creator" content="@onemorecar" />
        <meta name="twitter:title" content={'Privacy Policy - Onemorecar'} />
        <meta name="twitter:description" content="Onemorecar.ca was founded to be a platform for Canadian car enthusiasts to come together to buy, sell and discuss classic, speciality and modern vehicles" />
        <meta name="twitter:image" content="https://onemorecar.ca/seo-black.png" />
        
        <meta property="og:image" content="https://onemorecar.ca/seo-black.png" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={'Privacy Policy - Onemorecar'} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:description" content="Onemorecar.ca was founded to be a platform for Canadian car enthusiasts to come together to buy, sell and discuss classic, speciality and modern vehicles" />
      </Helmet>

      <Header className="mt-3" as="h2">
        Privacy Policy
      </Header>
      <p className="justify-text">
        This Privacy Policy describes how your personal information is
        collected, used, and shared when you visit or bid on a vehicle on
        www.onemorecar.ca (the &ldquo;site&rdquo;).
      </p>
      <p className="justify-text">
        <strong>Personal information we collect</strong>
      </p>
      <p className="justify-text">
        When you visit the site, we automatically collect certain information
        about your device, including information about your web browser, IP
        address, time zone, and some of the cookies that are installed on your
        device. Additionally, as you browse the site, we collect information
        about the individual web pages or products that you view, what websites
        or search terms referred you to the site, and information about how you
        interact with the site. We refer to this automatically-collected
        information as &ldquo;Device Information.&rdquo;
      </p>
      <p className="justify-text">
        We collect Device Information using the following technologies:
      </p>
      <p className="justify-text">
        &nbsp;&nbsp;&nbsp; - &ldquo;Cookies&rdquo; are data files that are
        placed on your device or computer and often include an anonymous unique
        identifier. For more information about cookies, and how to disable
        cookies, visit{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="http://www.allaboutcookies.org"
        >
          http://www.allaboutcookies.org
        </a>
        .
      </p>
      <p className="justify-text">
        &nbsp;&nbsp;&nbsp; - &ldquo;Log files&rdquo; track actions occurring on
        the Site, and collect data including your IP address, browser type,
        Internet service provider, referring/exit pages, and date/time stamps.
      </p>
      <p className="justify-text">
        &nbsp;&nbsp;&nbsp; - &ldquo;Web beacons,&rdquo; &ldquo;tags,&rdquo; and
        &ldquo;pixels&rdquo; are electronic files used to record information
        about how you browse the Site.
      </p>
      <p className="justify-text">
        Additionally when you submit a listing, attempt to place a bid or
        purchase a vehicle through the site, we collect certain information from
        you, including your name, billing address, shipping address, payment
        information (including credit card), email address, and phone
        number.&nbsp; We refer to this information as &ldquo;Order
        Information.&rdquo;
      </p>
      <p className="justify-text">
        When we talk about &ldquo;Personal Information&rdquo; in this Privacy
        Policy, we are talking both about device information and bid
        information.
      </p>
      <p className="justify-text">
        <strong>How do we use your personal information?</strong>
      </p>
      <p className="justify-text">
        We use the Information that we collect generally to fulfill bids placed
        through the site (including processing your payment information, and
        providing you with bid confirmation, confirmation of bids, etc.).&nbsp;
        Additionally, we use this order Information to:
      </p>
      <ul>
        <li>Communicate with you</li>
        <li>Communicate between sellers, bidders and winning bidders</li>
        <li>To respond to user inquiries and fulfill user requests.</li>
        <li>To process transactions</li>
      </ul>
      <ul>
        <li>To protect our rights and the rights of others.</li>
      </ul>
      <ul>
        <li>Screen users and bids for potential risk or fraud; and</li>
      </ul>
      <p className="justify-text">
        When in line with the preferences you have shared with us, provide you
        with information or advertising relating to our products or services.
      </p>
      <p className="justify-text">
        We use the device information that we collect to help us screen for
        potential risk and fraud (in particular, your IP address), and more
        generally to improve and optimize our site (for example, by generating
        analytics about how our customers browse and interact with the Site, and
        to assess the success of our marketing and advertising campaigns).
      </p>
      <p className="justify-text">
        <strong>Sharing your personal information</strong>
      </p>
      <p className="justify-text">
        We share your Personal Information with third parties to help us use
        your Personal Information, as described above. &nbsp;
      </p>
      <p className="justify-text">
        Finally, we may also share your Personal Information to comply with
        applicable laws and regulations, to respond to a subpoena, search
        warrant or other lawful request for information we receive, or to
        otherwise protect our rights.
      </p>
      <p>
        <strong>Do not track</strong>
      </p>
      <p className="justify-text">
        Please note that we do not alter our Site&rsquo;s data collection and
        use practices when we see a Do Not Track signal from your browser.
      </p>
      <p>
        <strong>Data retention</strong>
      </p>
      <p className="justify-text">
        When you place a bid or submit a car through the Site, we will maintain
        your information for our records unless and until you ask us to delete
        this information.
      </p>
      <p>
        <strong>Changes</strong>
      </p>
      <p className="justify-text">
        We may update this privacy policy from time to time in order to reflect,
        for example, changes to our practices or for other operational, legal or
        regulatory reasons.
      </p>
      <p>
        <strong>Contact us</strong>
      </p>
      <p className="justify-text">
        For more information about our privacy practices, if you have questions,
        or if you would like to make a complaint, please contact us by e-mail at
        info@onemorecar.ca or by mail using the details provided below.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
