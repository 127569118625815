import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  verifyResetPasswordToken,
  resetPasswordWithToken,
} from '../../store/actions';
import BeatLoader from 'react-spinners/BeatLoader';
import {
  AvForm,
  AvGroup,
  AvInput,
  AvFeedback,
} from 'availity-reactstrap-validation';
import { Label } from 'reactstrap';
import { Button, Icon } from 'semantic-ui-react';

const ForgotPassword = props => {
  const [loading, setLoading] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordShown2, setPasswordShown2] = useState(false);
  const [userId, setUserId] = useState(false);
  const [resetToken, setResetToken] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const togglePasswordVisibility2 = () => {
    setPasswordShown2(passwordShown2 ? false : true);
  };

  useEffect(() => {
    const pathname = window.location.pathname;
    const passwordResetToken = pathname.split('/')[2];
    const feachData = async () => {
      setLoading(true);
      const userId = await props.verifyResetPasswordToken({
        passwordResetToken,
      });
      setUserId(userId);
      setResetToken(passwordResetToken);
      setLoading(false);
    };
    feachData();
    // eslint-disable-next-line
  }, [window.location.pathname]);

  if (loading) {
    return (
      <div className="d-flex justify-content-center">
        <BeatLoader size={6} loading color="#db2828" />
      </div>
    );
  }

  return (
    <AvForm
      className="mt-5"
      onValidSubmit={async (event, values) => {
        await props.resetPasswordWithToken({
          userId,
          passwordResetToken: resetToken,
          ...values,
        });
      }}
    >
      <AvGroup>
        <Label for="password">New Password</Label>
        <div className="pass-wrapper">
          <AvInput
            name="password"
            id="password"
            type={passwordShown ? 'text' : 'password'}
            required
          />
          <Icon onClick={togglePasswordVisibility} name="eye" />
          <AvFeedback>Please enter your password.</AvFeedback>
        </div>
      </AvGroup>
      <AvGroup>
        <Label for="confirm_password">Confirm password</Label>
        <div className="pass-wrapper">
          <AvInput
            name="confirm_password"
            id="confirm_password"
            type={passwordShown2 ? 'text' : 'password'}
            required
          />
          <Icon onClick={togglePasswordVisibility2} name="eye" />
          <AvFeedback>Please repeat your password.</AvFeedback>
        </div>
      </AvGroup>
      <Button className="w-100" inverted color="red" size="large">
        Reset Password
      </Button>
    </AvForm>
  );
};

const mapStateToProps = state => ({
  user: state.auth.userData,
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      verifyResetPasswordToken,
      resetPasswordWithToken,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
