import { NotificationManager } from 'react-notifications';
import { AuthAxios } from '../../../index';
import axios from 'axios';

import {
  AUCTION_LOADING,
  SET_AUCTION_LIST,
  SET_AUCTION,
  SET_AUCTIONS,
  GET_AUCTIONS_LOADING,
  GET_PASS_AUCTIONS_LOADING,
  SET_AUCTION_DETAIL,
  GET_AUCTION_DETAIL_LOADING,
  GET_AUCTION_DETAIL_BY_SLUG_LOADING,
  SET_WISH_LIST,
  WISH_LIST_LOADIND,
  SET_COMMENTS,
  COMMENTS_LOADIND,
  COMMENTS_BY_SLUG_LOADIND,
  POST_BID_LOADIND,
  SET_POST_BID_ERROR,
  SET_POST_BID_SUCCESS,
  POST_OFFER_LOADIND,
  SET_POST_OFFER_ERROR,
  SET_POST_OFFER_SUCCESS,
} from './actions.js';

export const getAdminAuctions = req => {
  return async dispatch => {
    try {
      dispatch({ type: AUCTION_LOADING, payload: true });
      const response = await AuthAxios({
        method: 'get',
        url: '/admin/auctions',
        params: req,
      });
      const data = response?.data?.data || {};
      dispatch({ type: SET_AUCTION_LIST, payload: data });
      dispatch({ type: AUCTION_LOADING, payload: false });
      return data;
    } catch (error) {
      dispatch({ type: AUCTION_LOADING, payload: false });
      const message = error?.response?.data?.errorMessage || '';
      NotificationManager.error(message || 'Oops, Something went wrong!');
    }
  };
};

export const getAdminAuction = id => {
  return async dispatch => {
    try {
      dispatch({ type: AUCTION_LOADING, payload: true });
      const response = await AuthAxios({
        method: 'get',
        url: `/admin/auctions/${id}`,
      });
      const data = response?.data?.data || {};
      dispatch({ type: SET_AUCTION, payload: data });
      dispatch({ type: AUCTION_LOADING, payload: false });
      return data;
    } catch (error) {
      dispatch({ type: AUCTION_LOADING, payload: false });
      const message = error?.response?.data?.errorMessage || '';
      NotificationManager.error(message || 'Oops, Something went wrong!');
    }
  };
};

export const postAdminAuction = req => {
  return async dispatch => {
    try {
      dispatch({ type: AUCTION_LOADING, payload: true });
      await AuthAxios({
        method: 'post',
        url: '/admin/auctions',
        data: req,
      });
      await dispatch(getAdminAuctions({}));
      dispatch({ type: AUCTION_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: AUCTION_LOADING, payload: false });
      const message = error?.response?.data?.errorMessage || '';
      NotificationManager.error(message || 'Oops, Something went wrong!');
    }
  };
};

export const putAdminAuction = (req, id, setDafault) => {
  return async dispatch => {
    try {
      dispatch({ type: AUCTION_LOADING, payload: true });
      await AuthAxios({
        method: 'put',
        url: `/admin/auctions/${id}`,
        data: req,
      });
      await dispatch(getAdminAuctions({}));
      dispatch({ type: AUCTION_LOADING, payload: false });
      setDafault();
    } catch (error) {
      dispatch({ type: AUCTION_LOADING, payload: false });
      const message = error?.response?.data?.errorMessage || '';
      NotificationManager.error(message || 'Oops, Something went wrong!');
    }
  };
};

export const deleteAdminAuction = id => {
  return async dispatch => {
    try {
      dispatch({ type: AUCTION_LOADING, payload: true });
      await AuthAxios({
        method: 'DELETE',
        url: `/admin/auctions/${id}`,
      });
      await dispatch(getAdminAuctions({}));
      dispatch({ type: AUCTION_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: AUCTION_LOADING, payload: false });
      const message = error?.response?.data?.errorMessage || '';
      NotificationManager.error(message || 'Oops, Something went wrong!');
    }
  };
};

export const rejectAdminAuction = id => {
  return async dispatch => {
    try {
      dispatch({ type: AUCTION_LOADING, payload: true });
      await AuthAxios({
        method: 'DELETE',
        url: `/admin/auction-reject/${id}`,
      });
      await dispatch(getAdminAuctions({}));
      dispatch({ type: AUCTION_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: AUCTION_LOADING, payload: false });
      const message = error?.response?.data?.errorMessage || '';
      NotificationManager.error(message || 'Oops, Something went wrong!');
    }
  };
};

export const getAuctions = req => {
  return async dispatch => {
    try {
      if (req.auction_type === 'past') {
        dispatch({ type: GET_PASS_AUCTIONS_LOADING, payload: true });
      } else {
        dispatch({ type: GET_AUCTIONS_LOADING, payload: true });
      }
      const response = await axios({
        method: 'get',
        url: `/auctions`,
        params: req,
      });

      const data = response?.data?.data || {};
      dispatch({ type: SET_AUCTIONS, payload: data });
      if (req.auction_type === 'past') {
        dispatch({ type: GET_PASS_AUCTIONS_LOADING, payload: false });
      } else {
        dispatch({ type: GET_AUCTIONS_LOADING, payload: false });
      }
      return data;
    } catch (error) {
      if (req.auction_type === 'past') {
        dispatch({ type: GET_PASS_AUCTIONS_LOADING, payload: false });
      } else {
        dispatch({ type: GET_AUCTIONS_LOADING, payload: false });
      }
      const message = error?.response?.data?.errorMessage || '';
      NotificationManager.error(message || 'Oops, Something went wrong!');
    }
  };
};

export const getAuction = id => {
  return async dispatch => {
    try {
      dispatch({ type: GET_AUCTION_DETAIL_LOADING, payload: true });
      const response = await axios({
        method: 'get',
        url: `/auctions/${id}`,
      });
      const data = response?.data?.data || {};
      dispatch({ type: SET_AUCTION_DETAIL, payload: data });
      dispatch({ type: GET_AUCTION_DETAIL_LOADING, payload: false });
      return data;
    } catch (error) {
      dispatch({ type: GET_AUCTION_DETAIL_LOADING, payload: false });
      const message = error?.response?.data?.errorMessage || '';
      NotificationManager.error(message || 'Oops, Something went wrong!');
    }
  };
};

export const getAuctionBySlug = slug => {
  return async dispatch => {
    try {
      dispatch({ type: GET_AUCTION_DETAIL_BY_SLUG_LOADING, payload: true });
      const response = await axios({
        method: 'get',
        url: `/auctions-by-slug/${slug}`,
      });
      const data = response?.data?.data || {};
      dispatch({ type: SET_AUCTION_DETAIL, payload: data });
      dispatch({ type: GET_AUCTION_DETAIL_BY_SLUG_LOADING, payload: false });
      return data;
    } catch (error) {
      dispatch({ type: GET_AUCTION_DETAIL_BY_SLUG_LOADING, payload: false });
      const message = error?.response?.data?.errorMessage || '';
      NotificationManager.error(message || 'Oops, Something went wrong!');
    }
  };
};

export const postWishList = id => {
  return async dispatch => {
    try {
      dispatch({ type: WISH_LIST_LOADIND, payload: true });
      const response = await AuthAxios({
        method: 'post',
        url: '/user/wish-list',
        data: {
          auction_id: id,
        },
      });
      const data = response?.data?.data || {};
      await dispatch(getUserWishList());
      NotificationManager.success(data);
      dispatch({ type: WISH_LIST_LOADIND, payload: false });
    } catch (error) {
      dispatch({ type: WISH_LIST_LOADIND, payload: false });
      const message = error?.response?.data?.errorMessage || '';
      NotificationManager.error(message || 'Oops, Something went wrong!');
    }
  };
};

export const getUserWishList = () => {
  return async dispatch => {
    try {
      dispatch({ type: WISH_LIST_LOADIND, payload: true });
      const response = await AuthAxios({
        method: 'get',
        url: '/user/wish-list-auction-ids',
      });
      const data = response?.data?.data || {};
      dispatch({ type: SET_WISH_LIST, payload: data });
      dispatch({ type: WISH_LIST_LOADIND, payload: false });
      return data?.wish_list_auction_ids || [];
    } catch (error) {
      dispatch({ type: WISH_LIST_LOADIND, payload: false });
      const message = error?.response?.data?.errorMessage || '';
      NotificationManager.error(message || 'Oops, Something went wrong!');
    }
  };
};

export const deleteUserWishList = id => {
  return async dispatch => {
    try {
      dispatch({ type: WISH_LIST_LOADIND, payload: true });
      const response = await AuthAxios({
        method: 'delete',
        url: `/user/wish-list/${id}`,
      });
      const data = response?.data?.data || {};
      await dispatch(getUserWishList());
      NotificationManager.success(data);
      dispatch({ type: WISH_LIST_LOADIND, payload: false });
    } catch (error) {
      dispatch({ type: WISH_LIST_LOADIND, payload: false });
      const message = error?.response?.data?.errorMessage || '';
      NotificationManager.error(message || 'Oops, Something went wrong!');
    }
  };
};

export const getComments = id => {
  return async dispatch => {
    try {
      dispatch({ type: COMMENTS_LOADIND, payload: true });
      const response = await axios({
        method: 'get',
        url: `/comments/${id}`,
      });
      const data = response?.data?.data || {};
      dispatch({ type: SET_COMMENTS, payload: data });
      dispatch({ type: COMMENTS_LOADIND, payload: false });
    } catch (error) {
      dispatch({ type: COMMENTS_LOADIND, payload: false });
      const message = error?.response?.data?.errorMessage || '';
      NotificationManager.error(message || 'Oops, Something went wrong!');
    }
  };
};

export const getCommentsBySlug = slug => {
  return async dispatch => {
    try {
      dispatch({ type: COMMENTS_BY_SLUG_LOADIND, payload: true });
      const response = await axios({
        method: 'get',
        url: `/comments-by-slug/${slug}`,
      });
      const data = response?.data?.data || {};
      dispatch({ type: SET_COMMENTS, payload: data });
      dispatch({ type: COMMENTS_BY_SLUG_LOADIND, payload: false });
    } catch (error) {
      dispatch({ type: COMMENTS_BY_SLUG_LOADIND, payload: false });
      const message = error?.response?.data?.errorMessage || '';
      NotificationManager.error(message || 'Oops, Something went wrong!');
    }
  };
};

export const postComments = (req, id) => {
  return async dispatch => {
    try {
      dispatch({ type: COMMENTS_LOADIND, payload: true });
      await AuthAxios({
        method: 'post',
        url: '/comments',
        data: req,
      });
      await Promise.all([dispatch(getComments(id)), dispatch(getAuction(id))]);
      dispatch({ type: COMMENTS_LOADIND, payload: false });
    } catch (error) {
      dispatch({ type: COMMENTS_LOADIND, payload: false });
      const message = error?.response?.data?.errorMessage || '';
      NotificationManager.error(message || 'Oops, Something went wrong!');
    }
  };
};

export const deleteComment = (id, auction_id) => {
  return async dispatch => {
    try {
      dispatch({ type: COMMENTS_LOADIND, payload: true });
      await AuthAxios({
        method: 'delete',
        url: `/comments/${id}`,
      });
      await Promise.all([
        dispatch(getComments(id)),
        dispatch(getAuction(auction_id)),
      ]);
      dispatch({ type: COMMENTS_LOADIND, payload: false });
    } catch (error) {
      dispatch({ type: COMMENTS_LOADIND, payload: false });
      const message = error?.response?.data?.errorMessage || '';
      NotificationManager.error(message || 'Oops, Something went wrong!');
    }
  };
};

export const setUpVote = (id, auction_id) => {
  return async dispatch => {
    try {
      dispatch({ type: COMMENTS_LOADIND, payload: true });
      await AuthAxios({
        method: 'put',
        url: `/comments/up-vote/${id}`,
      });
      await dispatch(getComments(auction_id));
      dispatch({ type: COMMENTS_LOADIND, payload: false });
    } catch (error) {
      dispatch({ type: COMMENTS_LOADIND, payload: false });
      const message = error?.response?.data?.errorMessage || '';
      NotificationManager.error(message || 'Oops, Something went wrong!');
    }
  };
};

export const setInappropriate = (id, status, auction_id) => {
  return async dispatch => {
    try {
      dispatch({ type: COMMENTS_LOADIND, payload: true });
      await AuthAxios({
        method: 'put',
        url: `/comments/mark-inappropriate/${id}/${status}`,
      });
      await dispatch(getComments(auction_id));
      dispatch({ type: COMMENTS_LOADIND, payload: false });
    } catch (error) {
      dispatch({ type: COMMENTS_LOADIND, payload: false });
      const message = error?.response?.data?.errorMessage || '';
      NotificationManager.error(message || 'Oops, Something went wrong!');
    }
  };
};

export const postBid = (req, closeModal) => {
  return async dispatch => {
    try {
      dispatch({ type: POST_BID_LOADIND, payload: true });
      const response = await AuthAxios({
        method: 'post',
        url: `/user/bid`,
        data: req,
      });
      const data = response?.data?.data || {};
      dispatch({ type: SET_POST_BID_SUCCESS, payload: data });
      setTimeout(async () => {
        closeModal();
        await dispatch(getAuction(req.auction_id));
        await dispatch(getComments(req.auction_id));
      }, 5000);
      dispatch({ type: POST_BID_LOADIND, payload: false });
    } catch (error) {
      dispatch({ type: POST_BID_LOADIND, payload: false });
      dispatch({
        type: SET_POST_BID_ERROR,
        payload: error?.response?.data?.errorMessage,
      });
      setTimeout(
        () => dispatch({ type: SET_POST_BID_ERROR, payload: null }),
        4000
      );
      const message = error?.response?.data?.errorMessage || '';
      NotificationManager.error(message || 'Oops, Something went wrong!');
    }
  };
};

export const postOffer = (req, closeModal) => {
  return async dispatch => {
    try {
      dispatch({ type: POST_OFFER_LOADIND, payload: true });
      const response = await AuthAxios({
        method: 'post',
        url: `/user/offer`,
        data: req,
      });
      const data = response?.data?.data || {};
      dispatch({ type: SET_POST_OFFER_SUCCESS, payload: data });
      setTimeout(async () => {
        closeModal();
        await dispatch(getAuction(req.auction_id));
        await dispatch(getComments(req.auction_id));
      }, 5000);
      dispatch({ type: POST_OFFER_LOADIND, payload: false });
    } catch (error) {
      dispatch({ type: POST_OFFER_LOADIND, payload: false });
      dispatch({
        type: SET_POST_OFFER_ERROR,
        payload: error?.response?.data?.errorMessage,
      });
      setTimeout(
        () => dispatch({ type: SET_POST_OFFER_ERROR, payload: null }),
        4000
      );
      const message = error?.response?.data?.errorMessage || '';
      NotificationManager.error(message || 'Oops, Something went wrong!');
    }
  };
};
