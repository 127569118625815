import {
  AUCTION_LOADING,
  SET_AUCTION_LIST,
  SET_AUCTION,
  SET_AUCTIONS,
  GET_AUCTIONS_LOADING,
  GET_PASS_AUCTIONS_LOADING,
  SET_AUCTION_DETAIL,
  GET_AUCTION_DETAIL_LOADING,
  GET_AUCTION_DETAIL_BY_SLUG_LOADING,
  SET_WISH_LIST,
  WISH_LIST_LOADIND,
  SET_COMMENTS,
  COMMENTS_LOADIND,
  COMMENTS_BY_SLUG_LOADIND,
  POST_BID_LOADIND,
  SET_POST_BID_ERROR,
  SET_POST_BID_SUCCESS,
  POST_OFFER_LOADIND,
  SET_POST_OFFER_ERROR,
  SET_POST_OFFER_SUCCESS,
} from './actions.js';

const initialState = {
  adminAuctionLoading: false,
  adminAuctions: {},
  adminAuction: null,
  auctions: {},
  getAuctionsLoading: false,
  getPassAuctionsLoading: false,
  auctionDetail: {},
  getAuctionDetailLoading: false,
  wishListLoading: false,
  wishList: [],
  commentsLoading: false,
  comments: [],
  postBidLoading: false,
  postBidError: null,
  postBidSuccess: null,
  postOfferLoading: false,
  postOfferError: null,
  postOfferSuccess: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AUCTION_LOADING:
      return { ...state, adminAuctionLoading: action.payload };
    case SET_AUCTION_LIST:
      return {
        ...state,
        adminAuctions: action.payload || {},
      };
    case SET_AUCTION:
      return {
        ...state,
        adminAuction: action.payload || {},
      };

    case SET_AUCTIONS:
      return {
        ...state,
        auctions: action.payload || {},
      };
    case GET_AUCTIONS_LOADING:
      return { ...state, getAuctionsLoading: action.payload };
    case GET_PASS_AUCTIONS_LOADING:
      return { ...state, getPassAuctionsLoading: action.payload };

    case SET_AUCTION_DETAIL:
      return {
        ...state,
        auctionDetail: action.payload || {},
      };
    case GET_AUCTION_DETAIL_LOADING:
      return { ...state, getAuctionDetailLoading: action.payload };

    case GET_AUCTION_DETAIL_BY_SLUG_LOADING:
      return { ...state, getAuctionDetailLoading: action.payload };

    case WISH_LIST_LOADIND:
      return { ...state, wishListLoading: action.payload };
    case SET_WISH_LIST:
      return {
        ...state,
        wishList: action.payload,
      };

    case COMMENTS_LOADIND:
      return { ...state, commentsLoading: action.payload };
    case COMMENTS_BY_SLUG_LOADIND:
      return { ...state, commentsLoading: action.payload };

    case SET_COMMENTS:
      return {
        ...state,
        comments: action.payload,
      };

    case POST_BID_LOADIND:
      return {
        ...state,
        postBidLoading: action.payload,
      };
    case SET_POST_BID_ERROR:
      return {
        ...state,
        postBidError: action.payload,
      };
    case SET_POST_BID_SUCCESS:
      return {
        ...state,
        postBidSuccess: action.payload,
      };

    case POST_OFFER_LOADIND:
      return {
        ...state,
        postOfferLoading: action.payload,
      };
    case SET_POST_OFFER_ERROR:
      return {
        ...state,
        postOfferError: action.payload,
      };
    case SET_POST_OFFER_SUCCESS:
      return {
        ...state,
        postOfferSuccess: action.payload,
      };

    default:
      return state;
  }
};

export * from './auctions';
export default reducer;
