import React, { useState } from 'react';
import {
  AvForm,
  AvGroup,
  AvInput,
  AvFeedback,
} from 'availity-reactstrap-validation';
import { Label } from 'reactstrap';
import { Modal, Button, Icon } from 'semantic-ui-react';

export const EmailModel = props => {
  const [open, setOpen] = useState(false);

  const inlineStyle = {
    modal: {
      height: 'auto',
      top: 'auto',
      left: 'auto',
      bottom: 'auto',
      right: 'auto',
    },
  };
  const closeModal = () => {
    setOpen(false);
  };

  return (
    <Modal
      size="tiny"
      onClose={() => {
        setOpen(false);
      }}
      onOpen={() => {
        setOpen(true);
      }}
      open={open}
      trigger={
        <strong className="contact-text bold">{props.buttonText}</strong>
      }
      style={inlineStyle.modal}
      closeOnDimmerClick={false}
      className="sign-up-modal"
    >
      <Modal.Header>
        <button
          onClick={() => {
            closeModal();
          }}
          type="button"
          className="close"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 className="text-center">Send Email</h4>
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <AvForm
            onValidSubmit={async (event, values) => {
              await props.sendEmail(
                {
                  to: props.to,
                  ...values,
                },
                closeModal
              );
            }}
          >
            <AvGroup>
              <Label for="subject">Subject</Label>
              <AvInput name="subject" id="subject" required />
              <AvFeedback>Subject shouldn't be empty.</AvFeedback>
            </AvGroup>
            <AvGroup>
              <Label for="content">Content</Label>
              <AvInput
                type="textarea"
                name="content"
                id="content"
                rows={6}
                required
              />
              <AvFeedback>Content shouldn't be empty.</AvFeedback>
            </AvGroup>
            <Button className="w-100" inverted color="red" size="large">
              Send Email
            </Button>
          </AvForm>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};
