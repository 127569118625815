import React, { useState } from 'react';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  UncontrolledDropdown,
  NavItem,
} from 'reactstrap';
import { Grid, Input } from 'semantic-ui-react';
import { AppNavbarBrand } from '@coreui/react';
import { images } from '../theme/images';
import { AuthModal } from './AuthModal';
import { Container } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import { AWS_CONFIG } from '../constant';
import './HomeHeader.scss';

export const HomeHeader = props => {
  const [search, setSearch] = useState('');
  const signOut = async e => {
    e.preventDefault();
    const { logOut } = props;
    await logOut();
    window.location.reload();
  };
  const { auth } = props;
  const email = auth?.userData?.email || null;
  let user_name = auth?.userData?.user_name || null;
  const role = auth?.userData?.role || null;
  const avatar = auth?.userData?.avatar || null;
  if (user_name && user_name.length > 15) {
    user_name = user_name.substring(0, 15) + '...';
  }
  return (
    <React.Fragment>
      <Container>
        <AppNavbarBrand
          href="/"
          className="home-nav-brand"
          full={{
            src: images.logo,
            width: isMobile ? 120 : 220,
            alt: 'Logo',
          }}
          minimized={{ src: images.logo, width: 70, alt: 'Logo' }}
        />
        <div className="d-flex ml-2">
          <Nav className="d-none d-none d-lg-block font-weight-bold" navbar>
            <NavItem className="px-3">
              <a href="/" className="nav-link header-title">
                Auctions
              </a>
            </NavItem>
          </Nav>
          <Nav className="d-none d-none d-lg-block font-weight-bold" navbar>
            <NavItem className="px-3">
              <a href="/sell-car" className="nav-link header-title">
              List Your Car
              </a>
            </NavItem>
          </Nav>
          <Nav className="d-none d-none d-lg-block font-weight-bold" navbar>
            <NavItem className="px-3">
              <a href="/marketplacewatch" className="nav-link header-title">
                Marketplace Watch
              </a>
            </NavItem>
          </Nav>
          <Nav className="d-none d-none d-lg-block font-weight-bold" navbar>
            <NavItem className="px-3">
              <a href="/how-it-works" className="nav-link header-title">
                How It Works
              </a>
            </NavItem>
          </Nav>
        </div>
        <Nav className="ml-auto d-none d-lg-block" navbar>
          <Input
            onKeyDown={e => {
              if (e.key === 'Enter' && search) {
                props.history.push(`/search?q=${search}`);
              }
            }}
            value={search}
            onChange={e => setSearch(e.target.value)}
            size="large"
            icon="search"
            placeholder="Search..."
          />
        </Nav>
        <Nav className="ml-3" navbar>
          <Grid>
            <Grid.Column className="d-flex">
              <AuthModal {...props} email={email} />
            </Grid.Column>
          </Grid>
          {user_name ? (
            <UncontrolledDropdown nav direction="down">
              <DropdownToggle nav>
                <strong className="d-none d-sm-block header-title">
                  {user_name}
                  <img
                    src={
                      avatar
                        ? `${AWS_CONFIG.defaultUrl}${avatar}`
                        : images.avatar
                    }
                    width="36px"
                    height="36px"
                    className="img-avatar ml-2 mb-2"
                    alt="avatar"
                  />
                </strong>
                <strong className="d-block d-sm-none">
                  <img
                    src={
                      avatar
                        ? `${AWS_CONFIG.defaultUrl}${avatar}`
                        : images.avatar
                    }
                    width="36px"
                    height="36px"
                    className="img-avatar ml-2 mb-2"
                    alt="avatar"
                  />
                </strong>
              </DropdownToggle>
              <DropdownMenu
                className="drop-down-menu"
                right
                style={{ right: 'auto' }}
              >
                <DropdownItem href="/account">
                  <strong>
                    <i className="fa fa-user" /> Profile
                  </strong>
                </DropdownItem>
                {role === 'admin' ? (
                  <>
                    <DropdownItem divider />
                    <DropdownItem href="/admin-dashboard">
                      <strong>
                        <i className="fa fa-tachometer" /> Dashboard
                      </strong>
                    </DropdownItem>
                  </>
                ) : null}
                <DropdownItem divider />
                <DropdownItem
                  onClick={e => {
                    signOut(e);
                  }}
                >
                  <strong>
                    <i className="fa fa-lock" /> Logout
                  </strong>
                </DropdownItem>
                <DropdownItem divider />
              </DropdownMenu>
            </UncontrolledDropdown>
          ) : null}
        </Nav>
        <div
          className="py-2 pl-4 cursor-pointer d-block d-sm-none"
          onClick={() => {
            props.onAsideClick();
          }}
        >
          <i className="icon-list font-3xl"></i>
        </div>
        <div
          className="py-2 pl-4 cursor-pointer d-none d-md-block d-lg-none"
          onClick={() => {
            props.onAsideClick();
          }}
        >
          <i className="icon-list font-3xl"></i>
        </div>
        <div
          className="py-2 pl-4 cursor-pointer d-none d-sm-block d-md-none"
          onClick={() => {
            props.onAsideClick();
          }}
        >
          <i className="icon-list font-3xl"></i>
        </div>
      </Container>
    </React.Fragment>
  );
};
