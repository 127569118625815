export const SET_AUCTION_LIST = 'SET_AUCTION_LIST';
export const SET_AUCTION = 'SET_AUCTION';
export const AUCTION_LOADING = 'AUCTION_LOADING';

export const SET_AUCTIONS = 'SET_AUCTIONS';
export const GET_AUCTIONS_LOADING = 'GET_AUCTIONS_LOADING';
export const GET_PASS_AUCTIONS_LOADING = 'GET_PASS_AUCTIONS_LOADING';

export const SET_AUCTION_DETAIL = 'SET_AUCTION_DETAIL';
export const GET_AUCTION_DETAIL_LOADING = 'GET_AUCTION_DETAIL_LOADING';
export const GET_AUCTION_DETAIL_BY_SLUG_LOADING = 'GET_AUCTION_DETAIL_BY_SLUG_LOADING';

export const SET_WISH_LIST = 'SET_WISH_LIST';
export const WISH_LIST_LOADIND = 'WISH_LIST_LOADIND';

export const SET_COMMENTS = 'SET_COMMENTS';
export const COMMENTS_LOADIND = 'COMMENTS_LOADIND';
export const COMMENTS_BY_SLUG_LOADIND = 'COMMENTS_BY_SLUG_LOADIND';

export const POST_BID_LOADIND = 'POST_BID_LOADIND';
export const SET_POST_BID_ERROR = 'SET_POST_BID_ERROR';
export const SET_POST_BID_SUCCESS = 'SET_POST_BID_SUCCESS';

export const POST_OFFER_LOADIND = 'POST_OFFER_LOADIND';
export const SET_POST_OFFER_ERROR = 'SET_POST_OFFER_ERROR';
export const SET_POST_OFFER_SUCCESS = 'SET_POST_OFFER_SUCCESS';
